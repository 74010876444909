import { useState, useRef, useEffect } from "react";
import {
  AdminContext,
  defaultI18nProvider,
  localStorageStore,
} from "react-admin";
import Keycloak from "keycloak-js";
import {
  keycloakAuthProvider,
  raKeycloakOptions,
} from "./core/keycloakAuthProvider";
import { httpClient } from "./core/httpClient";
import { apiDataProvider } from "./core/apiProvider";
import { Loading } from "./components/customLoading";

import { STORE_VERSION } from "./config/settings";
import { cleanThemeCache, ENV, setEnvFavicon } from "./ra-lb-tools/utils/tools";
import { initialize } from "./ra-lb-tools/config/packageSetup";
import {
  AttachMoney,
  BugReportOutlined,
  CurrencyExchange,
  DisplaySettings,
  MeetingRoom,
  MoreHoriz,
  WorkOutlineOutlined,
} from "@mui/icons-material";
import { AllowedResources } from "./AllowedResources";
import { resetPermissions } from "./ra-lb-tools/utils/access";
import { jiTheme } from "./theme/theme";
import { AuthProxy } from "./core/authProxy";

const App = () => {
  initialize({
    menuGroups: [
      { label: "billing", sortOrder: 41, icon: AttachMoney },
      { label: "Billing Activity", sortOrder: 51, icon: CurrencyExchange },
      { label: "opener", sortOrder: 61, icon: MeetingRoom },
      { label: "Server Management", sortOrder: 71, icon: DisplaySettings },
      { label: "More", sortOrder: 81, icon: MoreHoriz },
      { label: "legacy", icon: WorkOutlineOutlined, debug: true },
      { label: "debug", icon: BugReportOutlined, debug: true },
    ],
  });

  setEnvFavicon();

  const [keycloak, setKeycloak] = useState(undefined);
  const authProvider = useRef(undefined);
  const dataProvider = useRef(undefined);

  useEffect(() => {
    const initKeyCloakClient = async () => {
      resetPermissions();

      const _ENV = ENV();
      const keycloakClient = new Keycloak(_ENV.KEYCLOAK_CONFIG);
      await keycloakClient.init(_ENV.KEYCLOAK_INIT_OPTIONS);

      const AuthClient = AuthProxy(keycloakClient);

      authProvider.current = keycloakAuthProvider(
        AuthClient,
        raKeycloakOptions
      );
      dataProvider.current = apiDataProvider(
        _ENV.API_URL,
        httpClient(AuthClient)
      );
      setKeycloak(AuthClient);
    };

    if (!keycloak) {
      initKeyCloakClient();
    }

    cleanThemeCache();
  }, [keycloak]);

  if (!keycloak) return <Loading />;

  return (
    <AdminContext
      authProvider={authProvider.current}
      dataProvider={dataProvider.current}
      requireAuth
      store={localStorageStore(STORE_VERSION)}
      i18nProvider={defaultI18nProvider}
      theme={jiTheme}
    >
      <AllowedResources />
    </AdminContext>
  );
};

export default App;
