import { useParams } from "react-router-dom";
import {
  TextField,
  DateField,
  SelectField,
  SelectInput,
  TextInput,
  required,
} from "react-admin";
import { TitledShow } from "../../ra-lb-tools/components/titledRecord";
import {
  MyReferenceField,
  MyReferenceInput,
} from "../../ra-lb-tools/components/myReference";
import { MyList } from "../../ra-lb-tools/components/myList";
import { LabeledColumn } from "../../ra-lb-tools/components/labeledColumn";
import { LockerTypeField, LockerTypeInput } from "../lockerTypes";
import { MyCreate as Create } from "../../ra-lb-tools/components/myMutator";
import { ProductPriceList } from "./prices";
import { ShoppingCartOutlined } from "@mui/icons-material";
import { Row } from "../../ra-lb-tools/components/row";
import {
  MyTabbedShowLayout,
  Tab,
} from "../../ra-lb-tools/components/myTabbedShowLayout";
import { LockerUsageRecordsList } from "./lockerUsageRecord";

const UsagePeriodProduct = [
  { id: "minute", name: "Minute" },
  { id: "hour", name: "Hour" },
  { id: "day", name: "Day" },
];

const UsageProductType = [
  { id: "per_transaction", name: "Per transaction" },
  { id: "per_usage_period", name: "Per usage period" },
  { id: "no_metered", name: "No metered" },
];
export const ProductIcon = ShoppingCartOutlined;

export const ProductList = (props) => (
  <MyList {...props} resource="products">
    <TextField source="id" />
    <TextField source="name" />
    <LockerTypeField source="lockerTypeId" />
    <TextField source="processorId" />
    <SelectField source="type" choices={UsageProductType} />
    <SelectField source="usagePeriod" choices={UsagePeriodProduct} />
    <DateField source="createdAt" showTime />
    <DateField source="updatedAt" showTime />
  </MyList>
);
export const ProductShow = () => {
  const { id } = useParams();

  return (
    <TitledShow>
      <MyTabbedShowLayout>
        <Tab label="Summary" can={true}>
          <Row sx={{ justifyContent: "start" }} className={"RecordSummary"}>
            <LabeledColumn>
              <TextField source="id" />
              <TextField source="name" />
              <LockerTypeField source="lockerTypeId" />
              <TextField source="processorId" />
            </LabeledColumn>
            <LabeledColumn>
              <SelectField source="type" choices={UsageProductType} />
              <SelectField source="usagePeriod" choices={UsagePeriodProduct} />
              <DateField source="createdAt" showTime />
              <DateField source="updatedAt" showTime />
            </LabeledColumn>
          </Row>
        </Tab>
        <Tab
          label="Prices"
          path="prices"
          can={{ action: "list", resource: "prices" }}
        >
          <ProductPriceList productId={id} />
        </Tab>
        <Tab
          label="Usage Records"
          path="usage-records"
          can={{ action: "list", resource: "locker-usage-records" }}
        >
          <LockerUsageRecordsList filter={{ product_id: id }} />
        </Tab>
      </MyTabbedShowLayout>
    </TitledShow>
  );
};

export const ProductCreate = (props) => {
  return (
    <Create {...props}>
      <TextInput source="name" validate={required()} />
      <SelectInput source="type" choices={UsageProductType} />
      <SelectInput source="usagePeriod" choices={UsagePeriodProduct} />
      <LockerTypeInput source="lockerTypeId" />
    </Create>
  );
};

export const ProductInput = (props) => {
  return (
    <MyReferenceInput source="productId" reference="products" {...props} />
  );
};

export const ProductField = (props) => {
  return (
    <MyReferenceField source="productId" reference="products" {...props} />
  );
};
