import {
  AutocompleteInput,
  ReferenceArrayInput,
  TextInput,
  useNotify,
} from "react-admin";
import { MyCreate } from "../ra-lb-tools/components/myMutator";
import { useState } from "react";

export const TaskCreate = (props) => {
  const notify = useNotify();
  const [saveEnabled, setSaveEnabled] = useState(false);
  const filterToQuery = (searchText) => ({ id: `${searchText}` });

  const onSuccess = () => {
    setSaveEnabled(false);
    notify(`Success !!!`, {
      type: "success",
    });
  };

  const mutationOptions = {
    meta: {
      transform: (values) => {
        values.arguments = JSON.parse(values.arguments);
        return values;
      },
    },
    onSuccess,
  };

  return (
    <MyCreate
      columns={1}
      mutationOptions={mutationOptions}
      saveEnabled={saveEnabled}
      {...props}
    >
      <ReferenceArrayInput source="task" reference="task-types">
        <AutocompleteInput
          filterToQuery={filterToQuery}
          optionText="id"
          optionValue="id"
          label="Task"
          style={{ width: "100%" }}
          onChange={() => setSaveEnabled(true)}
        />
      </ReferenceArrayInput>
      <TextInput
        multiline
        source="arguments"
        label="Arguments (JSON list)"
        defaultValue={"[]"}
        onChange={() => setSaveEnabled(true)}
      />
    </MyCreate>
  );
};
