import { useResourceDefinitions, useStore } from "ra-core";
import { ResourceMenuItem } from "react-admin";
import get from "lodash/get";
import set from "lodash/set";
import pickBy from "lodash/pickBy";
import sortBy from "lodash/sortBy";
import { Box } from "@mui/material";
import { Settings } from "./settings";
import { customRouteMenu, debugInfo } from "../theme/styles";
import { AccordionMenu } from "../components/accordionMenu";
import { useCustomRoutes } from "../config/customRoutes";
import { getConfig } from "../config/packageSetup";
import { MenuItem } from "../components/MenuItem";
import { MenuSpacer } from "../components/menuSpacer";
import { menu_usage_store, updateMenuUsage } from "./menuUsage";
import { getFavorites } from "../components/menuFavorites";

const isAvailableForMenu = (resource) =>
  (resource.hasList || resource.onMenu) &&
  (Settings.debugging() || !get(resource, "options.debugOnly"));

const getSx = (options) =>
  Object.assign(
    {},
    customRouteMenu,
    options.sx || {},
    options.debugOnly ? debugInfo : {}
  );

const renderResourceMenu = (menuUsageStore, resourceName, options) => (
  <Box
    key={resourceName}
    sx={getSx(options)}
    data-sort-order={options.sortOrder}
    onClick={() => updateMenuUsage(menuUsageStore, resourceName)}
  >
    <ResourceMenuItem name={resourceName} />
  </Box>
);

const renderCustomMenu = (menuUsageStore, route) => {
  const label =
    !route.options.spacer &&
    (route.label ||
      (route.path && route.path.replaceAll(/[^\w\d\s]/g, " ").trim()));

  return route.options.spacer ? (
    <MenuSpacer route={route} key={route.options.sortOrder} />
  ) : (
    <MenuItem
      key={route.path}
      to={route.path}
      primaryText={label}
      {...route.menuProps}
      sx={getSx(route.options || {})}
      data-sort-order={route.options.sortOrder}
      onClick={() => updateMenuUsage(menuUsageStore, label)}
    />
  );
};

const getGrouped = () => {
  const keys = getConfig().menuGroups || [];

  keys.includes("") || keys.unshift("");

  const grouped = keys.reduce((acc, key) => {
    if (typeof key == "string") {
      acc[key] = {
        label: key,
        items: [],
      };
    } else {
      acc[key.label] = { ...key, items: [] };
    }

    return acc;
  }, {});

  return grouped;
};

/**
 * Based on
 * https://github.com/marmelab/react-admin/blob/0e4c7ca1187649bbe63fd04321c20b4de0ee488b/packages/ra-ui-materialui/src/layout/ResourceMenuItems.tsx
 *
 * Edit: there is almost nothing left from the original code
 */
export const ResourceMenuItems = ({ filter, onlyThese }) => {
  const menuUsageStore = useStore(menu_usage_store, {});
  const favorites = onlyThese ? [] : getFavorites(menuUsageStore[0]);

  const resources = Object.assign(
    {},
    useResourceDefinitions(),
    useCustomRoutes()
  );
  let menus = [];

  let available = pickBy(resources, isAvailableForMenu);
  if (filter) {
    available = pickBy(available, (resource) =>
      (resource.label || resource.name || "").includes(filter)
    );
  }

  if (onlyThese) {
    available = pickBy(available, (resource) =>
      onlyThese.includes(resource.label || resource.name)
    );
  }

  const applyGrouping = !(filter || onlyThese);

  const sorted = sortBy(available, (resource) =>
    get(resource, "options.sortOrder", 0)
  );

  let _ungrouped = pickBy(
    sorted,
    (resource) =>
      (!applyGrouping || !get(resource, "options.group")) &&
      !favorites.includes(resource.label || resource.name)
  );

  Object.keys(_ungrouped).forEach((key, idx) => {
    const resource = _ungrouped[key];
    set(
      resource,
      "options.sortOrder",
      get(resource, "options.sortOrder", idx * 10)
    );
    const menu = resource.hasList
      ? renderResourceMenu(menuUsageStore, resource.name, resource.options)
      : renderCustomMenu(menuUsageStore, resource);

    menus.push(menu);
  });

  const _grouped = pickBy(
    sorted,
    (resource) => applyGrouping && get(resource, "options.group")
  );
  const grouped = getGrouped();
  Object.keys(_grouped).forEach((key) => {
    const resource = _grouped[key];
    const menu = resource.hasList
      ? renderResourceMenu(menuUsageStore, resource.name, resource.options)
      : renderCustomMenu(menuUsageStore, resource);

    const group = resource.options.group;

    grouped[group] = grouped[group] || { label: group, items: [] };
    grouped[group].items.push(menu);
  });

  Object.keys(grouped).forEach((groupName) => {
    const group = grouped[groupName];
    if (!group.items.length) {
      return;
    }

    menus.push(
      <AccordionMenu
        key={groupName}
        title={groupName}
        data-sort-order={group.sortOrder}
        icon={group.icon}
        debug={group.debug}
      >
        <Box>{group.items}</Box>
      </AccordionMenu>
    );
  });

  menus = sortBy(menus, (menu_entry) =>
    get(menu_entry, "props.data-sort-order", 1000)
  );

  return <>{menus}</>;
};
