import { stringify } from "query-string";
import { fetchUtils, DataProvider } from "ra-core";
import { GetListParams, DeleteResult } from "react-admin";
import { debug_log } from "../ra-lb-tools/utils/debugLog";
import { errorHandlingFactory } from "../ra-lb-tools/utils/errors";
import { processQualifiedFilters } from "../ra-lb-tools/utils/api";
import { getValue, popKey } from "../ra-lb-tools/utils/tools";

interface CustomHttpClient {
  (url: string, options?: fetchUtils.Options): Promise<any>;
}
/**
 * Maps react-admin queries to the backend REST API
 *
 * getList     => GET http://my.api.url/posts?sort=['title','ASC']&skip=0&limit=24
 * getList     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 *
 */

export const apiDataProvider = (
  apiUrl: string,
  httpClient: CustomHttpClient,
  countHeader = "Content-Range"
): DataProvider => {
  const parseCounterHeader = (headers: Headers): number => {
    if (!headers.has(countHeader)) {
      throw new Error(
        `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
      );
    }

    const headerValue = headers.get(countHeader.toLowerCase()) as string;

    return countHeader === "Content-Range"
      ? parseInt(headerValue.split("/").pop() as string, 10)
      : parseInt(headerValue, 10);
  };

  const buildOptions = (
    resource: string,
    rangeStart: number,
    rangeEnd: number
  ): object => {
    return countHeader === "Content-Range"
      ? {
          // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
          headers: new Headers({
            Range: `${resource}=${rangeStart}-${rangeEnd}`,
          }),
        }
      : {};
  };

  const customResources: any = {
    lockerHistory: {
      parent: {
        resource: "lockers",
        id: "lockerId",
      },
      name: "history",
    },
    lockerState: {
      parent: {
        resource: "lockers",
        id: "locker_id",
      },
      name: "states",
    },
    productPrices: {
      parent: {
        resource: "products",
        id: "productId",
      },
      name: "prices",
    },
  };

  const customMeta: any = {
    getMany: {
      towers: {
        status_id: 0,
      },
    },
  };

  /**
   * Current behaviour: built in List component provides resource entity name (i.e. towers, events, etc)
   * and we use it to build api endpoints with standard template
   * Problem: we do not provide standard endpoints for some entities (i.e /events),
   * we only allow access through other enpoints (i.e. towers/events)
   *
   * Solution: we specify such entities and through which entities we should access the data
   * and this method makes the translation
   *
   * @param resource
   * @param params
   * @returns resource
   */
  const processCustomResources = (
    resource: string,
    params: GetListParams
  ): string => {
    if (customResources[resource]) {
      const parent = customResources[resource].parent;
      const parentId = params.filter[parent.id];
      const name = customResources[resource].name || resource;

      resource = `${parent.resource}/${parentId}/${name}`;
      delete params.filter[parent.id];
    }

    return resource;
  };

  const processRequest = (resource: string, params: GetListParams): string => {
    processQualifiedFilters(resource, params);
    resource = processCustomResources(resource, params);

    return resource;
  };

  return {
    getList: (resource, params) => {
      resource = processRequest(resource, params);

      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;

      const rangeStart = (page - 1) * perPage;
      const rangeEnd = page * perPage - 1;

      const customQuery = getValue(params, "meta.customQuery", {});

      const query = {
        sort: JSON.stringify([field, order]),
        //range: JSON.stringify([rangeStart, rangeEnd]),
        skip: rangeStart,
        limit: perPage,
        filter: JSON.stringify(params.filter),
        ...customQuery,
      };

      let url = `${apiUrl}/${resource}`;

      const customSegment = getValue(params, "meta.customSegment");
      if (customSegment) {
        url += "/" + customSegment;
      }

      url = `${url}/?${stringify(query)}`;

      const options = buildOptions(resource, rangeStart, rangeEnd);

      debug_log(`getList: ${apiUrl}/${resource}`);
      debug_log(query);
      debug_log(options);

      const transformResponse = getValue(params, "meta.transformResponse");
      const onSuccess = (data: { json: any; headers: Headers }) => {
        const res = {
          data: transformResponse
            ? data.json.map(transformResponse)
            : data.json,
          total: parseCounterHeader(data.headers),
        };

        debug_log(`Response: ${apiUrl}/${resource}`);
        debug_log(query);
        debug_log(res);

        return res;
      };

      const notify = params.meta && params.meta.notify;
      if (notify) {
        const onError = (err: any) => {
          const handler = errorHandlingFactory(notify);
          handler(err);

          return {
            data: [{ id: "err" }],
            total: 1,
          };
        };
        return httpClient(url, options).then(onSuccess).catch(onError);
      } else {
        return httpClient(url, options).then(onSuccess);
      }
    },

    getOne: (resource, params) => {
      let url = `${apiUrl}/${resource}/${params.id}`;

      const customSegment = getValue(params, "meta.customSegment");
      if (customSegment) {
        url += "/" + customSegment;
      }

      debug_log(`getOne: ${url}`);
      debug_log(params);

      const transformResponse = getValue(params, "meta.transformResponse");

      return httpClient(url).then(({ json }) => ({
        data: transformResponse ? transformResponse(json) : json,
      }));
    },

    getMany: (resource, params) => {
      const filter = { id: params.ids };
      const meta =
        params.meta || getValue(customMeta, `getMany.${resource}`, {});
      const query = {
        filter: JSON.stringify({ ...meta, ...filter }),
      };
      const url = `${apiUrl}/${resource}/?${stringify(query)}`;

      debug_log(`getMany: ${apiUrl}/${resource}`);
      debug_log(query);

      return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;

      const rangeStart = (page - 1) * perPage;
      const rangeEnd = page * perPage - 1;

      const query = {
        sort: JSON.stringify([field, order]),
        // range: JSON.stringify([rangeStart, rangeEnd]),
        skip: rangeStart,
        limit: perPage,
        filter: JSON.stringify({
          ...params.filter,
          [params.target]: params.id,
        }),
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;
      const options = buildOptions(resource, rangeStart, rangeEnd);

      debug_log(`getManyReference: ${apiUrl}/${resource}`);
      debug_log(query);

      return httpClient(url, options).then(({ headers, json }) => {
        return {
          data: json,
          total: parseCounterHeader(headers),
        };
      });
    },

    update: (resource, params) => {
      let uri = `${apiUrl}/${resource}/${params.id}`;

      const customSegment = getValue(params, "meta.customSegment");
      if (customSegment) {
        uri += "/" + customSegment;
      }

      const method = getValue(params, "meta.customMethod", "PUT");

      return httpClient(uri, {
        method,
        body: JSON.stringify(params.data),
      }).then(({ json }) => ({ data: json }));
    },

    updateMany: (resource, params) =>
      Promise.all(
        params.ids.map((id) => {
          let uri = `${apiUrl}/${resource}/${id}`;

          const meta = params.meta || popKey(params.data, "__meta");
          const customSegment = meta && meta.customSegment;
          if (customSegment) {
            uri += "/" + customSegment;
          }

          return httpClient(uri, {
            method: "PUT",
            body: JSON.stringify(params.data),
          });
        })
      ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),

    create: (resource, params) => {
      const customSegment = getValue(params, "meta.customSegment", "");
      const transform = getValue(params, "meta.transform");

      let data = null;
      try {
        data = transform ? transform(params.data) : params.data;
      } catch (error) {
        return Promise.reject(error);
      }
      const body = JSON.stringify(data);

      return httpClient(`${apiUrl}/${resource}/${customSegment}`, {
        method: "POST",
        body,
      }).then(({ json }) => ({
        data: { ...params.data, id: getValue(json, "id"), _response: json },
      }));
    },

    delete: (resource, params) => {
      const uri = `${apiUrl}/${resource}/${params.id}`;
      const options = {
        method: "DELETE",
        headers: new Headers({
          "Content-Type": "text/plain",
        }),
      };

      const notify = params.meta && params.meta.notify;
      if (notify) {
        const onError = (err: any) => {
          const handler = errorHandlingFactory(notify);
          handler(err);

          const res: DeleteResult = { data: { id: -1 } };
          return res;
        };
        return httpClient(uri, options)
          .then(({ json }) => ({ data: json }))
          .catch(onError);
      } else {
        return httpClient(uri, options).then(({ json }) => ({ data: json }));
      }
    },

    deleteMany: (resource, params) =>
      Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: "DELETE",
            headers: new Headers({
              "Content-Type": "text/plain",
            }),
          })
        )
      ).then((responses) => ({
        data: responses.map(({ json }) => json.id),
      })),

    customCommand: (
      url: string,
      notify: (msg: string, config: object) => void,
      method = "POST",
      successMessage = "Success!"
    ) => {
      const onError = errorHandlingFactory(notify);
      if (!url.startsWith("/")) {
        url = "/" + url;
      }

      const uri = apiUrl + url;

      return httpClient(uri, {
        method,
      })
        .then((r) => {
          notify(successMessage, {
            type: "success",
          });

          return getValue(r, "json");
        })
        .catch((err) => onError(err));
    },

    resetEvents: (
      towerId: string,
      reason: string | null,
      notify: (msg: string, config: object) => void
    ) =>
      httpClient(`${apiUrl}/towers/${towerId}/reset-events`, {
        method: "POST",
        body: JSON.stringify(reason ? { reason } : {}),
      })
        .then(() =>
          notify(`Events successfully reset on Tower ${towerId}`, {
            type: "success",
          })
        )
        .catch((err) =>
          notify(`Error resetting events: ${err}`, { type: "error" })
        ),

    importRemoteLockers: (
      federationId: number,
      notify: (msg: string, config: object) => void
    ) =>
      httpClient(`${apiUrl}/federations/${federationId}/import-lockers`, {
        method: "POST",
      })
        .then(() =>
          notify(`Lockers successfully import for Federation ${federationId}`, {
            type: "success",
          })
        )
        .catch((err) =>
          notify(`Error importing remote Lockers: ${err}`, { type: "error" })
        ),

    regenerateSecret: (clientId: string) =>
      httpClient(`${apiUrl}/clients/${clientId}/regenerate-secret`, {
        method: "POST",
      }).then(({ json }) => ({
        data: json,
      })),

    retrieveMapsKey: () =>
      httpClient(`${apiUrl}/api-keys/google-maps`, {
        method: "GET",
      }).then(({ json }) => ({
        data: json,
      })),

    retrieveLockerStatuses: () =>
      httpClient(`${apiUrl}/locker-statuses/`, {
        method: "GET",
      }).then(({ json }) => ({
        data: json,
      })),

    addPriceToPlan: (
      planId: string,
      priceId: string,
      notify: (msg: string, config: object) => void
    ) =>
      httpClient(`${apiUrl}/plans/${planId}/add-price`, {
        method: "POST",
        body: JSON.stringify({ priceId }),
      })
        .then(() =>
          notify(`Price successfully added to Plan ${planId}`, {
            type: "success",
          })
        )
        .catch((err) =>
          notify(`Error adding price: ${err}`, { type: "error" })
        ),
  };
};
