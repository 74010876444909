import {
  TextField,
  NumberField,
  required,
  TextInput,
  SelectInput,
  NumberInput,
  FormDataConsumer,
  ShowBase,
  useStore,
} from "react-admin";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import { nameStrategyChoices } from "./base";
import { LockerGroupCreate, LockerGroupEdit } from "./LockerSpec";
import { useState, useEffect } from "react";
import { buildLockersSpec, validateFields } from "./lockersHelpers";

import { MyList } from "../../ra-lb-tools/components/myList";
import {
  MyEdit as Edit,
  MyCreate as Create,
} from "../../ra-lb-tools/components/myMutator";
import { Row } from "../../ra-lb-tools/components/row";
import { Column } from "../../ra-lb-tools/components/column";
import { TowerRenderColumn } from "../tower_render/TowerRenderColumn.js";
export const TowerModelIcon = PrecisionManufacturingIcon;

export const TowerModelList = () => (
  <MyList resource="tower-models">
    <TextField source="id" />
    <TextField source="name" />
    <TextField source="nameStrategy" />
    <TextField source="description" />
    <NumberField source="numColumns" />
    <NumberField source="lockerCount" sortable={false} />
    <NumberField source="lowLockerCount" sortable={false} />
    <NumberField source="towerCount" sortable={false} />
  </MyList>
);

export const TowerModelCreate = (props) => {
  const [numColumns, setNumColumns] = useState(1);
  const [lockers, setLockers] = useState([]);
  const [highlightList, setHighlightList] = useState([]);
  const [renderAlwaysVisible, setRenderAlwaysVisible] = useStore(
    "tower-render-always-visible",
    false
  );
  const [nameStrategy, setNameStrategy] = useState(true);
  const [rowRef, setRowRef] = useState({});

  const addRowRef = (record, ref) => {
    const key = record.physicalId;

    setRowRef((rowRef) => {
      rowRef[key] = ref;
      return rowRef;
    });
  };

  const scrollToRow = (record) => {
    const key = record.physicalId;

    rowRef[key]?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const handleNumColumnsChange = (event) => {
    setNumColumns(event.target.value);
  };

  const handleLockersChange = (newLockers) => {
    setLockers(newLockers);
  };

  useEffect(() => {
    const updatedLockers = lockers.filter(
      (locker) => locker.colPosition <= numColumns
    );
    setLockers(updatedLockers);
  }, [numColumns]);

  const transformLockersToOneField = (data) => {
    const lockersSpec = buildLockersSpec(data, lockers);
    return {
      ...data,
      lockersSpec: lockersSpec,
    };
  };

  const validateForm = (data) => {
    return validateFields(data, lockers);
  };

  const dataColumnSx = renderAlwaysVisible
    ? { width: "70%" }
    : { width: "100%" };
  const fullHeighClass = renderAlwaysVisible ? "h-100-overflow" : "";

  const TowerRender = (
    <TowerRenderColumn
      {...{
        lockers,
        numColumns,
        highlightList,
        setHighlightList,
        renderAlwaysVisible,
        setRenderAlwaysVisible,
        nameStrategy,
        onClick: scrollToRow,
        columnProps: {
          className: fullHeighClass,
        },
      }}
    />
  );

  return (
    <Row className={fullHeighClass}>
      <Create
        {...props}
        transform={transformLockersToOneField}
        redirect="show"
        formProps={{
          validate: validateForm,
          mode: "onBlur",
          reValidateMode: "onBlur",
        }}
        className={fullHeighClass}
        style={dataColumnSx}
        columns={1}
      >
        <Row>
          <Column>
            <Row>
              <Column>
                <TextInput source="name" validate={required()} />
                <SelectInput
                  choices={nameStrategyChoices}
                  source="nameStrategy"
                  optionValue="id"
                  optionText="name"
                  validate={required()}
                  onChange={(event) => setNameStrategy(event.target.value)}
                />
                <TextInput source="description" multiline />
                <NumberInput
                  source="numColumns"
                  onChange={handleNumColumnsChange}
                  defaultValue={1}
                />
                <TextInput source="pictureUrl" />
              </Column>
              {!renderAlwaysVisible && TowerRender}
            </Row>
            <LockerGroupCreate
              numColumns={numColumns}
              lockers={lockers}
              onChange={handleLockersChange}
              highlightList={highlightList}
              onMouseEnter={(locker) => setHighlightList([locker.physicalId])}
              onMouseLeave={() => setHighlightList([])}
              setRowRef={addRowRef}
            />
          </Column>
        </Row>
      </Create>
      {renderAlwaysVisible && TowerRender}
    </Row>
  );
};

export const TowerModelEdit = (props) => {
  const [numColumns, setNumColumns] = useState();
  const [lockers, setLockers] = useState([]);
  const [highlightList, setHighlightList] = useState([]);
  const [renderAlwaysVisible, setRenderAlwaysVisible] = useStore(
    "tower-render-always-visible",
    false
  );
  const [nameStrategy, setNameStrategy] = useState();
  const [rowRef, setRowRef] = useState({});

  const addRowRef = (record, ref) => {
    const key = record.physicalId;

    setRowRef((rowRef) => {
      rowRef[key] = ref;
      return rowRef;
    });
  };

  const scrollToRow = (record) => {
    const key = record.physicalId;

    rowRef[key]?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const handleNumColumnsChange = (event) => {
    setNumColumns(event.target.value);
  };

  const handleLockersChange = (newLockers) => {
    setLockers(newLockers);
  };

  const groupLockersAndFilterUpdatedFields = (data, { previousData }) => {
    const updatedData = {};

    const lockersSpec = buildLockersSpec(data, lockers);
    if (lockersSpec.length > 0) {
      data.lockersSpec = lockersSpec;
    }

    for (const fieldName in data) {
      if (fieldName === "lockersSpec") {
        if (
          JSON.stringify(data[fieldName]) !==
          JSON.stringify(previousData[fieldName])
        ) {
          updatedData[fieldName] = data[fieldName];
        }
      } else if (data[fieldName] !== previousData[fieldName]) {
        updatedData[fieldName] = data[fieldName];
      }
    }

    return updatedData;
  };
  const validateForm = (data) => {
    return validateFields(data, lockers);
  };

  const dataColumnSx = renderAlwaysVisible
    ? { width: "70%" }
    : { width: "100%" };
  const fullHeighClass = renderAlwaysVisible ? "h-100-overflow" : "";

  const TowerRender = (
    <TowerRenderColumn
      {...{
        lockers,
        numColumns,
        highlightList,
        setHighlightList,
        renderAlwaysVisible,
        setRenderAlwaysVisible,
        nameStrategy,
        onClick: scrollToRow,
        columnProps: {
          className: fullHeighClass,
        },
      }}
    />
  );

  return (
    <Row className={fullHeighClass}>
      <Edit
        {...props}
        transform={groupLockersAndFilterUpdatedFields}
        redirect="show"
        formProps={{
          mode: "onBlur",
          reValidateMode: "onBlur",
          validate: validateForm,
        }}
        className={fullHeighClass}
        style={dataColumnSx}
        columns={1}
      >
        <Row>
          <Column>
            <Row>
              <Column>
                <NumberInput source="id" disabled />
                <TextInput source="name" validate={required()} />
                <SelectInput
                  choices={nameStrategyChoices}
                  source="nameStrategy"
                  optionValue="id"
                  optionText="name"
                  validate={required()}
                  onChange={(event) => setNameStrategy(event.target.value)}
                />
                <TextInput source="description" multiline />
                <FormDataConsumer>
                  {({ formData }) => (
                    <NumberInput
                      source="numColumns"
                      onChange={handleNumColumnsChange}
                      defaultValue={numColumns}
                      disabled={formData.towerCount > 0}
                    />
                  )}
                </FormDataConsumer>

                <TextInput source="towerCount" disabled={true} />
              </Column>
              {!renderAlwaysVisible && TowerRender}
            </Row>
            <FormDataConsumer>
              {({ formData }) => (
                <LockerGroupEdit
                  numColumns={numColumns}
                  lockers={lockers}
                  onChange={handleLockersChange}
                  highlightList={highlightList}
                  onMouseEnter={(locker) =>
                    setHighlightList([locker.physicalId])
                  }
                  onMouseLeave={() => setHighlightList([])}
                  setRowRef={addRowRef}
                  towerCount={formData.towerCount}
                />
              )}
            </FormDataConsumer>
          </Column>
        </Row>
      </Edit>
      {renderAlwaysVisible && <ShowBase>{TowerRender}</ShowBase>}
    </Row>
  );
};
