import { DeleteWithConfirmButton } from "react-admin";
import { WithPermissions } from "./withPermissions";

export const MyDeleteButton = (props) => {
  return (
    <WithPermissions action={"delete"} label={"DeleteWithConfirmButton"}>
      <DeleteWithConfirmButton
        confirmContent="You will not be able to recover this record. Are you sure?"
        {...props}
      />
    </WithPermissions>
  );
};
