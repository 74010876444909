import { JsonStyled } from "../components/jsonStyled";
import { Card, CardContent } from "@mui/material";
import { Title } from "react-admin";
import { Store } from "../utils/store";

export const Status = () => {
  const data = Store.getAll();

  return (
    <Card>
      <Title title="Status" />
      <CardContent>
        <JsonStyled data={data} />
      </CardContent>
    </Card>
  );
};
