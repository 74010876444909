import {
  TextField,
  TextInput,
  BooleanInput,
  required,
  DateField,
} from "react-admin";
import { TitledShow } from "../../ra-lb-tools/components/titledRecord";
import BusinessIcon from "@mui/icons-material/Business";
import {
  MyReferenceField,
  MyReferenceInput,
} from "../../ra-lb-tools/components/myReference";
import { MyList } from "../../ra-lb-tools/components/myList";
import {
  MyEdit as Edit,
  MyCreate as Create,
} from "../../ra-lb-tools/components/myMutator";
import { LabeledColumn } from "../../ra-lb-tools/components/labeledColumn";
import { UserField, UserInput, UserList } from "../users";
import {
  PlanSubscriptionField,
  PlanSubscriptionList,
} from "./plan_subsciptions";
import { useParams } from "react-router-dom";
import { MyBooleanField as BooleanField } from "../../components/myBooleanField";
import { Row } from "../../ra-lb-tools/components/row";
import {
  MyTabbedShowLayout,
  Tab,
} from "../../ra-lb-tools/components/myTabbedShowLayout";
import { LockerUsageRecordsList } from "./lockerUsageRecord";
import { useState } from "react";
import { Box } from "@mui/material";
import get from "lodash/get";

export const OrganizationIcon = BusinessIcon;

export const OrganizationList = (props) => (
  <MyList {...props} resource="organizations">
    <TextField source="id" />
    <TextField source="name" />
    <BooleanField source="active" />
    <UserField source="accountOwnerId" />
    <UserField source="serviceAccountId" />
    <TextField source="customerId" />
    <PlanSubscriptionField source="planSubscriptionId" />
    <TextField source="phone" />
    <TextField source="billingAddress" />
    <TextField source="shippingAddress" />
    <DateField source="createdAt" showTime />
    <DateField source="updatedAt" showTime />
  </MyList>
);

export const OrganizationEdit = (props) => {
  return (
    <Edit {...props}>
      <TextInput source="name" validate={required()} />
      <UserInput source="accountOwnerId" validate={required()} />
      <UserInput source="serviceAccountId" />
      <TextInput source="phone" />
      <TextInput source="billingAddress" />
      <TextInput source="shippingAddress" />
      <BooleanInput source="active" />
    </Edit>
  );
};

export const OrganizationCreate = (props) => {
  return (
    <Create {...props}>
      <TextInput source="name" validate={required()} />
      <UserInput source="accountOwnerId" validate={required()} />
      <UserInput source="serviceAccountId" />
      <TextInput source="phone" />
      <TextInput source="billingAddress" />
      <TextInput source="shippingAddress" />
      <BooleanInput source="active" />
    </Create>
  );
};

export const OrganizationShow = () => {
  const id = parseInt(useParams()["id"]);

  return (
    <TitledShow>
      <MyTabbedShowLayout>
        <Tab label="Summary" can={true}>
          <Row className={"RecordSummary"}>
            <LabeledColumn>
              <TextField source="id" />
              <TextField source="name" />
              <BooleanField source="active" />
              <UserField source="accountOwnerId" />
              <UserField source="serviceAccountId" />
              <PlanSubscriptionField source="planSubscriptionId" />
            </LabeledColumn>
            <LabeledColumn>
              <TextField source="customerId" />
              <TextField source="phone" />
              <TextField source="billingAddress" />
              <TextField source="shippingAddress" />
              <DateField source="createdAt" showTime />
              <DateField source="updatedAt" showTime />
            </LabeledColumn>
          </Row>
        </Tab>
        <Tab
          label="Subscriptions"
          path="Subscriptions"
          can={{ action: "list", resource: "plan-subscriptions" }}
        >
          <PlanSubscriptionList filter={{ organization_id: id }} />
        </Tab>
        <Tab
          label="Locker Usage Records"
          path="locker-usage-records"
          can={{ action: "list", resource: "locker-usage-records" }}
        >
          <LockerUsageRecordsList filter={{ organization_id: id }} />
        </Tab>
        <Tab
          label="Upcoming Invoice"
          path="upcoming-invoice"
          can={{ action: "read_invoice", resource: "organization" }}
        >
          <OrganizationInvoiceShow />
        </Tab>
        <Tab
          label="Users"
          path="users"
          can={{ action: "list", resource: "users" }}
        >
          <UserList filter={{ organization_id: id }} />
        </Tab>
      </MyTabbedShowLayout>
    </TitledShow>
  );
};

export const OrganizationInvoiceShow = () => {
  const id = parseInt(useParams()["id"]);

  const [error, setError] = useState(false);
  const onError = (error) => {
    setError(
      "Error: " +
        get(error, "body.detail[0].msg", get(error, "message", "Unknown error"))
    );
  };

  return error ? (
    <Box>{error}</Box>
  ) : (
    <TitledShow
      queryOptions={{
        meta: {
          customSegment: "upcoming-invoice",
          transformResponse: (response) => ({
            ...response,
            id,
          }),
        },
        onError,
      }}
      noHeaders={true}
    >
      <Row>
        <LabeledColumn>
          <DateField source="created" showTime />
          <DateField source="periodStart" showTime />
          <DateField source="periodEnd" showTime />
          <TextField source="customerEmail" />
          <TextField source="customerName" />
          <TextField source="currency" />
        </LabeledColumn>
        <LabeledColumn>
          <TextField source="billingReason" />
          <TextField source="subtotal" />
          <TextField source="subtotalExcludingTax" />
          <TextField source="tax" />
          <TextField source="totalExcludingTax" />
          <TextField source="total" />
        </LabeledColumn>
      </Row>
    </TitledShow>
  );
};

export const OrganizationInput = (props) => {
  return (
    <MyReferenceInput
      source="organizationId"
      reference="organizations"
      {...props}
    />
  );
};

export const OrganizationField = (props) => {
  return (
    <MyReferenceField
      source="organizationId"
      reference="organizations"
      {...props}
    />
  );
};
