import { TextField, DateField, NumberInput, TextInput } from "react-admin";

import BusinessIcon from "@mui/icons-material/Business";
import { JsonField } from "../ra-lb-tools/components/jsonField";
import { MyList } from "../ra-lb-tools/components/myList";
import { MyReferenceField } from "../ra-lb-tools/components/myReference";
import { UserInput } from "./users";
import { LockerUsageEventList } from "./billing/lockerUsageEvent";
import { TitledShow } from "../ra-lb-tools/components/titledRecord";
import {
  MyTabbedShowLayout,
  Tab,
} from "../ra-lb-tools/components/myTabbedShowLayout";
import { LabeledColumn } from "../ra-lb-tools/components/labeledColumn";
import { useParams } from "react-router-dom";
import { Row } from "../ra-lb-tools/components/row";

export const SystemIcon = BusinessIcon;

export const LockerOpenRequestList = (props) => {
  const filters = [
    <NumberInput source="locker_id" alwaysOn key={1} label="Locker Id" />,
    <TextInput source="status" alwaysOn key={3} />,
    <UserInput source="userId" alwaysOn key={4} label="User" />,
  ];

  const title = "Locker Open Request";
  const resource = "locker-open-requests";

  return (
    <MyList
      title={title}
      resource={resource}
      sort={{
        field: "id",
        order: "DESC",
      }}
      filters={filters}
      {...props}
    >
      <TextField source="id" />
      <MyReferenceField source="lockerId" reference="lockers" />
      <TextField source="clientId" />
      <TextField source="returnUrl" />
      <TextField source="linkToken" />
      <TextField source="intent" />
      <TextField source="status" />
      <JsonField source="payload" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      <MyReferenceField
        source="userId"
        reference="users"
        primaryField="email"
      />
    </MyList>
  );
};

export const LockerOpenRequestShow = () => {
  const id = parseInt(useParams()["id"]);

  return (
    <TitledShow>
      <MyTabbedShowLayout>
        <Tab label="Summary" can={true}>
          <Row className={"RecordSummary"}>
            <LabeledColumn>
              <TextField source="id" />
              <MyReferenceField source="lockerId" reference="lockers" />
              <TextField source="clientId" />
              <TextField source="returnUrl" />
              <TextField source="linkToken" />
              <TextField source="intent" />
            </LabeledColumn>
            <LabeledColumn>
              <TextField source="status" />
              <DateField source="createdAt" showTime />
              <DateField source="updatedAt" showTime />
              <MyReferenceField
                source="userId"
                reference="users"
                primaryField="email"
              />
              <JsonField source="payload" />
            </LabeledColumn>
          </Row>
        </Tab>
        <Tab
          label="Locker Usage Events"
          path="locker-usage-events"
          can={{ action: "list", resource: "locker-usage-events" }}
        >
          <LockerUsageEventList filter={{ lockerOpenRequestId: id }} />
        </Tab>
      </MyTabbedShowLayout>
    </TitledShow>
  );
};
