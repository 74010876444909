import { NumberInput, TextField } from "react-admin";
import { JsonField } from "../ra-lb-tools/components/jsonField";
import { MyList } from "../ra-lb-tools/components/myList";
import { LockerField, LockerInput } from "../components/referenceFields";
import { TowerInput } from "./tower/towers";
import { TowerTimezonedDateField } from "./tower/towerTimezonedDate";
import { MyReferenceField } from "../ra-lb-tools/components/myReference";
export default function EventsList(props) {
  const filters = [
    <NumberInput source="id" alwaysOn key={1} />,
    <LockerInput source="locker_id" alwaysOn key={2} />,
    <TowerInput source="tower_id" alwaysOn key={3} />,
    <NumberInput source="packet_type" alwaysOn key={4} />,
  ];

  const resource = "events";

  return (
    <MyList {...props} resource={resource} filters={filters}>
      <TextField source="id" />
      <TextField source="packetTypeName" sortable={false} />
      <JsonField source="eventData" />
      <TowerTimezonedDateField
        label="Packet timestamp"
        source="eventData.packet_timestamp"
        showTime={true}
        sortable={false}
      />
      <TowerTimezonedDateField
        label="Received"
        source="received"
        showTime={true}
      />
      <TextField source="description" sortable={false} />
      <LockerField
        source="eventData.locker_id"
        label="Locker"
        sortable={false}
      />
      <MyReferenceField source="towerId" reference="towers" link="show" />
    </MyList>
  );
}
