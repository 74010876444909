import { ExpandLess, ExpandMore, Menu } from "@mui/icons-material";
import { FieldInput } from "../../ra-lb-tools/components/unsourcedInputs";
import {
  Box,
  IconButton,
  MenuItem,
  Select,
  Slider,
  Typography,
} from "@mui/material";
import inflection from "inflection";
import { useState } from "react";

const custom_options = {
  highlight_shield: (value, index, props) => (
    <Select
      size="small"
      key={index}
      value={value}
      label={"Highlight shield"}
      onChange={(event) =>
        optionSetter("highlight_shield", event.target.value, props)
      }
    >
      <MenuItem value={0}>All shields</MenuItem>
      {Array.from({ length: props.shieldCount }, (_, i) => (
        <MenuItem value={i + 1}>Shield {i + 1}</MenuItem>
      ))}
    </Select>
  ),
  port: (value, index, props) => (
    <FieldInput
      key={index}
      {...{
        name: "port",
        value,
        label: "Port [#]",
        setter: (value) => optionSetter("port", value, props),
      }}
    />
  ),
  broken__box_angle: (value, index, props) => (
    <Box
      className={"RenderOption Slide"}
      sx={{ width: "25%", margin: "0 15px", textAlign: "center" }}
      key={index}
    >
      <Typography>Box Angle</Typography>
      <Slider
        value={value}
        onChange={(event, newValue) =>
          optionSetter("box_angle", newValue, props)
        }
        valueLabelDisplay="auto"
        min={0}
        max={360}
        step={5}
      />
    </Box>
  ),
  broken__box_depth: (value, index, props) => (
    <Box
      className={"RenderOption Slide"}
      sx={{ width: "25%", margin: "0 15px", textAlign: "center" }}
      key={index}
    >
      <Typography>Box Depth</Typography>
      <Slider
        value={value}
        onChange={(event, newValue) =>
          optionSetter("box_depth", newValue, props)
        }
        valueLabelDisplay="auto"
        min={0}
        max={100}
        step={10}
      />
    </Box>
  ),
  unit: (value, index, props) => (
    <Select
      size="small"
      key={index}
      value={value}
      label={"Unit"}
      onChange={(event) => optionSetter("unit", event.target.value, props)}
    >
      <MenuItem value={"inch"}>inch</MenuItem>
      <MenuItem value={"cm"}>cm</MenuItem>
    </Select>
  ),
  ruler: (value, index, props) => (
    <Select
      size="small"
      key={index}
      value={value}
      label={"Ruler"}
      onChange={(event) => optionSetter("ruler", event.target.value, props)}
    >
      <MenuItem value={""}>No ruler</MenuItem>
      <MenuItem value={"tower"}>Tower ruler</MenuItem>
      <MenuItem value={"locker"}>Locker ruler</MenuItem>
      <MenuItem value={"all"}>All rulers</MenuItem>
    </Select>
  ),
};

const optionSetter = (name, value, props) =>
  props.onChange && props.onChange({ [name]: value });

const OptionBar = (props) => {
  const showOptions = [
    ["name", "type", "access", "status"],
    ["port", "shield", "highlight_shield"],
    ["legend", "proportional", "ruler", "unit"],
    ["box_angle", "box_depth"],
  ];

  const uncategorized = Object.keys(props.options).filter(
    (item) => !showOptions.flat(Infinity).includes(item)
  );
  if (uncategorized.length) {
    showOptions.push(uncategorized);
  }

  const sx = props.columnStyle ? {} : { display: "flex", flexWrap: "wrap" };
  const rows = [];

  showOptions.forEach((row, rowIndex) => {
    const fields = [];
    row.forEach((name, index) => {
      if (name == "status" && !props.hasStatus) return;
      if (
        props.shieldCount < 2 &&
        ["shield", "highlight_shield"].includes(name)
      )
        return;

      const value = props.options[name];
      const field = custom_options[name] ? (
        custom_options[name](value, index, props)
      ) : (
        <FieldInput
          key={index}
          {...{
            name,
            value,
            label: inflection.humanize(name),
            setter: (value) => optionSetter(name, value, props),
          }}
        />
      );
      fields.push(field);
    });
    rows.push(
      <Box className={"OptionRow"} sx={sx} key={rowIndex}>
        {fields}
      </Box>
    );
  });
  return <Box className="OptionBar">{rows}</Box>;
};

export const ToolBox = ({
  alwaysVisible,
  onAlwaysVisibleChange,
  options,
  setOptions,
  hasStatus,
  shieldCount,
}) => {
  const [unfolded, setUnfolded] = useState(false);

  const sx = {
    border: "1px solid black",
    padding: "5px",
  };

  const foldSwitchSx = {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "red",
    fontSize: "1.2em",
    cursor: "pointer",
  };

  const FoldSwitch = (
    <Box
      className="FoldSwitch"
      sx={foldSwitchSx}
      onClick={() => setUnfolded((v) => !v)}
    >
      <IconButton>
        <Menu />
      </IconButton>
      <Box className="FoldSwitchLabel">Diagram Tools</Box>
      <IconButton>{unfolded ? <ExpandLess /> : <ExpandMore />}</IconButton>
    </Box>
  );

  const AlwaysVisibleSwitch = onAlwaysVisibleChange
    ? FieldInput({
        value: !!alwaysVisible,
        setter: (value) =>
          onAlwaysVisibleChange && onAlwaysVisibleChange(value),
        name: "alwaysVisible",
        label: "Always visible",
      })
    : null;

  return (
    <Box className="ToolBox" sx={sx}>
      <Box sx={{ display: "flex" }}>
        {AlwaysVisibleSwitch}
        {FoldSwitch}
      </Box>
      {unfolded && (
        <Box>
          <OptionBar
            options={options}
            onChange={(entry) =>
              setOptions((_oldOptions) => ({ ..._oldOptions, ...entry }))
            }
            hasStatus={hasStatus}
            shieldCount={shieldCount}
          />
        </Box>
      )}
    </Box>
  );
};
