import { AdminUI, Loading, useDataProvider, useTheme } from "react-admin";

import { resources as all_resources } from "./Resources";
import MyLayout from "./theme/theme";
import Dashboard from "./views/Dashboard";
import { cloneElement, useEffect, useState } from "react";
import { FactoryAccessControlProvider } from "./ra-lb-tools/utils/access";
import { asArray, getValue } from "./ra-lb-tools/utils/tools";

const resourceActions = ["list", "edit", "create", "show"];

const sanitizeResource = (_res, acp) => {
  const resource = getValue(_res, "props.name");
  const can = getValue(_res, "props.options.can");
  const allowedActions = acp.allowedActions({ resource });

  let res = null;

  if (can) {
    if (acp.can(can)) {
      res = cloneElement(_res);
    }
  } else if (allowedActions.length) {
    const removedActions = resourceActions.filter(
      (item) => !allowedActions.includes(item)
    );
    const newProps = {
      ..._res.props,
      ...Object.fromEntries(removedActions.map((key) => [key, undefined])),
    };

    //TODO: check custom inner routes
    res = cloneElement(_res, newProps);
  }

  return res;
};

const sanitizeCustomRoutes = (_res, acp) => {
  const children = asArray(getValue(_res, "props.children", [])).flat(1);
  const newChildren = children
    .map((_child) => {
      const path = getValue(_child, "props.path");
      const isAllowedPath =
        !path ||
        getValue(_child, "props.customRoute") ||
        acp.isAllowedPath({ path });

      if (isAllowedPath) {
        return cloneElement(_child);
      } else {
        return null;
      }
    })
    .filter((v) => v);

  const res = cloneElement(_res, { children: newChildren });

  return res;
};

export const AllowedResources = () => {
  const [resources, setResources] = useState([]);
  const dataProvider = useDataProvider();

  const [theme, setTheme] = useTheme();

  useEffect(() => {
    FactoryAccessControlProvider(dataProvider).then((acp) => {
      const allowed_resources = all_resources
        .map((_res) => {
          const type = getValue(_res, "type.raName");
          let res = null;

          if (type == "Resource") {
            res = sanitizeResource(_res, acp);
          } else {
            //custom routes
            res = sanitizeCustomRoutes(_res, acp);
          }

          return res;
        })
        .filter((v) => v);

      setResources(allowed_resources);
      setTheme(theme);
    });
  }, []);

  return (
    <AdminUI
      disableTelemetry
      ready={Loading}
      layout={MyLayout}
      dashboard={Dashboard}
    >
      {resources}
    </AdminUI>
  );
};
