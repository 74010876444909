import get from "lodash/get";

const getRequestIdLabel = (error) => {
  let request_id =
    get(error, "body.request_id") || get(error, "request_id") || "";

  if (request_id) {
    request_id = ` (request_id: ${request_id} )`;
  }

  return request_id;
};

const getDetailLabel = (error) => {
  let detail = get(error, "body.detail") || get(error, "detail") || "";

  if (Array.isArray(detail)) {
    return detail;
  }

  if (detail) {
    detail = ` -> ${detail}`;
  }

  return detail;
};

export const getErrorMessage = (error) => {
  const detail = getDetailLabel(error);
  const request_id_label = getRequestIdLabel(error);

  if (Array.isArray(detail)) {
    return detail
      .map((err) => {
        const loc = err.loc;

        let source = Array.isArray(loc) ? loc[loc.length - 1] : "";
        source = source ? source + ": " : source;

        let errorIdx =
          Array.isArray(loc) && loc.length > 1 ? loc[loc.length - 2] : "";
        errorIdx = Number.isInteger(errorIdx) ? `Entry #${errorIdx}: ` : "";

        const msg = err.msg || get(err, "ctx.message");

        return `${errorIdx}${source}${msg}`;
      })
      .join(". ");
  }

  const message = error.message + detail + request_id_label;

  return message;
};

export const errorDisplayConfig = {
  autoHideDuration: 10000,
  multiLine: true,
  type: "error",
};

export const errorHandlingFactory = (notifier) => {
  const onError = (error) => {
    notifier(getErrorMessage(error), errorDisplayConfig);
  };
  return onError;
};
