import { TextField, DateField, NumberInput } from "react-admin";

import BusinessIcon from "@mui/icons-material/Business";
import { JsonField } from "../ra-lb-tools/components/jsonField";
import { MyList } from "../ra-lb-tools/components/myList";
import { MyReferenceField } from "../ra-lb-tools/components/myReference";

export const SystemIcon = BusinessIcon;

const filters = [
  <NumberInput source="locker_id" alwaysOn key={1} label="Locker Id" />,
];

export const LockerRequestList = (props) => {
  const title = "Federation Locker Request";
  const resource = "locker-requests";

  return (
    <MyList
      title={title}
      resource={resource}
      sort={{
        field: "id",
        order: "DESC",
      }}
      filters={filters}
      {...props}
    >
      <TextField source="id" />
      <MyReferenceField source="lockerId" reference="lockers" />
      <TextField source="lockerRequestId" />
      <JsonField source="responseBody" />
      <DateField source="requestTimestamp" showTime />
      <DateField source="releasedAt" showTime />
      <MyReferenceField
        source="federationId"
        reference="federations"
        primaryField="remoteId"
      />
      <MyReferenceField
        source="userId"
        reference="users"
        primaryField="email"
      />
    </MyList>
  );
};
