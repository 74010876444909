import { TextField, TextInput, required } from "react-admin";
import {
  MyCreate as Create,
  MyEdit as Edit,
} from "../ra-lb-tools/components/myMutator";
import { MyList } from "../ra-lb-tools/components/myList";
import { TitledShow } from "../ra-lb-tools/components/titledRecord";
import { Row } from "../ra-lb-tools/components/row";
import { LabeledColumn } from "../ra-lb-tools/components/labeledColumn";
import {
  MyTabbedShowLayout,
  Tab,
} from "../ra-lb-tools/components/myTabbedShowLayout";

const backToFront = (response) => ({
  ...response,
  _id: response.id,
  id: response.name,
});

export const SPWebHooksCreate = (props) => {
  return (
    <Create columns={1} {...props}>
      <TextInput source="sp_client_id" validate={required()} />
      <TextInput source="url" validate={required()} />
    </Create>
  );
};

export const SPWebHooksList = (props) => (
  <MyList
    {...props}
    resource="webhooks"
    queryOptions={{
      meta: {
        transformResponse: backToFront,
      },
    }}
  >
    <TextField source="_id" label="Id" sortable={false} />
    <TextField source="name" label="name/client_id" sortable={false} />
    <TextField source="url" sortable={false} />
  </MyList>
);

export const SPWebHooksEdit = (props) => {
  return (
    <Edit
      queryOptions={{
        meta: {
          transformResponse: backToFront,
        },
      }}
      {...props}
    >
      <TextInput source="id" disabled />
      <TextInput source="_id" label="Remote ID" disabled />
      <TextInput source="name" label="name/client_id" disabled />
      <TextInput source="url" />
    </Edit>
  );
};

export const SPWebHooksShow = () => {
  return (
    <TitledShow
      queryOptions={{
        meta: {
          transformResponse: backToFront,
        },
      }}
    >
      <MyTabbedShowLayout>
        <Tab label="Summary" can={true}>
          <Row className={"RecordSummary"}>
            <LabeledColumn>
              <TextField source="_id" label="Remote ID" />
              <TextField source="name" label="name/client_id" />
              <TextField source="url" />
            </LabeledColumn>
          </Row>
        </Tab>
      </MyTabbedShowLayout>
    </TitledShow>
  );
};
