import {
  Link,
  TextField,
  TextInput,
  useRecordContext,
  WithRecord,
} from "react-admin";
import { MyList } from "../ra-lb-tools/components/myList";
import { LocationField, LocationInput } from "./locations/components";
import { DeveloperMode } from "@mui/icons-material";
import { getValue } from "../ra-lb-tools/utils/tools";
import { Row } from "../ra-lb-tools/components/row";
import { TitledShow } from "../ra-lb-tools/components/titledRecord";
import { LabeledColumn } from "../ra-lb-tools/components/labeledColumn";
import {
  MyTabbedShowLayout,
  Tab,
} from "../ra-lb-tools/components/myTabbedShowLayout";

const firmware_uri =
  "https://gitlab.com/luxer-one/harborlockers/hal-firmware/-/releases/";

const getReleaseUri = (release) => firmware_uri + release;

export const TagNameField = (props) => {
  const record = useRecordContext();
  if (!record) return null;

  const tagField = props.source || "tagName";
  const release = getValue(record, tagField);

  return (
    <Link to={getReleaseUri(release)} target="_blank">
      <TextField {...props} />
    </Link>
  );
};
export const LocationFirmwareIcon = DeveloperMode;

export const LocationFirmwareList = (props) => {
  const filters = [
    <TextInput source="tagName" key="tagName" alwaysOn />,
    <TextInput source="version" key="version" alwaysOn />,
    <LocationInput source="locationId" key="locationId" />,
  ];

  return (
    <MyList resource="location-firmware" filters={filters} {...props}>
      <TextField source="id" />
      <LocationField source="locationId" />
      <TagNameField source="tagName" />
      <TextField source="version" />
    </MyList>
  );
};

export const LocationFirmwareShow = () => {
  return (
    <TitledShow labelField={"tagName"}>
      <MyTabbedShowLayout>
        <Tab label="Summary" can={true}>
          <Row className={"RecordSummary"}>
            <LabeledColumn>
              <TextField source="id" />
              <LocationField source="locationId" />
              <TagNameField source="tagName" />
              <TextField source="version" />
            </LabeledColumn>
          </Row>
        </Tab>
        <Tab
          label="Locations"
          path="locations"
          can={{ action: "list", resource: "location-firmware" }}
        >
          <WithRecord
            render={({ tagName }) => (
              <LocationFirmwareList
                filter={{ tagName }}
                noHeaders
                dataGridProps={{ omit: ["Show"] }}
              />
            )}
          />
        </Tab>
      </MyTabbedShowLayout>
    </TitledShow>
  );
};
