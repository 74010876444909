import {
  TextField,
  TextInput,
  ReferenceInput,
  ReferenceField,
  Labeled,
} from "react-admin";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ImportRemoteLockersButton from "../components/buttonImportRemoteLockers";
import AutocompleteInputFluid from "../ra-lb-tools/components/autocompleteInputFluid";
import { LocationField, LocationInput } from "./locations/components";
import { MyList } from "../ra-lb-tools/components/myList";
import {
  MyEdit as Edit,
  MyCreate as Create,
} from "../ra-lb-tools/components/myMutator";
import { SystemField, SystemInput } from "./systems";
import { TitledShow } from "../ra-lb-tools/components/titledRecord";
import { LabeledColumn } from "../ra-lb-tools/components/labeledColumn";
import {
  MyTabbedShowLayout,
  Tab,
} from "../ra-lb-tools/components/myTabbedShowLayout";
import { Row } from "../ra-lb-tools/components/row";
import { LockerRequestList } from "./lockerRequest";
import { useParams } from "react-router";

export const LocationIcon = LocationOnIcon;

export const FederationList = (props) => (
  <MyList {...props} resource="federations">
    <TextField source="id" />
    <TextField source="remoteId" />
    <SystemField label="System" />
    <LocationField label="Location" />
    <ReferenceField
      source="lockerProviderId"
      reference="locker-providers"
      link="show"
    >
      <TextField source="name" />
    </ReferenceField>
    <ImportRemoteLockersButton />
  </MyList>
);

export const FederationEdit = (props) => (
  <Edit {...props} labelField="remoteId">
    <TextInput disabled source="id" />
    <TextInput disabled source="remoteId" />
    <SystemInput disabled label="System" slack={10} />
    <LocationInput />
  </Edit>
);

export const FederationShow = (props) => {
  const { id } = useParams();

  return (
    <TitledShow {...props} labelField="remoteId">
      <MyTabbedShowLayout>
        <Tab label="Summary" can={true}>
          <Row className={"RecordSummary"}>
            <LabeledColumn>
              <TextField source="id" />
              <TextField source="remoteId" />
              <SystemField label="System" />
              <Labeled>
                <LocationField label="Location" />
              </Labeled>
              <ReferenceField
                source="lockerProviderId"
                reference="locker-providers"
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>
            </LabeledColumn>
          </Row>
        </Tab>
        <Tab
          label="Locker Requests"
          path="locker-requests"
          can={{ action: "list", resource: "locker-request" }}
        >
          <LockerRequestList filter={{ federation_id: parseInt(id) }} />
        </Tab>
      </MyTabbedShowLayout>
    </TitledShow>
  );
};

export const FederationCreate = (props) => (
  <Create {...props}>
    <TextInput source="remoteId" />
    <ReferenceInput source="ownerId" reference="users">
      <AutocompleteInputFluid
        filterToQuery={(searchText) => ({ email: `%${searchText}` })}
        optionText="email"
        optionValue="id"
      ></AutocompleteInputFluid>
    </ReferenceInput>
    <TextInput source="systemName" />
    <ReferenceInput source="lockerProviderId" reference="locker-providers">
      <AutocompleteInputFluid
        filterToQuery={(searchText) => ({ name: `%${searchText}` })}
        optionText="name"
        optionValue="id"
      ></AutocompleteInputFluid>
    </ReferenceInput>
  </Create>
);
