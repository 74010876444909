import { Route } from "react-router-dom";
import {
  UserIcon,
  UserEdit,
  UserCreate,
  UserShow,
  UserPage,
} from "./views/users";
import {
  LocationIcon,
  LocationList,
  LocationEdit,
} from "./views/locations/locations";
import LocationCreate, {
  LocationBulkCreate,
} from "./views/locations/locationCreate";
import {
  SystemIcon,
  SystemList,
  SystemEdit,
  SystemCreate,
  SystemShow,
} from "./views/systems";
import {
  TowerIcon,
  TowerList,
  TowerEdit,
  TowerCreate,
  TowerBulkCreate,
  TowerTowerModelEdit,
} from "./views/tower/towers";
import { TowerShow } from "./views/tower/TowerDetail";
import {
  LockerIcon,
  LockerList,
  LockerEdit,
  LockerShow,
  LockerKeypadEdit,
  LockerStatusEdit,
} from "./views/lockers";
import {
  ReservationList,
  ReservationIcon,
  ReservationShow,
} from "./views/reservations";
import {
  openLockerTokenIcon,
  openLockerTokenList,
  OpenLockerTokenShow,
} from "./views/openLockerTokens";
import {
  clientsList,
  clientsView,
  clientsEdit,
  clientsCreate,
  ClientIcon,
} from "./views/clients";
import {
  TowerModelCreate,
  TowerModelEdit,
  TowerModelIcon,
  TowerModelList,
} from "./views/tower_model/TowerModel";
import {
  TowerHistoryList,
  TowerHistoryListView,
  TowerHistoryShow,
} from "./views/towerHistory";

import {
  LockerProviderList,
  LockerProviderCreate,
  LockerProviderEdit,
  LockerProviderShow,
  BusinessViewIcon,
} from "./views/lockerProviders";

import {
  FederationCreate,
  FederationEdit,
  FederationList,
  FederationShow,
} from "./views/federatios";

import { KeyIcon, KeyList } from "./views/keys";
import { TowerModelShow } from "./views/tower_model/TowerModelDetail";
import {
  LocationFirmwareEdit,
  LocationShow,
} from "./views/locations/locationShow";
import { myCustomRoutes } from "./ra-lb-tools/components/customRoutes";
import { LockerUsageList, LockerUsageShow } from "./views/billing/lockerUsage";
import {
  LockerTypeCreate,
  LockerTypeEdit,
  LockerTypeIcon,
  LockerTypeList,
  LockerTypeShow,
} from "./views/lockerTypes";
import {
  OrganizationCreate,
  OrganizationEdit,
  OrganizationIcon,
  OrganizationList,
  OrganizationShow,
} from "./views/billing/organizations";
import {
  PlanCreate,
  PlanIcon,
  PlanList,
  PlanShow,
} from "./views/billing/plans";
import {
  ProductCreate,
  ProductIcon,
  ProductList,
  ProductShow,
} from "./views/billing/products";
import {
  PlanSubscriptionCreate,
  PlanSubscriptionIcon,
  PlanSubscriptionList,
  PlanSubscriptionShow,
} from "./views/billing/plan_subsciptions";
import { PriceIcon, PriceList, ProductPriceList } from "./views/billing/prices";
import CommandsList from "./views/commands";
import EventsList from "./views/events";
import {
  DeviceEdit,
  DeviceIcon,
  DeviceList,
  DeviceShow,
} from "./views/devices";
import { DeviceHistoryIcon, DeviceHistoryList } from "./views/deviceHistory";
import {
  LocationFirmwareIcon,
  LocationFirmwareList,
  LocationFirmwareShow,
} from "./views/firmware";
import {
  PolicyCreate,
  PolicyEdit,
  PolicyIcon,
  PolicyList,
  PolicyShow,
} from "./views/policies";
import {
  LocationImageIcon,
  LocationImageList,
  LocationImageShow,
} from "./views/locationImage";
import { CustomRoutes, Resource } from "react-admin";
import { PermissionIcon, PermissionList } from "./views/permissions";
import { TaskCreate } from "./views/tasks";
import { RoomPreferences, Timeline, Webhook } from "@mui/icons-material";
import { LockerRequestList } from "./views/lockerRequest";
import { LockerUsageRecordsList } from "./views/billing/lockerUsageRecord";
import {
  LockerOpenRequestList,
  LockerOpenRequestShow,
} from "./views/lockerOpenRequest";
import {
  SPWebHooksCreate,
  SPWebHooksEdit,
  SPWebHooksList,
  SPWebHooksShow,
} from "./views/sp_webhooks";
import {
  LockerUsageEventList,
  LockerUsageEventShow,
} from "./views/billing/lockerUsageEvent";

export const resources = [
  <Resource
    name="towers"
    key="towers"
    list={TowerList}
    show={TowerShow}
    edit={TowerEdit}
    create={TowerCreate}
    icon={TowerIcon}
  >
    <Route path=":towerId/history" element={<TowerHistoryListView />} />
    <Route path=":id/model" element={<TowerTowerModelEdit />} />
  </Resource>,
  <Resource
    name="users"
    key="users"
    list={UserPage}
    edit={UserEdit}
    create={UserCreate}
    show={UserShow}
    icon={UserIcon}
  />,
  <Resource
    name="locations"
    key="locations"
    list={LocationList}
    edit={LocationEdit}
    create={LocationCreate}
    show={LocationShow}
    icon={LocationIcon}
  >
    <Route path=":id/firmware" element={<LocationFirmwareEdit />} />
  </Resource>,
  <Resource
    name="systems"
    key="systems"
    list={SystemList}
    edit={SystemEdit}
    create={SystemCreate}
    show={SystemShow}
    icon={SystemIcon}
  />,
  <Resource
    name="clients"
    key="clients"
    list={clientsList}
    show={clientsView}
    edit={clientsEdit}
    create={clientsCreate}
    icon={ClientIcon}
  />,
  <Resource
    name="policies"
    key="policies"
    list={PolicyList}
    create={PolicyCreate}
    edit={PolicyEdit}
    show={PolicyShow}
    icon={PolicyIcon}
    options={{
      group: "More",
      sortOrder: 100,
    }}
  />,
  <Resource
    name="permissions"
    key="permissions"
    list={PermissionList}
    icon={PermissionIcon}
    options={{
      group: "More",
      sortOrder: 101,
      can: true,
    }}
  />,
  <Resource
    name="organizations"
    key="organizations"
    list={OrganizationList}
    edit={OrganizationEdit}
    create={OrganizationCreate}
    show={OrganizationShow}
    icon={OrganizationIcon}
    options={{
      group: "billing",
    }}
  />,
  <Resource
    name="products"
    key="products"
    list={ProductList}
    show={ProductShow}
    create={ProductCreate}
    icon={ProductIcon}
    options={{
      group: "billing",
    }}
  >
    <Route path=":productId/prices" element={<ProductPriceList />} />
  </Resource>,
  <Resource
    name="prices"
    key="prices"
    list={PriceList}
    icon={PriceIcon}
    recordRepresentation={(record) =>
      `${record.product_data && record.product_data.name} ${
        record.unit_amount
      } ${record.currency} ${record.billing_scheme}`
    }
    options={{
      group: "billing",
    }}
  />,
  <Resource
    name="plans"
    key="plans"
    list={PlanList}
    create={PlanCreate}
    show={PlanShow}
    icon={PlanIcon}
    options={{
      group: "billing",
    }}
  />,
  <Resource
    name="plan-subscriptions"
    key="plan-subscriptions"
    list={PlanSubscriptionList}
    create={PlanSubscriptionCreate}
    show={PlanSubscriptionShow}
    icon={PlanSubscriptionIcon}
    options={{
      group: "billing",
    }}
  />,
  <Resource
    name="tower-models"
    key="tower-models"
    list={TowerModelList}
    show={TowerModelShow}
    create={TowerModelCreate}
    edit={TowerModelEdit}
    icon={TowerModelIcon}
    options={{
      group: "More",
    }}
  />,
  <Resource
    name="locker-providers"
    key="locker-providers"
    list={LockerProviderList}
    create={LockerProviderCreate}
    edit={LockerProviderEdit}
    show={LockerProviderShow}
    icon={BusinessViewIcon}
    options={{
      group: "More",
    }}
  />,
  <Resource
    name="federations"
    key="federations"
    list={FederationList}
    create={FederationCreate}
    edit={FederationEdit}
    show={FederationShow}
    options={{
      group: "More",
    }}
  />,
  <Resource
    name="devices"
    key="devices"
    list={DeviceList}
    show={DeviceShow}
    edit={DeviceEdit}
    icon={DeviceIcon}
    options={{
      group: "More",
    }}
  />,
  <Resource
    name="location-firmware"
    key="location-firmware"
    list={LocationFirmwareList}
    show={LocationFirmwareShow}
    icon={LocationFirmwareIcon}
    options={{
      group: "More",
    }}
  />,
  <Resource
    name="location-image"
    key="location-image"
    list={LocationImageList}
    show={LocationImageShow}
    icon={LocationImageIcon}
    options={{
      group: "More",
    }}
  />,
  <Resource
    name="lockers"
    key="lockers"
    list={LockerList}
    edit={LockerEdit}
    icon={LockerIcon}
    show={LockerShow}
    options={{
      group: "legacy",
    }}
  >
    <Route path=":id/keypad" element={<LockerKeypadEdit />} />
    <Route path=":id/status" element={<LockerStatusEdit />} />
  </Resource>,
  <Resource
    name="keys"
    key="keys"
    list={KeyList}
    icon={KeyIcon}
    options={{
      group: "legacy",
    }}
  />,
  <Resource
    name="reservations"
    key="reservations"
    list={ReservationList}
    icon={ReservationIcon}
    show={ReservationShow}
    options={{
      group: "legacy",
    }}
  />,
  <Resource
    name="open-locker-tokens"
    key="open-locker-tokens"
    list={openLockerTokenList}
    show={OpenLockerTokenShow}
    icon={openLockerTokenIcon}
    options={{
      group: "legacy",
    }}
  />,
  <Resource
    name="locker-requests"
    key="locker-requests"
    list={LockerRequestList}
    icon={LocationImageIcon}
    options={{
      group: "More",
    }}
  />,
  <Resource
    name="locker-usage"
    key="locker-usage"
    list={LockerUsageList}
    show={LockerUsageShow}
    icon={Timeline}
    options={{
      group: "Billing Activity",
    }}
  />,
  <Resource
    name="locker-usage-events"
    key="locker-usage-events"
    list={LockerUsageEventList}
    show={LockerUsageEventShow}
    icon={Timeline}
    options={{
      group: "Billing Activity",
    }}
  />,
  <Resource
    name="commands"
    key="commands"
    list={CommandsList}
    icon={openLockerTokenIcon}
    options={{
      group: "legacy",
    }}
  />,
  <Resource
    name="events"
    key="events"
    list={EventsList}
    icon={openLockerTokenIcon}
    options={{
      group: "legacy",
    }}
  />,
  <Resource
    name="tower-history"
    key="tower-history"
    list={TowerHistoryList}
    show={TowerHistoryShow}
    icon={openLockerTokenIcon}
    options={{
      group: "legacy",
    }}
  />,
  <Resource
    name="locker-types"
    key="locker-types"
    list={LockerTypeList}
    edit={LockerTypeEdit}
    create={LockerTypeCreate}
    show={LockerTypeShow}
    icon={LockerTypeIcon}
    options={{
      group: "legacy",
    }}
  />,
  <Resource
    name="device-history"
    key="device-history"
    list={DeviceHistoryList}
    icon={DeviceHistoryIcon}
    options={{
      group: "legacy",
    }}
  />,
  <Resource
    name="tasks"
    key="tasks"
    list={TaskCreate}
    icon={DeviceHistoryIcon}
    options={{
      group: "Server Management",
    }}
  />,
  <Resource
    name="locker-usage-records"
    key="locker-usage-records"
    list={LockerUsageRecordsList}
    icon={LocationImageIcon}
    options={{
      group: "Billing Activity",
    }}
  />,
  <Resource
    name="locker-open-requests"
    key="locker-open-requests"
    list={LockerOpenRequestList}
    show={LockerOpenRequestShow}
    icon={RoomPreferences}
    options={{
      group: "opener",
    }}
  />,
  <Resource
    name="webhooks"
    key="webhooks"
    list={SPWebHooksList}
    show={SPWebHooksShow}
    create={SPWebHooksCreate}
    edit={SPWebHooksEdit}
    icon={Webhook}
    options={{
      group: "opener",
    }}
  />,
  <CustomRoutes key="CustomRoutes">
    {myCustomRoutes()}
    <Route path="/locations/bulk-create" element={<LocationBulkCreate />} />
    <Route path="/towers/bulk-create" element={<TowerBulkCreate />} />
  </CustomRoutes>,
];
