import {
  TextField,
  TextInput,
  NumberInput,
  SimpleFormIterator,
  ArrayInput,
  SelectInput,
} from "react-admin";
import BusinessIcon from "@mui/icons-material/Business";
import { JsonField } from "react-admin-json-view";
import { JsonInput } from "react-admin-json-view";
import { TitledShow } from "../ra-lb-tools/components/titledRecord";
import { FederationList } from "./federatios";
import { useParams } from "react-router-dom";
import { MyList } from "../ra-lb-tools/components/myList";
import {
  MyEdit as Edit,
  MyCreate as Create,
} from "../ra-lb-tools/components/myMutator";
import { Row } from "../ra-lb-tools/components/row";
import { LabeledColumn } from "../ra-lb-tools/components/labeledColumn";
import {
  MyTabbedShowLayout,
  Tab,
} from "../ra-lb-tools/components/myTabbedShowLayout";

export const BusinessViewIcon = BusinessIcon;

const typeOptions = [
  { id: "luxer", name: "Luxer" },
  { id: "test", name: "Test" },
];

export const LockerProviderList = () => (
  <MyList resource="locker-providers">
    <TextField source="id" />
    <TextField source="name" />
    <TextField source="type" />
    <JsonField
      source="settings"
      reactJsonOptions={{
        name: null,
        collapsed: true,
        enableClipboard: false,
        displayDataTypes: false,
        theme: "google",
      }}
    />
    <TextField source="webhookPath" />
  </MyList>
);

export const LockerProviderShow = () => {
  const id = parseInt(useParams()["id"]);

  return (
    <TitledShow>
      <MyTabbedShowLayout>
        <Tab label="Summary" can={true}>
          <Row sx={{ justifyContent: "start" }} className={"RecordSummary"}>
            <LabeledColumn>
              <TextField source="id" />
              <TextField source="name" />
              <TextField source="type" />
              <TextField source="webhookPath" />
              <JsonField
                source="settings"
                reactJsonOptions={{
                  name: "settings",
                  collapsed: false,
                  enableClipboard: false,
                  displayDataTypes: false,
                  theme: "google",
                }}
              />
            </LabeledColumn>
          </Row>
        </Tab>
        <Tab
          label="Federations"
          path="federations"
          can={{ action: "list", resource: "federations" }}
        >
          <FederationList filter={{ locker_provider_id: id }} />
        </Tab>
      </MyTabbedShowLayout>
    </TitledShow>
  );
};

export const LockerProviderEdit = () => (
  <Edit columns={1}>
    <TextInput source="id" disabled />
    <TextInput source="name" label="Name" />
    <TextInput source="type" label="Type" />
    <JsonInput
      source="settings"
      jsonString={false} // Set to true if the value is a string, default: false
      reactJsonOptions={{
        name: null,
        collapsed: false,
        enableClipboard: false,
        displayDataTypes: false,
        theme: "google",
      }}
    />
  </Edit>
);

export const LockerProviderCreate = () => (
  <Create columns={1}>
    <TextInput source="name" label="Name" />
    <SelectInput source="type" choices={typeOptions} />
    <TextInput source="apiUrl" label="API url" />
    <TextInput source="sharedKey" label="Shared Key" />
    <ArrayInput source="lockersMap" label="Lockers Map">
      <SimpleFormIterator>
        <TextInput source="remoteType" label="Remote Type Name" />
        <NumberInput source="local_type_id" label="Local Type ID" />
      </SimpleFormIterator>
    </ArrayInput>
  </Create>
);
