import { TextField, TextInput } from "react-admin";
import { TitledShow } from "../ra-lb-tools/components/titledRecord";
import { useParams } from "react-router-dom";
import { TowerList } from "./tower/towers";
import BusinessIcon from "@mui/icons-material/Business";
import { FederationList } from "./federatios";
import {
  MyReferenceField,
  MyReferenceInput,
} from "../ra-lb-tools/components/myReference";
import { MyList } from "../ra-lb-tools/components/myList";
import {
  MyEdit as Edit,
  MyCreate as Create,
} from "../ra-lb-tools/components/myMutator";
import { LabeledColumn } from "../ra-lb-tools/components/labeledColumn";
import {
  MyTabbedShowLayout,
  Tab,
} from "../ra-lb-tools/components/myTabbedShowLayout";
import { LockerUsageList } from "./billing/lockerUsage";
import { Row } from "../ra-lb-tools/components/row";

export const SystemIcon = BusinessIcon;

export const SystemList = (props) => (
  <MyList {...props} resource="systems">
    <TextField source="id" />
    <TextField source="name" />
    <TextField source="description" />
    <MyReferenceField
      source="ownerId"
      reference="users"
      link="show"
      primaryField="email"
    />
  </MyList>
);

export const SystemEdit = (props) => {
  return (
    <Edit {...props}>
      <TextInput source="id" disabled />
      <TextInput source="name" />
      <TextInput source="description" multiline />
    </Edit>
  );
};

export const SystemCreate = (props) => {
  return (
    <Create {...props}>
      <TextInput source="name" />
      <TextInput source="description" multiline />
    </Create>
  );
};

export const SystemShow = () => {
  const id = parseInt(useParams()["id"]);

  return (
    <TitledShow>
      <MyTabbedShowLayout>
        <Tab label="Summary" can={true}>
          <Row className={"RecordSummary"}>
            <LabeledColumn>
              <TextField source="id" />
              <TextField source="name" />
              <TextField source="description" />
              <MyReferenceField
                source="ownerId"
                reference="users"
                link="show"
                primaryField="email"
              />
            </LabeledColumn>
          </Row>
        </Tab>
        <Tab
          label="Towers"
          path="towers"
          can={{ action: "list", resource: "towers" }}
        >
          <TowerList filter={{ system_id: id }} filters={null} />
        </Tab>
        <Tab
          label="Retired Towers"
          path="towers"
          can={{ action: "list", resource: "towers" }}
        >
          <TowerList filter={{ system_id: id, status_id: 4 }} filters={null} />
        </Tab>
        <Tab
          label="Locker Usage"
          path="locker-usage"
          can={{
            action: "list",
            resource: "locker-usage",
          }}
          key="locker-usage"
        >
          <LockerUsageList filter={{ system_id: id }} />
        </Tab>
        <Tab
          label="Federations"
          path="federations"
          can={{ action: "list", resource: "federations" }}
        >
          <FederationList filter={{ system_id: id }} />
        </Tab>
      </MyTabbedShowLayout>
    </TitledShow>
  );
};

export const SystemInput = (props) => {
  return <MyReferenceInput source="systemId" reference="systems" {...props} />;
};

export const SystemField = (props) => {
  return <MyReferenceField source="systemId" reference="systems" {...props} />;
};
