import { Link, TextField, useCreatePath } from "react-admin"; //ra-core
import { Debug } from "./debug";

export const MyLinkField = ({
  resource,
  record,
  source = "name",
  linkType: type = "show",
  ...rest
}) => {
  const createPath = useCreatePath();
  const id = record["id"];
  const path = createPath({ resource, id, type });
  return (
    <Link to={path} {...rest}>
      <TextField record={record} source={source} />
      {source != "id" && <Debug> (#{id})</Debug>}
    </Link>
  );
};
