import {
  LinearProgress,
  TextField,
  useGetList,
  useRecordContext,
} from "react-admin";

export const LastReferenceField = ({
  reference,
  source,
  target = "id",
  filter = {},
  noDataMsg = "",
  record,
  validateRecord,
  render,
}) => {
  record = record || useRecordContext();
  if (!record) return null;
  if (validateRecord && !validateRecord(record)) return null;

  const { data, isLoading, error } = useGetList(reference, {
    filter: { ...filter, [source]: record[target] },
    sort: { field: "id", order: "DESC" },
    pagination: { page: 1, perPage: 1 },
  });
  if (isLoading) {
    return <LinearProgress />;
  }
  if (error) {
    return <p>ERROR: {error.message}</p>;
  }
  if (!(data && data.length)) return <p>{noDataMsg}</p>;

  const last_reference = data[0];

  if (typeof render === "function") {
    return render(last_reference);
  } else {
    return <TextField record={last_reference} source={render} />;
  }
};
