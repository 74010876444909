import {
  TextField,
  DateField,
  SelectField,
  TextInput,
  WithRecord,
  Link,
} from "react-admin";
import { MyList } from "../ra-lb-tools/components/myList";
import { TowerField, TowerInput } from "./tower/towers";
import { JsonField } from "../ra-lb-tools/components/jsonField";
import { ManageHistory } from "@mui/icons-material";
import { ColoredOptionChipField } from "../components/coloredOptionChipField";

export const typeChoices = [
  { id: 1, name: "Insert", color: "success" },
  { id: 2, name: "Remove", color: "error" },
];
export const DeviceHistoryIcon = ManageHistory;

export const DeviceHistoryList = (props) => {
  const filters = [
    <TextInput source="serial_number" alwaysOn key="serialNumber" />,
    <TowerInput source="tower_id" alwaysOn key="towerId" />,
  ];

  return (
    <MyList
      resource="device-history"
      filters={filters}
      sort={{
        field: "id",
        order: "DESC",
      }}
      {...props}
    >
      <TextField source="id" />
      <WithRecord
        source="serialNumber"
        render={(record) => (
          <Link to={`/devices/${record.serialNumber}/show`}>
            <TextField source="serialNumber" />
          </Link>
        )}
      />
      <TowerField source="towerId" />
      <SelectField
        source="type"
        choices={typeChoices}
        optionText={<ColoredOptionChipField />}
      />
      <TextField source="towerhistoryId" label="Tower History Entry" />
      <DateField source="createdAt" showTime={true} />
      <JsonField source="data" />
    </MyList>
  );
};
