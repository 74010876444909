import { TextField, DateField, NumberInput } from "react-admin";

import BusinessIcon from "@mui/icons-material/Business";
import { MyList } from "../../ra-lb-tools/components/myList";
import {
  MyReferenceField,
  MyReferenceInput,
} from "../../ra-lb-tools/components/myReference";
import { UserInput } from "../users";
import { TitledShow } from "../../ra-lb-tools/components/titledRecord";
import {
  MyTabbedShowLayout,
  Tab,
} from "../../ra-lb-tools/components/myTabbedShowLayout";
import { LabeledColumn } from "../../ra-lb-tools/components/labeledColumn";
import { ReferenceInputString } from "../../ra-lb-tools/components/referenceInputString";
import { LockersStatusField } from "../lockers";
import { Row } from "../../ra-lb-tools/components/row";

export const SystemIcon = BusinessIcon;

export const LockerUsageEventList = (props) => {
  const filters = [
    <NumberInput source="locker_id" alwaysOn key={1} label="Locker Id" />,
    <UserInput source="userId" alwaysOn key={2} />,
    <ReferenceInputString
      source="type"
      reference="locker-usage-event-types"
      alwaysOn
      key={3}
    />,
    <ReferenceInputString
      source="source"
      reference="locker-usage-event-sources"
      alwaysOn
      key={4}
    />,
    <MyReferenceInput
      label="To Status"
      source="toStatus"
      reference="locker-statuses"
      alwaysOn
      key={5}
    />,
  ];

  const title = "Locker Usage Event";
  const resource = "locker-usage-events";

  return (
    <MyList
      title={title}
      resource={resource}
      sort={{
        field: "id",
        order: "DESC",
      }}
      filters={filters}
      {...props}
    >
      <TextField source="id" />
      <DateField source="timestamp" showTime />
      <MyReferenceField source="lockerId" reference="lockers" />
      <MyReferenceField
        source="userId"
        reference="users"
        primaryField="email"
      />
      <TextField source="type" />
      <TextField source="source" />
      <TextField source="keypadCode" />
      <TextField source="clientId" />
      <LockersStatusField
        label="To Status"
        statusIdField="toStatus"
        editable={false}
      />
      <MyReferenceField
        source="reservationId"
        reference="reservations"
        primaryField="id"
      />
      <MyReferenceField
        source="lockerUsageId"
        reference="locker-usage"
        primaryField="id"
      />
      <MyReferenceField
        source="lockerOpenRequestId"
        reference="locker-open-requests"
        primaryField="id"
      />
      <MyReferenceField
        source="openLockerTokenId"
        reference="open-locker-tokens"
        primaryField="id"
      />
      <DateField source="createdAt" showTime />
    </MyList>
  );
};

export const LockerUsageEventShow = () => {
  return (
    <TitledShow>
      <MyTabbedShowLayout>
        <Tab label="Summary" can={true}>
          <Row className={"RecordSummary"}>
            <LabeledColumn>
              <TextField source="id" />
              <DateField source="timestamp" showTime />
              <MyReferenceField source="lockerId" reference="lockers" />
              <MyReferenceField
                source="userId"
                reference="users"
                primaryField="email"
              />
              <TextField source="type" />
              <TextField source="source" />
              <TextField source="keypadCode" />
            </LabeledColumn>
            <LabeledColumn>
              <TextField source="clientId" />
              <LockersStatusField
                label="To Status"
                statusIdField="toStatus"
                editable={false}
              />
              <MyReferenceField
                source="reservationId"
                reference="reservations"
                primaryField="id"
              />
              <MyReferenceField
                source="lockerUsageId"
                reference="locker-usage"
                primaryField="id"
              />
              <MyReferenceField
                source="lockerOpenRequestId"
                reference="locker-open-requests"
                primaryField="id"
              />
              <MyReferenceField
                source="openLockerTokenId"
                reference="open-locker-tokens"
                primaryField="id"
              />
              <DateField source="createdAt" showTime />
            </LabeledColumn>
          </Row>
        </Tab>
      </MyTabbedShowLayout>
    </TitledShow>
  );
};
