import { Stack } from "@mui/material";

export const Row = ({ children, className, sx, ...rest }) => (
  <Stack
    spacing={4}
    direction="row"
    sx={{ justifyContent: "stretch", width: "100%", ...sx }}
    className={"Row " + className}
    {...rest}
  >
    {children}
  </Stack>
);

Row.defaultProps = {
  className: "",
};
