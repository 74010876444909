import {
  Button,
  Link,
  TextField,
  TextInput,
  TopToolbar,
  useRecordContext,
} from "react-admin";
import { TitledShow } from "../../ra-lb-tools/components/titledRecord";
import { useParams } from "react-router-dom";
import { JsonField } from "../../ra-lb-tools/components/jsonField";
import { TowerList } from "../tower/towers";
import { FederationList } from "../federatios";
import { LabeledColumn } from "../../ra-lb-tools/components/labeledColumn";
import { GoBackButton } from "../../ra-lb-tools/components/goBackButton";
import { MyEdit } from "../../ra-lb-tools/components/myMutator";
import { Edit } from "@mui/icons-material";
import { TagNameField } from "../firmware";
import { LocationImageList } from "../locationImage";
import { MyEditButton as EditButton } from "../../ra-lb-tools/components/myEditButton";
import {
  MyTabbedShowLayout,
  Tab,
} from "../../ra-lb-tools/components/myTabbedShowLayout";
import { MyReferenceField } from "../../ra-lb-tools/components/myReference";
import { LockerUsageList } from "../billing/lockerUsage";
import { Row } from "../../ra-lb-tools/components/row";

export const LocationFirmwareEdit = (props) => {
  return (
    <MyEdit
      {...props}
      formProps={{
        shouldUnregister: true,
      }}
      mutationOptions={{
        meta: { customSegment: "firmware", customMethod: "POST" },
      }}
    >
      <TextInput source="id" disabled />
      <TextInput source="name" disabled />
      <TextInput source="tagName" />
    </MyEdit>
  );
};

const EditFirmwareButton = () => {
  const record = useRecordContext();

  if (!record) return null;
  const path = `/locations/${record.id}/firmware`;

  return (
    <Button
      component={Link}
      to={path}
      label={"Firmware"}
      onClick={(e) => e.stopPropagation()}
    >
      <Edit />
    </Button>
  );
};

const LocationShowActions = () => (
  <TopToolbar>
    <EditButton />
    <EditFirmwareButton />
    <GoBackButton size="small" variant="text" />
  </TopToolbar>
);

export const LocationShow = () => {
  const id = parseInt(useParams()["id"]);

  return (
    <TitledShow actions={<LocationShowActions />}>
      <MyTabbedShowLayout>
        <Tab label="Summary" can={true}>
          <Row className={"RecordSummary"}>
            <LabeledColumn>
              <TextField source="id" />
              <TextField source="name" />
              <TextField source="address1" />
              <TextField source="address2" />
              <TextField source="description" />
              <TagNameField source="firmware.tagName" />
              <TextField source="firmware.version" />
            </LabeledColumn>
            <LabeledColumn>
              <TextField source="lat" />
              <TextField source="lon" />
              <TextField source="city" />
              <TextField source="state" />
              <TextField source="zip" />
              <TextField source="country" />
              <TextField source="timezone" />
            </LabeledColumn>
            <LabeledColumn>
              <MyReferenceField
                source="ownerId"
                reference="users"
                link="show"
                primaryField="email"
              />
              <TextField source="notes" />
              <JsonField source="accessHours" maxLines={null} />
            </LabeledColumn>
          </Row>
        </Tab>
        <Tab
          label="Towers"
          path="towers"
          can={{ action: "list", resource: "towers" }}
        >
          <TowerList filter={{ location_id: id }} filters={null} />
        </Tab>
        <Tab
          label="Retired Towers"
          path="towers-retired"
          can={{ action: "list", resource: "towers" }}
        >
          <TowerList
            filter={{ location_id: id, status_id: 4 }}
            filters={null}
          />
        </Tab>
        <Tab
          label="Federations"
          path="federations"
          can={{ action: "list", resource: "federations" }}
        >
          <FederationList filter={{ location_id: id }} />
        </Tab>
        <Tab
          label="Images"
          path="images"
          can={{ action: "list", resource: "location-image" }}
        >
          <LocationImageList filter={{ location_id: id }} />
        </Tab>
        <Tab
          label="Locker Usage"
          path="locker-usage"
          can={{
            action: "list",
            resource: "locker-usage",
          }}
          key="locker-usage"
        >
          <LockerUsageList filter={{ location_id: id }} />
        </Tab>
      </MyTabbedShowLayout>
    </TitledShow>
  );
};
