import { TextField, DateField, NumberField } from "react-admin";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { MyList } from "../ra-lb-tools/components/myList";
import { MyReferenceField } from "../ra-lb-tools/components/myReference";

export const KeyIcon = VpnKeyIcon;

export const KeyList = (props) => {
  return (
    <MyList resource="keys" {...props}>
      <TextField source="id" />
      <TextField source="name" />
      <NumberField source="slot" />
      <MyReferenceField source="towerId" reference="towers" link="show" />
      <TextField source="typeName" label="Type" sortable={false} />
      <TextField source="rotationName" label="Rotation" sortable={false} />
      <TextField source="statusName" label="Status" sortable={false} />
      <DateField source="validFrom" showTime={true} />
      <DateField source="validUntil" showTime={true} />
    </MyList>
  );
};
