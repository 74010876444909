import { Box, FormControlLabel, Switch, TextField } from "@mui/material";
import { Button } from "react-admin";
import MultiInputField from "./multiInputField";

const LabeledSwitch = (props) => {
  const control = <Switch checked={props.value} onChange={props.onChange} />;

  return <FormControlLabel control={control} label={props.label} />;
};

const NumberField = (props) => <TextField type="number" {...props} />;

const fieldInputs = {
  default: {
    input: TextField,
    getter: (event) => event.target.value,
    parser: (v) => v,
  },
  boolean: {
    input: LabeledSwitch,
    getter: (event) => event.target.checked,
  },
  number: {
    input: NumberField,
    parser: parseInt,
  },
  function: {
    input: Button,
  },
  array: {
    input: MultiInputField,
    getter: (values) => values,
  },
};

function getType(value) {
  if (Array.isArray(value)) return "array";
  return typeof value;
}

export const FieldInput = ({ value, label, name, setter, sx, ...rest }) => {
  const type = getType(value);
  const typeSetup = Object.assign(
    {},
    fieldInputs.default,
    fieldInputs[type] || {}
  );
  const Input = typeSetup.input;

  const handler = (event) => {
    const value = typeSetup.parser(typeSetup.getter(event));
    setter(value);
  };

  return (
    <Box key={name} sx={sx} className={"FieldInput"}>
      <Input
        label={label}
        onChange={handler}
        value={type === "function" ? undefined : value}
        onClick={type === "function" ? value : undefined}
        {...rest}
      />
    </Box>
  );
};

export const getUnsourcedInputs = (setups) => {
  return Object.values(setups).map((setup) => FieldInput(setup));
};
