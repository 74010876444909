import { TextField, NumberField, ShowBase, useStore } from "react-admin";
import { useParams } from "react-router-dom";
import { SpecTable } from "./LockerSpec";
import { TitledShow } from "../../ra-lb-tools/components/titledRecord";
import { TowerList } from "../tower/towers";
import { Row } from "../../ra-lb-tools/components/row";
import { Column } from "../../ra-lb-tools/components/column";
import { useState } from "react";
import { TowerRenderColumn } from "../tower_render/TowerRenderColumn";
import { LabeledColumn } from "../../ra-lb-tools/components/labeledColumn";
import {
  MyTabbedShowLayout,
  Tab,
} from "../../ra-lb-tools/components/myTabbedShowLayout";

export const TowerModelShow = () => {
  const id = parseInt(useParams()["id"]);

  const [highlightList, setHighlightList] = useState([]);
  const [renderAlwaysVisible, setRenderAlwaysVisible] = useStore(
    "tower-render-always-visible",
    false
  );
  const [rowRef, setRowRef] = useState({});

  const addRowRef = (record, ref) => {
    const key = record.physicalId;

    setRowRef((rowRef) => {
      rowRef[key] = ref;
      return rowRef;
    });
  };

  const scrollToRow = (record) => {
    const key = record.physicalId;

    rowRef[key]?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const dataColumnSx = renderAlwaysVisible
    ? { width: "70%" }
    : { width: "100%" };
  const fullHeighClass = renderAlwaysVisible ? "h-100-overflow" : "";

  const TowerRender = (
    <TowerRenderColumn
      {...{
        highlightList,
        setHighlightList,
        renderAlwaysVisible,
        setRenderAlwaysVisible,
        onClick: scrollToRow,
        columnProps: {
          className: fullHeighClass,
        },
      }}
    />
  );

  return (
    <Row className={fullHeighClass}>
      <TitledShow className={fullHeighClass} style={dataColumnSx}>
        <MyTabbedShowLayout>
          <Tab label="Summary" can={true}>
            <Row className={"RecordSummary"}>
              <Column>
                <Row>
                  <LabeledColumn>
                    <NumberField source="id" />
                    <TextField source="name" />
                    <TextField source="nameStrategy" />
                    <TextField source="description" />
                    <NumberField source="numColumns" />
                  </LabeledColumn>
                  <LabeledColumn>
                    <TextField source="lockerCount" />
                    <TextField source="lowLockerCount" />
                    <TextField source="towerCount" />
                  </LabeledColumn>
                  {!renderAlwaysVisible && TowerRender}
                </Row>
                <Row>
                  <SpecTable
                    source="lockersSpec"
                    highlightList={highlightList}
                    onMouseEnter={(locker) =>
                      setHighlightList([locker.physicalId])
                    }
                    onMouseLeave={() => setHighlightList([])}
                    setRowRef={addRowRef}
                  />
                </Row>
              </Column>
            </Row>
          </Tab>
          <Tab
            label="Towers"
            path="towers"
            can={{ action: "list", resource: "towers" }}
          >
            <TowerList filter={{ tower_model_id: id }} />
          </Tab>
          <Tab
            label="Retired Towers"
            path="towers-retired"
            can={{ action: "list", resource: "towers" }}
          >
            <TowerList
              filter={{ tower_model_id: id, status_id: 4 }}
              filters={null}
            />
          </Tab>
        </MyTabbedShowLayout>
      </TitledShow>
      {renderAlwaysVisible && <ShowBase>{TowerRender}</ShowBase>}
    </Row>
  );
};
