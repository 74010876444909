import { Stack } from "@mui/material";
import { LabeledAll } from "./labeledAll";

export const LabeledColumn = ({ children, sx }) => {
  return (
    <Stack direction="column" sx={sx}>
      <LabeledAll>{children}</LabeledAll>
    </Stack>
  );
};
