import {
  TextField,
  DateField,
  NumberInput,
  DateTimeInput,
  SelectField,
  SelectInput,
  ReferenceInput,
  TextInput,
  useRecordContext,
  Link,
} from "react-admin";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";
import { MyList } from "../ra-lb-tools/components/myList";
import AutocompleteInputFluid from "../ra-lb-tools/components/autocompleteInputFluid";
import { MyReferenceField } from "../ra-lb-tools/components/myReference";
import { useParams } from "react-router-dom";
import { TitledShow } from "../ra-lb-tools/components/titledRecord";
import {
  MyTabbedShowLayout,
  Tab,
} from "../ra-lb-tools/components/myTabbedShowLayout";
import { LabeledColumn } from "../ra-lb-tools/components/labeledColumn";
import { LockerUsageEventList } from "./billing/lockerUsageEvent";
import { Row } from "../ra-lb-tools/components/row";
import { Settings } from "../ra-lb-tools/utils/settings";
import { expandTokenizedTemplate } from "../ra-lb-tools/utils/tools";

export const openLockerTokenIcon = EnhancedEncryptionIcon;

const intentChoices = [
  { id: 1, name: "Dropoff" },
  { id: 2, name: "Pickup" },
  { id: 3, name: "Release" },
  { id: 4, name: "Emergency" },
];

const openLockerTokenFilters = [
  <NumberInput label="Locker Id" source="locker_id" alwaysOn key={1} />,
  <SelectInput
    label="Intent"
    source="intent"
    alwaysOn
    key={2}
    choices={intentChoices}
  />,
  <DateTimeInput label="Expires after" source="expires_at_gt" key={3} />,
  <TextInput
    label="Tower id"
    source="locker__tower_id"
    alwaysOn
    key="locker__tower_id"
  />,
  <ReferenceInput
    source="open-locker-tokens##user_id"
    label="User"
    reference="users"
    allowEmpty
    key="open-locker-tokens##user_id"
  >
    <AutocompleteInputFluid
      label="User"
      filterToQuery={(searchText) => ({ email: `%${searchText}` })}
      optionText="email"
      optionValue="id"
    ></AutocompleteInputFluid>
  </ReferenceInput>,
  <TextInput source="client_info" alwaysOn key="client_info" />,
];

export const OpenLockerTokenClientInfo = ({ record }) => {
  const clientInfoTemplates = (
    Settings.getValue("clientInfoTemplate") || []
  ).filter((v) => v);
  record = record || useRecordContext();

  for (let index = 0; index < clientInfoTemplates.length; index++) {
    const clientInfoTemplate = clientInfoTemplates[index];
    const parsed = expandTokenizedTemplate(
      clientInfoTemplate,
      record.clientInfo
    );
    if (parsed) {
      return (
        <Link to={parsed} target="_blank">
          <TextField record={record} source="clientInfo" />
        </Link>
      );
    }
  }

  return <TextField record={record} source="clientInfo" />;
};

export const openLockerTokenList = (props) => {
  const resource = "open-locker-tokens";

  return (
    <MyList {...props} filters={openLockerTokenFilters} resource={resource}>
      <TextField source="id" />
      <MyReferenceField
        source="lockerId"
        reference="lockers"
        link="show"
        label="Locker"
      />
      <MyReferenceField
        source="userId"
        reference="users"
        sortable={false}
        link="show"
        primaryField="email"
      />
      <SelectField choices={intentChoices} source="intent" sortable={false} />
      <DateField source="expiresAt" showTime />
      <OpenLockerTokenClientInfo source="clientInfo" sortable={false} />
      <TextField source="currentToken" sortable={false} />
      <TextField source="nextToken" sortable={false} />
    </MyList>
  );
};

export const OpenLockerTokenShow = () => {
  const id = parseInt(useParams()["id"]);

  return (
    <TitledShow>
      <MyTabbedShowLayout>
        <Tab label="Summary" can={true}>
          <Row className={"RecordSummary"}>
            <LabeledColumn>
              <TextField source="id" />
              <MyReferenceField
                source="lockerId"
                reference="lockers"
                link="show"
                label="Locker"
              />
              <MyReferenceField
                source="userId"
                reference="users"
                sortable={false}
                link="show"
                primaryField="email"
              />
              <SelectField
                choices={intentChoices}
                source="intent"
                sortable={false}
              />
            </LabeledColumn>
            <LabeledColumn>
              <DateField source="expiresAt" showTime />
              <OpenLockerTokenClientInfo source="clientInfo" sortable={false} />
              <TextField source="currentToken" sortable={false} />
              <TextField source="nextToken" sortable={false} />
            </LabeledColumn>
          </Row>
        </Tab>
        <Tab
          label="Locker Usage Events"
          path="locker-usage-events"
          can={{ action: "list", resource: "locker-usage-events" }}
        >
          <LockerUsageEventList filter={{ openLockerTokenId: id }} />
        </Tab>
      </MyTabbedShowLayout>
    </TitledShow>
  );
};
