import { debug_log } from "./debugLog";
import { asArray, getValue } from "./tools";
import inflection from "inflection";

const actionToCommand = {
  list: "query",
  show: "read",
  create: "create",
  edit: "update",
  delete: "delete",
  "bulk-create": "create_bulk",
};

const commandToAction = (command) =>
  Object.keys(actionToCommand).find(
    (action) => actionToCommand[action] === command
  );

const formatResource = (resource) =>
  inflection.singularize(resource).replaceAll("-", "_");

const checkNoEmpty = (val, label, reqLabel) => {
  if (!val) {
    val = "";
    console.warn(`AccessControlProvider :: empty '${label}' on '${reqLabel}'`);
  }

  return val;
};

const domain = "backend";

const accessControlProvider = (permissions) => ({
  permissions,

  _can: function (req, reqLabel) {
    if (req === true) {
      return true;
    }

    req = req || {};
    let { resource, action } = req;
    resource = checkNoEmpty(resource, "resource", reqLabel);
    action = checkNoEmpty(action, "action", reqLabel);

    resource = formatResource(resource);
    const command = actionToCommand[action] || action;

    const res = !!permissions.find(
      (p) =>
        ["*", domain].includes(p.domain) &&
        ["*", resource].includes(p.model) &&
        ["*", command].includes(p.command)
    );

    !res &&
      debug_log(
        `AccessControlProvider :: ${reqLabel} / {${resource}, ${action}} ==> ${res}`,
        4
      );

    return res;
  },

  can: function (reqList, reqLabel) {
    reqList = asArray(reqList);

    return (
      reqList.filter((req) => this._can(req, reqLabel)).length ===
      reqList.length
    );
  },

  allowedActions: function ({ resource }) {
    resource = checkNoEmpty(resource, "resource");

    resource = formatResource(resource);
    let allowed = [];

    const perms = permissions.filter(
      (p) =>
        ["*", domain].includes(p.domain) && ["*", resource].includes(p.model)
    );

    perms.forEach((perm) => {
      const command = perm.command;

      if (command == "*") {
        allowed = allowed.concat(Object.keys(actionToCommand));
      } else {
        const action = commandToAction(command) || `${command}**`;
        allowed.push(action);
      }
    });

    return allowed;
  },

  isAllowedPath: function ({ path }) {
    path = checkNoEmpty(path, "path");

    path = path.split("/").filter((v) => v);
    path = {
      resource: path[0],
      action: path[1],
    };

    return this._can(path);
  },
});

let AccessControlProviderInstance = null;

export const resetPermissions = () => {
  AccessControlProviderInstance = null;
  debug_log("AccessControlProvider :: reset");
};

export const getPermissionsData = (dataProvider) => {
  return dataProvider.customCommand(
    `permissions/?domain_filter=${domain}`,
    () => null,
    "GET"
  );
};

export const FactoryAccessControlProvider = (dataProvider) => {
  if (AccessControlProviderInstance) {
    return Promise.resolve(AccessControlProviderInstance);
  }

  debug_log("AccessControlProvider :: create");

  return getPermissionsData(dataProvider).then((res) => {
    const permissions = getValue(res, "permissions", []);
    AccessControlProviderInstance = accessControlProvider(permissions);

    return AccessControlProviderInstance;
  });
};
