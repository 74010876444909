import {
  Create,
  CreateBase,
  Edit,
  EditBase,
  FormDataConsumer,
  SaveButton,
  SimpleForm,
  Toolbar,
  TopToolbar,
  useNotify,
  useResourceDefinition,
} from "react-admin";
import { errorHandlingFactory } from "../utils/errors";
import { Settings } from "../utils/settings";
import { Debug } from "./debug";
import { Box, Card, CardContent } from "@mui/material";
import { JsonStyled } from "./jsonStyled";
import { useLocation } from "react-router-dom";
import { Row } from "./row";
import { splitArray } from "../utils/tools";
import { Column } from "./column";
import { GoBackButton } from "./goBackButton";
import { RecordTitle } from "./recordTitle";
import { MyShowButton as ShowButton } from "./myShowButton";
import { WithPermissions } from "./withPermissions";
import { GrafanaButton } from "./grafanaButton";
import { DebugDotJson } from "./debugDotJson";

const MutatorFormData = () => {
  return (
    <FormDataConsumer>
      {({ formData }) => (
        <DebugDotJson
          data={formData}
          jsonProps={{ wrapperStyle: { maxHeight: "60vh" } }}
        />
      )}
    </FormDataConsumer>
  );
};

const MutatorToolbar = ({ sx, saveEnabled, ...props }) => {
  return (
    <Toolbar sx={{ justifyContent: "space-between", ...sx }} {...props}>
      <SaveButton disabled={!saveEnabled} />
      <GoBackButton size="medium" variant="contained" />
    </Toolbar>
  );
};

const MutatorEditActions = () => (
  <TopToolbar>
    <ShowButton />
    <Debug>
      <GrafanaButton
        expr={` |= \`PUT /backend/v1/${useResourceDefinition().name}\` `}
      />
    </Debug>
  </TopToolbar>
);

const MutatorCreateActions = () => (
  <TopToolbar>
    <Debug>
      <GrafanaButton
        expr={` |= \`POST /backend/v1/${useResourceDefinition().name}\` `}
      />
    </Debug>
  </TopToolbar>
);

export const MyEdit = (props) => <MyMutator {...props} mutatorType="edit" />;
export const MyCreate = (props) => (
  <MyMutator {...props} mutatorType="create" />
);

const MyMutator = ({
  saveEnabled,
  labelField,
  formProps,
  children,
  columns,
  actions,
  ...props
}) => {
  actions =
    actions ||
    (props.mutatorType == "edit" ? (
      <MutatorEditActions />
    ) : (
      <MutatorCreateActions />
    ));
  const Mutator = props.mutatorType == "edit" ? Edit : Create;
  const MutatorBase = props.mutatorType == "edit" ? EditBase : CreateBase;

  const notify = useNotify();
  const onError = errorHandlingFactory(notify);

  const processedProps = Object.assign(
    {
      mutationMode: Settings.getValue("pessimisticSave")
        ? "pessimistic"
        : "optimistic",
    },
    props
  );

  formProps = {
    warnWhenUnsavedChanges: true,
    toolbar: <MutatorToolbar saveEnabled={saveEnabled} />,
    ...formProps,
  };

  const mutationOptions = { onError, ...props.mutationOptions };
  let { className, sx, style, ...mutatorProps } = processedProps;

  mutatorProps = { ...mutatorProps };

  if (children && !Array.isArray(children)) {
    children = [children];
  }

  const column_groups = splitArray(children, columns);
  children = (
    <Row>
      {column_groups.map((column, columnIdx) => (
        <Column key={columnIdx}>{column.map((field) => field)}</Column>
      ))}
      <MutatorFormData />
    </Row>
  );

  return (
    <WithPermissions action={props.mutatorType || "create"}>
      <Box
        className={"MutatorWrapper " + (className || "")}
        sx={sx}
        style={style}
      >
        <MutatorBase {...mutatorProps}>
          <RecordTitle labelField={labelField} actions={actions} />
        </MutatorBase>
        <Mutator
          {...mutatorProps}
          actions={null}
          mutationOptions={mutationOptions}
        >
          <SimpleForm {...formProps}>{children}</SimpleForm>
        </Mutator>
        <Debug foldable={true}>
          <Card>
            <CardContent>
              <h3>Processed Props</h3>
              <JsonStyled data={mutatorProps} />
              <h3>Location Info</h3>
              <JsonStyled data={useLocation()} />
            </CardContent>
          </Card>
        </Debug>
      </Box>
    </WithPermissions>
  );
};

MyMutator.defaultProps = {
  redirect: -1,
  mutatorType: "edit",
  columns: 2,
  saveEnabled: true,
};
