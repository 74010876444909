import { useStore } from "react-admin";
import { Store } from "./store";
import { getConfig } from "../config/packageSetup";
import { TextField } from "@mui/material";

const packageConfig = getConfig();

const app_settings_container = "app_settings";
const app_settings = {
  showOnClick: {
    label: "Lists: Show On Click",
    defaultValue: packageConfig.showOnClick,
  },
  verticalTabs: {
    label: "Vertical tabs",
    defaultValue: packageConfig.verticalTabs,
  },
  pessimisticSave: {
    label: "Save: pessimistic mode",
    defaultValue: packageConfig.pessimisticSave,
  },
  "json.max_lines": {
    label: "JSON Fields max lines",
    defaultValue: packageConfig["json.max_lines"],
  },
  customAuthToken: {
    label: "Custom Auth Token",
    defaultValue: packageConfig.customAuthToken,
  },
  "ENV.API_URL": {
    label: "Custom API URL",
    defaultValue: packageConfig["ENV.API_URL"],
  },
  clientInfoTemplate: {
    label: "Client info template",
    defaultValue: packageConfig.clientInfoTemplate,
    fields: [
      {
        Component: TextField,
        name: "regex",
        label: "Client info regex",
        style: { width: "30%" },
      },
      {
        Component: TextField,
        name: "template",
        label: "Client info template",
      },
    ],
  },
  resetStorage: {
    label: "Restore local settings",
    defaultValue: packageConfig.resetStorage,
  },
};

const debug_settings_container = "debug_settings";
const debug_settings = {
  log_level: {
    label: "Log Level",
    defaultValue: 0,
  },
  show_restricted_elements: {
    label: "Show Restricted Elements",
    defaultValue: false,
  },
};

const prepareSettings = (_settings, container) => {
  const settings = {};

  for (const _setting in _settings) {
    const setup = _settings[_setting];
    const { defaultValue, label, ...rest } = setup;
    const storageKey = container + "." + _setting;
    const [value, setter] = useStore(storageKey, defaultValue);

    settings[_setting] = {
      name: _setting,
      label,
      value,
      setter,
      ...rest,
    };
  }

  return settings;
};

export const Settings = {
  debugging: () => Store.get("debug"),
  debug_level: () => Store.get(`RaStore.${debug_settings_container}.log_level`),
  getDebugSetting: (name) =>
    Store.get(`RaStore.${debug_settings_container}.${name}`),
  get: (_setting) => {
    const settings = prepareSettings(app_settings, app_settings_container);

    if (_setting) {
      return settings[_setting];
    }

    return settings;
  },
  getValue: (_setting) => Settings.get(_setting).value,

  getDebugSettings: () => {
    return prepareSettings(debug_settings, debug_settings_container);
  },
};
