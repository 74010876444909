import { Box, TextField } from "@mui/material";
import { TextInput, TopToolbar, useNotify } from "react-admin";
import { MyCreate } from "./myMutator";
import { CopyToClipboardButton } from "./copyToClipboardButton";
import { Row } from "./row";
import { useState } from "react";
import { getErrorMessage } from "../utils/errors";
import inflection from "inflection";
import { GrafanaButton } from "./grafanaButton";

const ServerLogsButton = ({ resource }) => {
  const grafanaExpr = ` | json | event =~ \`${inflection.capitalize(
    inflection.singularize(resource)
  )} bulk.*\``;

  return <GrafanaButton expr={grafanaExpr} />;
};

export const MyBulkCreate = ({ schema, label, resource, requiredFields }) => {
  const [result, setResult] = useState("");
  const [saveEnabled, setSaveEnabled] = useState(false);
  const notify = useNotify();

  const jsonSchema = JSON.stringify(schema, null, 4);
  const requiredJsonSchema = requiredFields
    ? JSON.stringify(
        [
          Object.fromEntries(
            Object.entries(schema[0]).slice(0, requiredFields)
          ),
        ],
        null,
        4
      )
    : null;

  return (
    <MyCreate
      columns={1}
      resource={resource}
      mutationOptions={{
        meta: {
          customSegment: "bulk",
          transform: (v) => JSON.parse(v.data_in),
        },
        onSuccess: (data) => {
          setSaveEnabled(false);

          const response = data._response;
          const ids = response.map((r) => r.id);

          const result =
            `Status: success!\n` +
            `Record count: ${response.length}\n` +
            `Record ids:\n` +
            ids.join(", ");
          setResult(result);
          notify(`${response.length} records created`, {
            type: "success",
          });
        },
        onError: (error) => {
          const error_msg = getErrorMessage(error);
          const errors = error_msg.split(". ");

          const result = `Status: fail :(\n` + `Errors:\n` + errors.join(".\n");
          setResult(result);
          notify(`Bulk creation failed`, {
            type: "error",
          });
        },
      }}
      saveEnabled={saveEnabled}
      actions={
        <TopToolbar>
          <ServerLogsButton resource={resource} />
        </TopToolbar>
      }
    >
      <Row sx={{ justifyContent: "flex-start" }}>
        <Box>
          Example Value <CopyToClipboardButton data={jsonSchema} />
          <pre style={{ fontSize: ".8em" }}>{jsonSchema}</pre>
        </Box>
        {requiredJsonSchema && (
          <Box>
            Required Value Example{" "}
            <CopyToClipboardButton data={requiredJsonSchema} />
            <pre style={{ fontSize: ".8em" }}>{requiredJsonSchema}</pre>
          </Box>
        )}
      </Row>
      <TextInput
        multiline
        source="data_in"
        label={label}
        onChange={() => setSaveEnabled(true)}
      />
      <Box>Results:</Box>
      <TextField
        multiline
        value={result}
        sx={{ width: "100%" }}
        style={{ padding: "0 !important" }}
      />
    </MyCreate>
  );
};
