import {
  FunctionField,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SingleFieldList,
  useRecordContext,
} from "react-admin";
import AutocompleteInputFluid from "./autocompleteInputFluid";
import { WithPermissions } from "./withPermissions";
import { Box } from "@mui/material";
import { Debug } from "./debug";

const Label = ({ value, field }) => {
  const prefix = field === "id" ? "#" : "";
  return prefix + value;
};

const PrimaryLabel = ({ record, primaryField }) => {
  const label = record[primaryField] || "unknown";

  return (
    <Box className="MyReferencePrimaryLabel">
      <Label value={label} field={primaryField} />
    </Box>
  );
};

const SecondaryLabel = ({ record, secondaryField }) => {
  if (!secondaryField) return null;

  const label = record[secondaryField];

  return (
    <Box className="MyReferenceSecondaryLabel">
      {" "}
      (<Label value={label} field={secondaryField} />)
    </Box>
  );
};

const DefaultLabel = (record, { primaryField, secondaryField, render }) => {
  const debugInfo = [primaryField, secondaryField].includes("id") ? null : (
    <Debug> (#{record.id})</Debug>
  );

  const label = (
    <Box className="MyReferenceLabel" sx={{ " *": { display: "inline" } }}>
      <PrimaryLabel record={record} primaryField={primaryField} />
      <SecondaryLabel record={record} secondaryField={secondaryField} />
      {debugInfo}
    </Box>
  );

  return render ? render(record, label) : label;
};

const defaults = {
  primaryField: "name",
};

export const MyReferenceInput = (props) => {
  const filter = (searchText) => ({ [props.primaryField]: `%${searchText}` });
  const Label = (choice) => DefaultLabel(choice, props);
  const inputText = (choice) => {
    let text = choice[props.primaryField];

    if (props.secondaryField) {
      text += " (" + choice[props.secondaryField] + ")";
    }

    return text;
  };

  return (
    <ReferenceInput {...props}>
      <AutocompleteInputFluid
        filterToQuery={filter}
        optionText={Label}
        optionValue="id"
        label={props.label}
        slack={props.slack}
        disabled={props.disabled}
        inputText={inputText}
      />
    </ReferenceInput>
  );
};

MyReferenceInput.defaultProps = defaults;

export const MyReferenceField = ({ ...props }) => {
  const record = props.record || useRecordContext();
  if (!record) return null;

  const fail = <Box>#{record[props.source]}</Box>;

  return (
    <WithPermissions
      label={`MyReferenceField ${props.reference}`}
      can={{ resource: props.reference, action: "list" }}
      fail={fail}
    >
      <ReferenceField {...props}>
        <FunctionField render={(record) => DefaultLabel(record, props)} />
      </ReferenceField>
    </WithPermissions>
  );
};

MyReferenceField.defaultProps = { ...defaults, link: "show" };

export const MyReferencesField = ({
  render,
  primaryField,
  secondaryField,
  link,
  ...props
}) => {
  return (
    <ReferenceManyField {...props}>
      <SingleFieldList linkType={link}>
        <FunctionField
          render={(record) =>
            DefaultLabel(record, { primaryField, secondaryField, render })
          }
        />
      </SingleFieldList>
    </ReferenceManyField>
  );
};

MyReferencesField.defaultProps = { ...defaults, link: "show", target: "id" };
