import React from "react";
import {
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Box,
} from "@mui/material";

function HomeItem(props) {
  return (
    <Grid
      className="HomeItem"
      item
      xs={12}
      sm={6}
      md={6}
      lg={4}
      xl={3}
      justifyContent={"center"}
      alignContent="center"
      textAlign={"center"}
    >
      <Card
        sx={{
          padding: "3rem",
          justifyContent: "center",
          height: "20rem",
          maxWidth: "20rem",
          alignItems: "center",
        }}
      >
        <a
          href={props.link}
          target={props.external ? "_blank" : undefined}
          rel="noreferrer"
          style={{ textDecoration: "none", color: "inherit", display: "block" }}
        >
          <CardMedia
            component="img"
            image={"/admin" + props.imageSrc}
            alt={props.text}
            sx={{
              height: "auto",
              width: "auto",
              margin: "auto",
              maxHeight: "150px",
              maxWidth: "150px",
              display: "flex",
            }}
          />

          <CardContent>
            <Typography variant="h5" style={{ fontSize: "1.1rem" }}>
              {props.text}
            </Typography>
          </CardContent>
        </a>
      </Card>
    </Grid>
  );
}

function Dashboard() {
  const { t } = { t: (v) => v };

  return (
    <Box sx={{ padding: "48px" }} className="Dashboard">
      <Grid
        justifyContent="space-between"
        sx={{ borderBottom: "1px solid #E0E0E0" }}
      >
        <Grid item>
          <Typography variant="h1" className="main-header" gutterBottom>
            Harbor Console
          </Typography>
          <Typography
            variant="h3"
            className="secondary-header"
            sx={{ paddingBottom: "15px" }}
          >
            {t("On-demand locker location access with limitless possibilities")}
          </Typography>
        </Grid>
      </Grid>

      <br />
      <Grid container spacing={6} alignItems="center" justifyItems={"center"}>
        <HomeItem
          imageSrc="/static/img/illustrations/documentation.png"
          text="Documentation"
          link="https://docs.harborlockers.com/"
          external={true}
        />
        <HomeItem
          imageSrc="/static/img/illustrations/schedule.png"
          text="Join Weekly Dev Hour - Thursdays at 1PM PT"
          link="https://meet.google.com/qfm-ynow-bpa"
          external={true}
        />
        <HomeItem
          imageSrc="/static/img/illustrations/schedule.png"
          text="Schedule a call"
          link="https://harborlockers.com/meet/discovery"
          external={true}
        />
        <HomeItem
          imageSrc="/static/img/illustrations/development_kit.png"
          text="Development Kit"
          link="https://store.harborlockers.com/products/harbor-developer-kit"
          external={true}
        />
        <HomeItem
          imageSrc="/static/img/illustrations/reference_app.png"
          text="Reference App"
          link="https://github.com/Harbor-Lockers/harbor-react-native-example"
          external={true}
        />
        <HomeItem
          imageSrc="/static/img/illustrations/github.png"
          text="GitHub Resources"
          link="https://github.com/orgs/Harbor-Lockers/repositories"
          external={true}
        />
        <HomeItem
          imageSrc="/static/img/illustrations/slack.png"
          text="Slack Channel"
          link="http://harborlockers.slack.com"
          external={true}
        />
      </Grid>
    </Box>
  );
}

export default Dashboard;
