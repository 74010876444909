import { NoAccess } from "../components/noAccess";
import { asArray, getValue } from "./tools";
import get from "lodash/get";

export const accessCheck = (acp, list) => {
  return asArray(list)
    .map((el) => {
      const label =
        get(el, "props.label") ||
        get(el, "props.className", "").split(" ")[0] ||
        "unknown";
      const resource = getValue(el, "props.resource");
      const action = getValue(el, "props.action");
      let can = getValue(el, "props.can");

      can = can || { resource, action };
      const allowed = acp.can(can, label);

      return allowed ? el : <NoAccess label={label} />;
    })
    .filter((v) => v);
};
