import { TextField, Datagrid, useDataProvider, Loading } from "react-admin";
import { VpnKey } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { getPermissionsData } from "../ra-lb-tools/utils/access";
import { Box } from "@mui/material";

export const PermissionIcon = VpnKey;

export const PermissionList = () => {
  const dataProvider = useDataProvider();
  const [data, setData] = useState();

  useEffect(() => {
    getPermissionsData(dataProvider).then((data) => {
      setData(data);
    });
  }, []);

  if (!data) {
    return <Loading />;
  }

  const permissions = data.permissions
    .map((perm, idx) => ({
      id: idx,
      ...perm,
    }))
    .sort((a, b) => a.action.localeCompare(b.action));

  const preSx = {
    margin: "8px 24px",
    padding: "5px",
    backgroundColor: "white",
    whiteSpace: "pre-wrap",
  };

  return (
    <Box>
      <h2>Enforcer Version: {data.enforcerVersion}</h2>
      <h3>Permissions</h3>
      <Datagrid
        resource="permissions"
        data={permissions}
        total={permissions.length}
        bulkActionButtons={false}
        sort={{}}
      >
        <TextField source="type" />
        <TextField source="subj" />
        <TextField source="resource" />
        <TextField source="action" />
        <TextField source="domain" />
        <TextField source="model" />
        <TextField source="command" />
      </Datagrid>
      <h3>PERM Model</h3>
      <pre style={preSx}>{data.model}</pre>
      <h3>Policies</h3>
      <pre style={preSx}>{data.adapter}</pre>
    </Box>
  );
};
