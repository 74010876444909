import {
  TextField,
  TextInput,
  BooleanInput,
  ReferenceInput,
  PasswordInput,
  ReferenceManyField,
  Link,
  useListContext,
  LinearProgress,
  CreateButton,
  useRecordContext,
} from "react-admin";
import { Box, Chip } from "@mui/material";

import RegenerateSecretButton from "../components/buttonRenerateSecret";
import CopyTextField from "../components/copyTextField";
import CopySecretField from "../components/copySecretField";
import ComputerIcon from "@mui/icons-material/Computer";
import AutocompleteInputFluid from "../ra-lb-tools/components/autocompleteInputFluid";
import { MyList } from "../ra-lb-tools/components/myList";
import {
  MyEdit as Edit,
  MyCreate as Create,
} from "../ra-lb-tools/components/myMutator";
import { MyBooleanField as BooleanField } from "../components/myBooleanField";
import { TitledShow } from "../ra-lb-tools/components/titledRecord";
import { LabeledColumn } from "../ra-lb-tools/components/labeledColumn";
import {
  MyTabbedShowLayout,
  Tab,
} from "../ra-lb-tools/components/myTabbedShowLayout";
import { Row } from "../ra-lb-tools/components/row";

export const ClientIcon = ComputerIcon;

const clientsFilters = [<TextInput source="name" alwaysOn key={1} />];

const ClientWebhooksRender = () => {
  const record = useRecordContext();
  const { error, isLoading, data } = useListContext();
  if (isLoading) {
    return <LinearProgress />;
  }
  if (error) {
    return <Box>Error: {error.message}</Box>;
  }

  if (!data.length) {
    return (
      <CreateButton
        resource="webhooks"
        state={{ record: { sp_client_id: record.clientId } }}
      />
    );
  } else {
    return (
      <Row sx={{ flexWrap: "wrap" }}>
        {data.map((hook) => (
          <Link key={hook.id} to={`/webhooks/${hook.name}/show`}>
            <Chip label={hook.url} />
          </Link>
        ))}
      </Row>
    );
  }
};

const ClientWebhooks = (props) => {
  return (
    <ReferenceManyField
      className="ClientWebhooks"
      reference="webhooks"
      target="name"
      source="clientId"
      sortable={false}
      {...props}
    >
      <ClientWebhooksRender />
    </ReferenceManyField>
  );
};

export const clientsList = (props) => (
  <MyList {...props} filters={clientsFilters} resource="clients">
    <TextField source="id" />
    <TextField source="name" />
    <TextField source="clientId" />
    <BooleanField source="enabled" />
    <ClientWebhooks label="Webhooks" />
  </MyList>
);

export const clientsView = (props) => (
  <TitledShow {...props}>
    <MyTabbedShowLayout>
      <Tab label="Summary" can={true}>
        <Row className={"RecordSummary"}>
          <LabeledColumn>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="description" />
            <BooleanField source="enabled" />
          </LabeledColumn>
          <LabeledColumn>
            <CopyTextField source="clientId" />
            <CopySecretField source="secret" label="secret" />
            <ClientWebhooks label="Webhooks" />
          </LabeledColumn>
        </Row>
      </Tab>
    </MyTabbedShowLayout>
  </TitledShow>
);

export const clientsEdit = () => {
  return (
    <Edit>
      <TextInput disabled source="id" />
      <TextInput source="name" />
      <TextInput multiline source="description" />
      <BooleanInput source="enabled" />
      <TextInput disabled source="clientId" />
      <PasswordInput disabled source="secret" />
      <RegenerateSecretButton />
    </Edit>
  );
};

export const clientsCreate = () => {
  return (
    <Create redirect="show">
      <TextInput source="clientId" />
      <TextInput source="name" />
      <TextInput multiline source="description" />
      <ReferenceInput source="ownerId" reference="users" allowEmpty>
        <AutocompleteInputFluid
          filterToQuery={(searchText) => ({ email: `%${searchText}` })}
          optionText="email"
          optionValue="id"
        />
      </ReferenceInput>
    </Create>
  );
};
