import { useListContext } from "react-admin";
import { Box, MenuItem, TextField } from "@mui/material";
import { useState, useEffect } from "react";
import isEqual from "lodash/isEqual";

const TimePeriodFilter = ({ source, label }) => {
  const { filterValues, setFilters } = useListContext();

  // State for Quantity & Unit
  const [filter, setFilter] = useState({
    quantity: filterValues?.[source]?.quantity || 0,
    unit: filterValues?.[source]?.unit || 60,
  });

  const unitChoices = [
    { id: 60, name: "minutes" },
    { id: 60 * 60, name: "hours" },
    { id: 60 * 60 * 24, name: "days" },
    { id: 60 * 60 * 24 * 30, name: "months" },
    { id: 60 * 60 * 24 * 365, name: "years" },
  ];

  // Update filters when the state changes
  useEffect(() => {
    const newFilter = {
      ...filterValues,
      [source]: filter.quantity * filter.unit,
    };

    !isEqual(filterValues, newFilter) && setFilters(newFilter, null);
  }, [filter, source, setFilters]);

  // Handle Input Changes
  const handleChange = (key) => (event) => {
    setFilter((prev) => ({ ...prev, [key]: event.target.value }));
  };

  const sxSmall = { ".MuiInput-input": { padding: "0 12px" } };
  const styleFielset = {
    padding: "6px 8px",
    borderRadius: "5px",
    borderStyle: "solid",
    borderWidth: "1px",
    overflow: "hidden",
    minWidth: "0%",
    borderColor: "rgba(0, 0, 0, 0.23)",
    position: "relative",
    top: "-5px",
  };
  const styleLegend = {
    color: "rgba(0, 0, 0, 0.6)",
    fontFamily:
      'Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, sans-serif',
    fontWeight: "400",
    fontSize: ".8rem",
    lineHeight: "1.4375em",
    padding: "0 5px",
  };

  return (
    <fieldset style={styleFielset}>
      <legend style={styleLegend}>{label}</legend>
      <Box display="flex" gap={2} class="TimePeriodFilter">
        {/* Quantity Input */}
        <TextField
          label=""
          type="number"
          value={filter.quantity}
          onChange={handleChange("quantity")}
          variant="standard"
          size="small"
          sx={{ ...sxSmall, width: "70px" }}
        />
        {/* Unit Select */}
        <TextField
          select
          label=""
          value={filter.unit}
          onChange={handleChange("unit")}
          variant="standard"
          size="small"
          sx={sxSmall}
        >
          {unitChoices.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    </fieldset>
  );
};

export default TimePeriodFilter;
