import {
  TextField,
  DateField,
  TextInput,
  required,
  ReferenceArrayInput,
  ReferenceArrayField,
  AutocompleteArrayInput,
  useResourceDefinition,
  SingleFieldList,
  WithRecord,
} from "react-admin";
import { TitledShow } from "../../ra-lb-tools/components/titledRecord";
import { Chip } from "@mui/material";
import {
  MyReferenceField,
  MyReferenceInput,
} from "../../ra-lb-tools/components/myReference";
import { MyList } from "../../ra-lb-tools/components/myList";
import { LabeledColumn } from "../../ra-lb-tools/components/labeledColumn";
import { MyCreate as Create } from "../../ra-lb-tools/components/myMutator";
import { PlanSubscriptionList } from "./plan_subsciptions";
import { useParams } from "react-router-dom";
import { AddShoppingCartOutlined } from "@mui/icons-material";
import {
  MyTabbedShowLayout,
  Tab,
} from "../../ra-lb-tools/components/myTabbedShowLayout";
import AddPriceButton from "./addPriceButton";
import { Row } from "../../ra-lb-tools/components/row";
export const PlanIcon = AddShoppingCartOutlined;

const PricesList = () => {
  const { recordRepresentation } = useResourceDefinition({
    resource: "prices",
  });

  const renderChip = (record) => <Chip label={recordRepresentation(record)} />;

  return (
    <ReferenceArrayField source="prices" reference="prices">
      <SingleFieldList linkType={false}>
        <WithRecord render={renderChip} />
      </SingleFieldList>
    </ReferenceArrayField>
  );
};

export const PlanList = (props) => (
  <MyList {...props} resource="plans">
    <TextField source="id" />
    <TextField source="name" />
    <TextField source="description" />
    <PricesList label={"Prices"} />
    <DateField source="createdAt" showTime />
    <DateField source="updatedAt" showTime />
  </MyList>
);

export const PlanShow = () => {
  const id = parseInt(useParams()["id"]);

  return (
    <TitledShow>
      <MyTabbedShowLayout>
        <Tab label="Summary" can={true}>
          <Row className={"RecordSummary"}>
            <LabeledColumn>
              <TextField source="id" />
              <TextField source="name" />
              <TextField source="description" />
              <PricesList label="Prices" />
              <AddPriceButton can={{ resource: "plan", action: "add_price" }} />
              <DateField source="createdAt" showTime />
              <DateField source="updatedAt" showTime />
            </LabeledColumn>
          </Row>
        </Tab>
        <Tab
          label="Subscriptions"
          path="Subscriptions"
          can={{ action: "list", resource: "plan-subscriptions" }}
        >
          <PlanSubscriptionList filter={{ plan_id: id }} />
        </Tab>
      </MyTabbedShowLayout>
    </TitledShow>
  );
};

export const PlanCreate = (props) => {
  return (
    <Create {...props}>
      <TextInput source="name" validate={required()} />
      <TextInput source="description" />
      <ReferenceArrayInput source="prices" reference="prices">
        <AutocompleteArrayInput
          filterToQuery={(searchText) => ({ product_name: `${searchText}` })}
        />
      </ReferenceArrayInput>
    </Create>
  );
};

export const PlanInput = (props) => {
  return <MyReferenceInput source="planId" reference="plans" {...props} />;
};

export const PlanField = (props) => {
  return <MyReferenceField source="planId" reference="plans" {...props} />;
};
