import { useStore } from "react-admin";
import { menu_usage_store } from "../utils/menuUsage";
import pickBy from "lodash/pickBy";
import { ResourceMenuItems } from "../utils/resourceMenuItems";
import { Box } from "@mui/material";

const threshold = 3;
const size = 3;

export const getFavorites = (menuUsage) => {
  menuUsage = pickBy(menuUsage, (v) => v > threshold);
  if (!Object.keys(menuUsage).length) {
    return [];
  }

  const favorites = Object.entries(menuUsage)
    .sort((a, b) => b[1] - a[1])
    .slice(0, size)
    .map(([key]) => key);

  return favorites;
};

export const MenuFavorites = () => {
  const [menuUsage] = useStore(menu_usage_store, {});
  const favorites = getFavorites(menuUsage);

  return (
    <Box className="MenuFavorites">
      <ResourceMenuItems onlyThese={favorites} />
    </Box>
  );
};
