import { AuthProvider } from "react-admin";
import Keycloak, { KeycloakTokenParsed } from "keycloak-js";
import jwt_decode from "jwt-decode";
import { ENV } from "../ra-lb-tools/utils/tools";
import { Store } from "../ra-lb-tools/utils/store";

export type PermissionsFunction = (
  decoded: KeycloakTokenParsed
) => string | boolean;

export const keycloakAuthProvider = (
  client: Keycloak,
  options: {
    onPermissions?: PermissionsFunction;
    loginRedirectUri?: string;
    logoutRedirectUri?: string;
  } = {}
): AuthProvider => ({
  async login() {
    return client.login({
      redirectUri: options.loginRedirectUri ?? window.location.origin,
    });
  },
  async logout() {
    return client.logout({
      redirectUri: options.logoutRedirectUri,
    });
  },
  async checkError() {
    return Promise.resolve();
  },
  async checkAuth() {
    return client.authenticated && client.token
      ? Promise.resolve()
      : Promise.reject("Failed to obtain access token.");
  },
  async getPermissions() {
    if (!client.token) {
      return Promise.resolve(false);
    }
    const decoded = jwt_decode<KeycloakTokenParsed>(client.token);
    return Promise.resolve(
      options.onPermissions ? options.onPermissions(decoded) : decoded
    );
  },
  async getIdentity() {
    if (client.token) {
      const decoded = jwt_decode<KeycloakTokenParsed>(client.token);
      const id = decoded.sub || "";
      const fullName =
        (decoded.preferred_username || decoded.email) +
        (Store.get("RaStore.app_settings.customAuthToken") ? " !!!" : "");
      return Promise.resolve({ id, fullName, decoded });
    }
    return Promise.reject("Failed to get identity.");
  },
  getAuthToken() {
    return client.token;
  },
});

// here you can set options for the keycloak client

export const getPermissions = (decoded: KeycloakTokenParsed) => {
  const roles = decoded?.realm_access?.roles;
  if (!roles) {
    return false;
  }
  if (roles.includes("superuser")) return "admin";
  return false;
};

export const raKeycloakOptions = {
  onPermissions: getPermissions,
  logoutRedirectUri: ENV().REDIRECT_URI,
};
