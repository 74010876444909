import { Box, Card, CardContent } from "@mui/material";
import { Button, Title, useAuthProvider } from "react-admin";
import { Settings } from "../utils/settings";
import { getUnsourcedInputs } from "../components/unsourcedInputs";
import { ClickToCommand } from "../components/clickToCommand";
import get from "lodash/get";
import { Store } from "../utils/store";
import { CopyToClipboardButton } from "../components/copyToClipboardButton";
import { ENV } from "../utils/tools";

const commands = {
  332211: () => {
    console.log("Switching debug mode");
    Store.flip("debug");
    window.location.reload();
  },
};

const AuthToken = () => {
  const authProvider = useAuthProvider();
  const token = authProvider.getAuthToken() || "";

  return (
    <Box>
      AuthToken: {token.substring(0, 10)}...
      <CopyToClipboardButton data={token} />
    </Box>
  );
};

const ApiUrl = () => {
  const data = ENV().API_URL;
  return (
    <Box>
      API URL: {data}
      <CopyToClipboardButton data={data} />
    </Box>
  );
};

export const AppSettings = () => {
  const setups = Settings.get();
  const inputs = getUnsourcedInputs(setups);
  inputs.splice(inputs.length - 4, 0, <AuthToken />);
  inputs.splice(inputs.length - 3, 0, <ApiUrl />);

  return (
    <Card className="AppSettings" sx={{ overflow: "visible" }}>
      <Title title="App Settings" />
      <CardContent
        sx={{
          "> *": { marginBottom: "10px" },
          ".MuiFormControl-root": { width: "100%" },
        }}
      >
        {inputs}
        <ClickToCommand
          parser={(e) => get(e, "target.value")}
          commands={commands}
        >
          <Button value={1} label="1" />
          <Button value={2} label="2" />
          <Button value={3} label="3" />
        </ClickToCommand>
      </CardContent>
    </Card>
  );
};
