import {
  Button,
  CreateButton,
  Datagrid,
  DatagridBody,
  DatagridConfigurable,
  EditButton,
  ExportButton,
  FilterButton,
  Link,
  List,
  ListBase,
  SelectColumnsButton,
  ShowButton,
  TopToolbar,
  useListContext,
  useNotify,
  useResourceContext,
  useResourceDefinition,
  useStore,
  WrapperField,
} from "react-admin";
import { debug_log } from "../utils/debugLog";
import { getStoreKey } from "../utils/getStoreKey";
import { getUniqueUIFilters } from "../utils/getUniqueUIFilters";
import {
  qualifyField,
  qualifyFilter,
  qualifyUIFilter,
  unqualifyField,
  unqualifyFilter,
} from "../utils/qualifyFilter";
import { JsonStyled } from "./jsonStyled";
import { Box, capitalize, Card, CardContent } from "@mui/material";
import { Debug } from "./debug";
import isArray from "lodash/isArray";
import omit from "lodash/omit";
import camelCase from "lodash/camelCase";
import PropTypes from "prop-types";
import { Settings } from "../utils/settings";
import { Empty } from "./Empty";
import { getConfig } from "../config/packageSetup";
import { expandCasings, objectMap } from "../utils/tools";
import MyRow from "./myRow";
import { useRef } from "react";
import { Store } from "../utils/store";
import { listSummary } from "../../config/settings";
import { Column } from "./column";
import { Upload } from "@mui/icons-material";
import { WithPermissions } from "./withPermissions";
import { NoAccess } from "./noAccess";
import { GrafanaButton } from "./grafanaButton";
import merge from "lodash/merge";

const packageConfig = getConfig();

const getFrequencies = (arr) => {
  const f = {};

  arr.forEach((val) => {
    f[val] = f[val] || 0;
    f[val]++;
  });

  return f;
};

const getDuplicated = (arr) => {
  const f = getFrequencies(arr);
  return Object.keys(f).filter((k) => f[k] > 1);
};

const RemoveMissingFilters = ({ uiFilters, resource }) => {
  const { filterValues, setFilters } = useListContext();

  const storedFilterKeysQ = Object.keys(filterValues).map((storedFilterKey) =>
    qualifyField(storedFilterKey, resource)
  );
  const uiFiltersKeysQ = uiFilters.map((uiFilter) =>
    qualifyField(uiFilter.props.source, resource)
  );

  const missing = storedFilterKeysQ.filter(
    (storedFilterKeyQ) => !uiFiltersKeysQ.includes(storedFilterKeyQ)
  );

  const duplicatedQ = getDuplicated(storedFilterKeysQ);
  const duplicated = duplicatedQ.concat(
    duplicatedQ.map((k) => unqualifyField(k))
  );

  const incorrect = missing.concat(duplicated);

  if (incorrect.length) {
    debug_log(
      `RemoveMissingFilters: Removing buggy stored filters: ${incorrect.join(
        ", "
      )}.`
    );
    const sanitizedFilters = omit(filterValues, incorrect);
    setFilters(sanitizedFilters);
  }

  return null;
};

const camelizeKeys = (o) => {
  if (!o) {
    return o;
  }

  const camel = {};

  Object.keys(o).forEach((key) => {
    camel[key] = o[key];
    if (key.includes("_")) {
      camel[camelCase(key)] = o[key];
    }
  });

  return camel;
};

const BulkButton = ({ resource }) => {
  return (
    <Button
      startIcon={<Upload />}
      color="primary"
      label="Bulk Create"
      component={Link}
      to={{
        pathname: `/${resource}/bulk-create`,
      }}
    />
  );
};

const ListActions = function ({ filters, ...props }) {
  const resourceData = useResourceDefinition();

  return (
    <TopToolbar>
      {props.supportDatagridConfigurable && (
        <SelectColumnsButton preferenceKey={props.preferenceKey} />
      )}
      <FilterButton filters={filters} />
      {props.resourceData.hasCreate && (
        <CreateButton state={{ record: props.filter }} />
      )}
      {resourceData.hasList && <ExportButton />}
      {props.enableBulkCreate && (
        <WithPermissions
          resource={props.resourceData.name}
          action={"bulk-create"}
          label={"BulkButton"}
        >
          <BulkButton resource={props.resourceData.name} />
        </WithPermissions>
      )}
      <Debug>
        <GrafanaButton expr={` |= \`GET /backend/v1/${resourceData.name}\` `} />
      </Debug>
    </TopToolbar>
  );
};

const complexLabels = (children) =>
  children.filter(
    (child) =>
      child &&
      child.props &&
      child.props.label &&
      typeof child.props.label !== "string"
  );

const MyShowButton = ({ resourceData, showOnClick, customRowButtons }) => {
  if (
    resourceData.hasShow &&
    !showOnClick &&
    !customRowButtons.includes("show")
  ) {
    return (
      <WrapperField
        key={"show_button"}
        label="Show"
        source="Show"
        sortable={false}
      >
        <ShowButton
          label=""
          sx={{
            padding: "8px",
            minWidth: "0",
            "& .MuiButton-startIcon": {
              margin: "0",
            },
          }}
        />
      </WrapperField>
    );
  }

  return null;
};

const getRowButtons = ({ resourceData, customRowButtons }) => {
  const buttons = [];

  if (resourceData.hasEdit && !customRowButtons.includes("edit")) {
    buttons.push(
      <WrapperField key={"edit_button"} label="Edit" source="Edit">
        <EditButton />
      </WrapperField>
    );
  }

  return buttons;
};
const MyDatagridBody = ({ rowProps, ...rest }) => (
  <DatagridBody {...rest} row={<MyRow {...rowProps} />} />
);

export const ListHeader = ({ resource, actions, ...props }) => {
  const pageResource = useResourceContext();
  const isEmbeded = resource && pageResource && pageResource != resource;

  if (isEmbeded) return null;

  resource = resource || pageResource;

  return (
    <Box className="ListHeader" {...props}>
      <Box
        className="ListTitleWrapper"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Column sx={{ flexWrap: "wrap" }}>
          <Box className="ListTitle">{capitalize(resource)}</Box>
          <Box className="ListSummary" sx={{ marginTop: "12px" }}>
            {listSummary[resource]}
          </Box>
        </Column>
        <Box
          className="ActionsWrapper"
          sx={{ "& .MuiToolbar-root": { minHeight: "auto" } }}
        >
          {actions}
        </Box>
      </Box>
    </Box>
  );
};

export const MyList = ({
  queryOptions = {},
  customRowButtons = [],
  enableBulkCreate,
  noHeaders,
  dataGridProps,
  rowProps,
  forceShow, //workaround for permisions on non defined resources TODO: make this better
  children,
  ...listProps
}) => {
  const resource = listProps.resource;
  if (children && !isArray(children)) {
    children = [children];
  }

  // Patch: persistent selected ids
  // "Selection state will remain linked to a resource-based key regardless of the specified storeKey string. This is a design choice..."
  const renderCount = useRef(0);
  renderCount.current += 1;
  if (renderCount.current < 2) {
    Store.set(`RaStore.${resource}.selectedIds`, []);
  }

  const resourceData = useResourceDefinition({ resource });
  const notify = useNotify();

  //https://github.com/marmelab/react-admin/blob/master/CHANGELOG.md#v4104
  const supportDatagridConfigurable = !complexLabels(children).length;

  const Grid = supportDatagridConfigurable ? DatagridConfigurable : Datagrid;

  debug_log(`<<<<<<<<<<<< List for '${resource}' <<< start`);

  let filters = getUniqueUIFilters(listProps.filters, listProps.filter);
  filters = qualifyUIFilter(filters, resource);

  const filterDefaultValues = objectMap({
    obj: listProps.filterDefaultValues || {},
    keyCallback: (key) => qualifyField(key, resource),
  });
  const filter = qualifyFilter(listProps.filter, resource);
  const storeKey = getStoreKey({ resource });
  const preferenceKey = supportDatagridConfigurable ? storeKey : null;
  const [storedData] = useStore(storeKey);
  const showOnClick = Settings.getValue("showOnClick");

  dataGridProps = dataGridProps || {};
  const omit = expandCasings(Object.keys(unqualifyFilter(filter))).concat(
    dataGridProps.omit || []
  );

  dataGridProps = Object.assign(
    { body: <MyDatagridBody rowProps={rowProps} /> },
    { rowClick: resourceData.hasShow && showOnClick ? "show" : null },
    preferenceKey ? { preferenceKey } : {},
    { bulkActionButtons: false },
    dataGridProps,
    { omit }
  );

  listProps = Object.assign({}, listProps, {
    filter,
    filters,
    filterDefaultValues,
    storeKey,
    perPage: packageConfig.listPageSize,
    empty: (
      <Empty
        buttonProps={{ state: { record: camelizeKeys(listProps.filter) } }}
      />
    ),
    queryOptions: merge({}, { meta: { notify } }, queryOptions),
  });

  const actionsProps = {
    preferenceKey,
    resourceData,
    supportDatagridConfigurable,
    filter: camelizeKeys(listProps.filter),
    filters,
    enableBulkCreate,
  };

  const actions = <ListActions {...actionsProps} />;
  const sources = children.map((ch) => ch && ch.props && ch.props.source);

  const pageResource = useResourceContext();
  const isEmbeded = resource && pageResource && pageResource != resource;
  const showHeader = !noHeaders && !isEmbeded;

  debug_log(`>>>>>>>>>>>> List for '${resource}' >>> end`);

  return resourceData.hasList || forceShow ? (
    <>
      <ListBase>
        {showHeader && <ListHeader resource={resource} actions={actions} />}
      </ListBase>
      <List
        {...listProps}
        actions={showHeader ? null : actions}
        disableSyncWithLocation={true}
      >
        <Grid {...dataGridProps}>
          <MyShowButton {...{ resourceData, showOnClick, customRowButtons }} />
          {children}
          {getRowButtons({ resourceData, showOnClick, customRowButtons })}
        </Grid>
        <RemoveMissingFilters uiFilters={filters} resource={resource} />
      </List>
      <Debug foldable={true}>
        <Card>
          <CardContent>
            <h3>Fixed filter</h3>
            <JsonStyled data={filter} />
            <h3>List setup</h3>
            <JsonStyled
              data={{
                resource,
                resourceData,
                storeKey,
                storedData,
                preferenceKey,
                supportDatagridConfigurable,
                showOnClick,
                filterDefaultValues,
                sources,
              }}
            />
          </CardContent>
        </Card>
      </Debug>
    </>
  ) : (
    <NoAccess label={`List ${resource}`} />
  );
};

MyList.propTypes = {
  resource: PropTypes.string.isRequired,
};
