import { Box } from "@mui/material";
import ReactJson from "react-json-view";
import { CopyToClipboardButton } from "./copyToClipboardButton";

const jsonLines = (data) =>
  JSON.stringify(data, null, 2)
    .split("\n")
    .filter((v) => v.trim().length > 1).length;

export const JsonStyled = ({ wrapperStyle, ...props }) => {
  if (!props.data) {
    return null;
  }

  if (
    !props.collapsed &&
    props.maxLines &&
    props.maxLines < jsonLines(props.data)
  ) {
    props = Object.assign({}, props, { collapsed: true });
  }

  const defaultStyle = {
    maxHeight: "50vh",
    overflowY: "auto",
    position: "relative",
  };

  const copyToClipboardStyle = {
    position: "absolute",
    right: "3px",
    top: "4px",
    zIndex: "2",
  };

  return (
    <Box style={{ ...defaultStyle, ...wrapperStyle }}>
      <CopyToClipboardButton data={props.data} sx={copyToClipboardStyle} />
      <ReactJson src={props.data} {...props} />
    </Box>
  );
};
JsonStyled.defaultProps = {
  name: null,
  collapsed: false,
  enableClipboard: false,
  displayDataTypes: false,
  theme: "google",
  style: { padding: "1em", borderRadius: "25px" },
};
