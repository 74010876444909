import { Show, ShowBase, TopToolbar } from "react-admin";

import { Box } from "@mui/material";
import { GoBackButton } from "./goBackButton";
import { RecordTitle } from "./recordTitle";
import { MyEditButton as EditButton } from "./myEditButton";
import { WithPermissions } from "./withPermissions";

const TitledWrapper = (props) => {
  return <Box sx={{ width: "100%" }}>{props.children}</Box>;
};

const ShowActions = () => (
  <TopToolbar>
    <EditButton />
    <GoBackButton size="small" variant="text" />
  </TopToolbar>
);

export const TitledShow = ({
  labelField,
  className,
  sx,
  style,
  actions,
  noHeaders,
  ...props
}) => {
  actions = actions || <ShowActions />;
  const recordTitle = <RecordTitle labelField={labelField} actions={actions} />;
  const _sx = { width: "100%" };

  return (
    <WithPermissions action={"show"}>
      <Box
        sx={{ ..._sx, ...sx }}
        style={style}
        className={["TitledShowWrapper", className].join(" ")}
      >
        {!noHeaders && <ShowBase {...props}>{recordTitle}</ShowBase>}
        <Show
          component={TitledWrapper}
          className="TitledShow"
          actions={null}
          {...props}
        >
          {props.children}
        </Show>
      </Box>
    </WithPermissions>
  );
};
