import { AutocompleteInput, useChoicesContext } from "react-admin";
import { JsonStyled } from "./jsonStyled";
import { Debug } from "./debug";
import { Dot } from "./dot";
import { Box } from "@mui/material";

const myProps = ["slack"];

const pickBy = (obj, filter) => {
  return Object.fromEntries(Object.entries(obj).filter(filter));
};

const theirProps = (props) => {
  return pickBy(props, ([key]) => !myProps.includes(key));
};

const AutocompleteInputFluid = ({ forceSize, size, ...props }) => {
  const { allChoices } = useChoicesContext();

  const wrapperSx = { display: "flex" };

  const inputSx = {
    "& .MuiAutocomplete-input": {
      textOverflow: "unset",
    },
    display: "inline-block",
    verticalAlign: "top",
    flex: 1,
  };

  const debugSx = {
    display: "inline-block",
    marginLeft: 10,
    maxHeight: "50vh",
    maxWidth: "50vw",
    overflowY: "auto",
    flexShrink: 1,
  };

  return (
    <Box className="AutocompleteInputFluid" sx={wrapperSx}>
      <AutocompleteInput
        {...theirProps(props)}
        sx={inputSx}
        className="AutocompleteInput"
        size={forceSize || size}
      >
        {props.children}
      </AutocompleteInput>
      <Debug sx={debugSx}>
        <Dot>
          <JsonStyled data={allChoices} />
        </Dot>
      </Debug>
    </Box>
  );
};

AutocompleteInputFluid.defaultProps = {
  slack: 4,
};

export default AutocompleteInputFluid;
