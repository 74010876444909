import { TextField, DateField, NumberInput } from "react-admin";

import BusinessIcon from "@mui/icons-material/Business";
import { JsonField } from "../../ra-lb-tools/components/jsonField";
import { MyList } from "../../ra-lb-tools/components/myList";
import { OrganizationField, OrganizationInput } from "./organizations";
import { ProductField, ProductInput } from "./products";
import { PlanSubscriptionField } from "./plan_subsciptions";

export const SystemIcon = BusinessIcon;
export const LockerUsageRecordsList = (props) => {
  const filters = [
    <OrganizationInput source="organization_id" alwaysOn key={2} />,
    <ProductInput source="product_id" alwaysOn key={3} />,
    <NumberInput source="plan_subscription_id" alwaysOn key={4} />,
  ];

  const title = "Locker Usage Records";
  const resource = "locker-usage-records";

  return (
    <MyList
      title={title}
      resource={resource}
      sort={{
        field: "id",
        order: "DESC",
      }}
      filters={filters}
      {...props}
    >
      <TextField source="id" />
      <DateField source="fromDt" showTime />
      <DateField source="toDt" showTime />
      <TextField source="quantity" />
      <ProductField source="productId" />
      <PlanSubscriptionField source="planSubscriptionId" />
      <OrganizationField source="organizationId" />
      <TextField source="processorItemId" />
      <TextField source="processorMeterId" />
      <JsonField source="meterEventSent" />
      <JsonField source="meterEventSentErrors" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </MyList>
  );
};
