import { Store } from "../ra-lb-tools/utils/store";

export const AuthProxy = (auth) =>
  new Proxy(auth, {
    get(obj, prop) {
      if (prop === "token") {
        const customToken = Store.get("RaStore.app_settings.customAuthToken");

        return customToken ? customToken : obj[prop];
      }

      return obj[prop];
    },
  });
