import {
  TextField,
  TextInput,
  ReferenceInput,
  SelectInput,
  SelectField,
  BooleanInput,
  FilterList,
  FilterListItem,
  useTheme,
  useRecordContext,
  maxLength,
  FormDataConsumer,
  WithRecord,
  SearchInput,
} from "react-admin";
import DnsIcon from "@mui/icons-material/Dns";
import { statusChoices, accessModeChoices } from "./base";
import { StatusHeader } from "./components";
import AutocompleteInputFluid from "../../ra-lb-tools/components/autocompleteInputFluid";
import { Box, Card, CardContent } from "@mui/material";
import { Link } from "react-router-dom";
import { LocationField, LocationInput } from "../locations/components";
import { MyList } from "../../ra-lb-tools/components/myList";
import { SystemField, SystemInput } from "../systems";
import {
  MyEdit as Edit,
  MyCreate as Create,
  MyEdit,
} from "../../ra-lb-tools/components/myMutator";
import { sxSpot } from "../../ra-lb-tools/theme/styles";
import { getFields } from "../../ra-lb-tools/utils/tools";
import { MyBooleanField as BooleanField } from "../../components/myBooleanField";
import {
  MyReferenceField,
  MyReferenceInput,
} from "../../ra-lb-tools/components/myReference";
import { MyBulkCreate } from "../../ra-lb-tools/components/myBulkCreate";
import { ColoredOptionChipField } from "../../components/coloredOptionChipField";
import { Row } from "../../ra-lb-tools/components/row";
import { MultiDateField } from "../../ra-lb-tools/components/multiDateField";
import { TowerHistoryStatus } from "./TowerDetail";

export const TowerIcon = DnsIcon;

export const TowerFilterSidebar = () => (
  <Card sx={{ order: -1, mr: 2, width: 200, marginTop: "20px" }}>
    <CardContent>
      <FilterList label="Tower is for Test?">
        <FilterListItem label="Yes" value={{ is_for_test: true }} />
        <FilterListItem label="No" value={{ is_for_test: false }} />
      </FilterList>
    </CardContent>
  </Card>
);

export const isForTestChoices = [
  { id: true, name: "Yes" },
  { id: false, name: "No" },
];

const towerFilters = [
  <TextInput source="tower_id" alwaysOn key="tower_id" />,
  <TextInput source="name" alwaysOn key="name" />,
  <SearchInput source="search" alwaysOn key="search" no-label />,
  <SelectInput source="status_id" choices={statusChoices} alwaysOn key={1} />,
  <SelectInput
    source="has_oos"
    label="Is OOS"
    key={"has_oos"}
    choices={isForTestChoices}
    alwaysOn
  />,
  <SelectInput
    source="is_for_test"
    choices={isForTestChoices}
    key={5}
    alwaysOn
    defaultValue={false}
  />,
  <LocationInput source="location_id" allowEmpty alwaysOn key={2} />,
  <SystemInput
    source="systemId"
    label="System"
    allowEmpty
    alwaysOn
    key={3}
    slack={8}
  />,
  <ReferenceInput
    source="owner_id"
    label="Owner"
    reference="users"
    allowEmpty
    key={4}
  >
    <AutocompleteInputFluid
      label="Owner"
      filterToQuery={(searchText) => ({ email: `%${searchText}` })}
      optionText="email"
      optionValue="id"
    ></AutocompleteInputFluid>
  </ReferenceInput>,
  <TextInput label="Device serial" source="deviceSerial" key="deviceSerial" />,
];

const hasOOS = (record) => record.statusId == 2 || record.oosLockersCount;

const rowStyle = (record, palette) => {
  const backgroundColor = record.isForTest
    ? palette.specialColors.testing
    : hasOOS(record)
    ? palette.specialColors.oos
    : palette.background.paper;

  return { backgroundColor };
};

const OosLockers = () => {
  const record = useRecordContext();
  if (!record) return null;
  if (!record.oosLockersCount) return null;

  const onClick = (e) => e.stopPropagation();
  const to = `/towers/${record.id}/show`;

  return (
    <Link to={to} onClick={onClick} style={sxSpot()}>
      <TextField source="oosLockersCount" />
    </Link>
  );
};

export const TowerList = (props) => {
  const [theme] = useTheme();
  const palette = theme.palette;
  const resource = "towers";

  const userFields = (
    <>
      <TextField source="id" />
      <BooleanField source="isForTest" label="Is for test?" />
      <TextField source="name" />
      <SelectField
        source="statusId"
        choices={statusChoices}
        optionText={<ColoredOptionChipField />}
      />
      <TowerHistoryStatus
        label="Last Sync Status"
        sx={{
          ".TowerHistorySummary.status-failed": {
            fontSize: "1.2em !important",
          },
          ".TowerHistoryDetail": {
            display: "none",
          },
        }}
      />
      <OosLockers label="OOS Lockers" />
      <SystemField label="System" />
      <LocationField label="Location" />
      <TextField source="location.city" label="City" />
      <TextField source="location.state" label="State" />
      <SelectField source="accessModeId" choices={accessModeChoices} />
      <MyReferenceField
        source="ownerId"
        reference="users"
        link="show"
        primaryField="email"
      />
      <MyReferenceField
        source="towerModelId"
        reference="tower-models"
        link="show"
      />
      <TextField source="fwVersion" />
      <TextField source="blVersion" label="Bootloader Version" />
      <BooleanField source="hasKeypad" />
      <TextField source="deviceSerial" />
      <MultiDateField source="configuredAt" />
      <TextField source="deviceModel" />
      <TextField source="lastCommand" />
      <TextField source="lastEvent" />
      <MultiDateField
        source="lastEventsSync"
        showTime={true}
        label="Last Events Sync"
      />
      <MultiDateField source="lastEventsReset" showTime={true} />
      <TextField source="lockersCount" sortable={false} />
      <TextField source="pinCode" />
    </>
  );

  const debugFields = <></>;

  return (
    <MyList
      {...props}
      filters={towerFilters}
      resource={resource}
      dataGridProps={{
        rowStyle: (record) => rowStyle(record, palette),
        omit: [
          "deviceSerial",
          "configuredAt",
          "deviceModel",
          "lastCommand",
          "lastEvent",
          "lastEventsSync",
          "lastEventsReset",
          "lockersCount",
          "pinCode",
        ],
      }}
      filterDefaultValues={{
        is_for_test: false,
      }}
      enableBulkCreate={true}
    >
      {getFields(userFields, debugFields)}
    </MyList>
  );
};

const TowerName = () => {
  const nameMaxLength = 16;

  return (
    <Row sx={{ alignItems: "baseline" }}>
      <TextInput
        source="name"
        validate={maxLength(nameMaxLength)}
        inputProps={{ maxLength: nameMaxLength }}
      />
      <Box>
        <FormDataConsumer>
          {({ formData }) => (
            <Box>
              {(formData.name || "").length}/{nameMaxLength}
            </Box>
          )}
        </FormDataConsumer>
      </Box>
    </Row>
  );
};

export const TowerEdit = (props) => {
  return (
    <Edit {...props}>
      <TextInput source="id" disabled />
      <TowerName source="name" />
      <TextInput source="pinCode" />
      <TextInput source="description" multiline />
      <SystemInput label="System" />
      <LocationInput />
      <SelectInput
        source="statusId"
        choices={statusChoices}
        optionValue="id"
        optionText="name"
        label={<StatusHeader />}
      />
      <SelectInput
        source="accessModeId"
        choices={accessModeChoices}
        optionValue="id"
        optionText="name"
      />
      <ReferenceInput
        label="Owner"
        source="ownerId"
        reference="users"
        filter={{ can_own_towers: true }}
        allowEmpty
      >
        <AutocompleteInputFluid
          filterToQuery={(searchText) => ({ email: `%${searchText}` })}
          optionText="email"
          optionValue="id"
        ></AutocompleteInputFluid>
      </ReferenceInput>
      <TextInput source="privateNotes" multiline />
      <BooleanInput source="isForTest" />
      <BooleanInput source="hasKeypad" />
    </Edit>
  );
};

export const TowerCreate = (props) => {
  return (
    <Create {...props}>
      <TowerName source="name" />
      <TextInput source="pinCode" />
      <TextInput source="description" multiline />
      <SystemInput source="systemId" />
      <LocationInput />
      <ReferenceInput source="towerModelId" reference="tower-models">
        <AutocompleteInputFluid
          filterToQuery={(searchText) => ({ name: `%${searchText}` })}
          optionText="name"
          optionValue="id"
        ></AutocompleteInputFluid>
      </ReferenceInput>
      <SelectInput
        source="accessModeId"
        choices={accessModeChoices}
        optionValue="id"
        optionText="name"
      />
      <ReferenceInput
        source="ownerId"
        reference="users"
        filter={{ can_own_towers: true }}
        allowEmpty
      >
        <AutocompleteInputFluid
          filterToQuery={(searchText) => ({ email: `%${searchText}` })}
          optionText="email"
          optionValue="id"
        ></AutocompleteInputFluid>
      </ReferenceInput>
      <SelectInput
        source="statusId"
        choices={statusChoices}
        optionValue="id"
        optionText="name"
        label={<StatusHeader />}
      />
      <TextInput source="privateNotes" multiline />
      <BooleanInput source="isForTest" />
      <BooleanInput source="hasKeypad" />
    </Create>
  );
};

export const TowerBulkCreate = () => {
  const schema = [
    {
      name: "string",
      systemId: 0,
      towerModelId: 0,
      locationId: 0,
      description: "string",
      pinCode: "string",
      accessModeId: 1,
      isForTest: false,
      hasKeypad: false,
      privateNotes: "string",
      statusId: 1,
      ownerId: "string",
    },
  ];

  return (
    <MyBulkCreate
      resource="towers"
      label="JSON Tower List"
      schema={schema}
      requiredFields={4}
    />
  );
};

const render = (record, DefaultLabel) => (
  <>
    {DefaultLabel}
    {record.statusId == 4 ? " -- RETIRED" : ""}
  </>
);

export const TowerInput = (props) => {
  return (
    <MyReferenceInput
      source="towerId"
      reference="towers"
      render={render}
      {...props}
    />
  );
};

export const TowerField = (props) => {
  return (
    <MyReferenceField
      source="towerId"
      reference="towers"
      render={render}
      queryOptions={{ meta: { status_id: 0 } }}
      {...props}
    />
  );
};

export const TowerTowerModelEdit = (props) => {
  return (
    <MyEdit
      resource="towers"
      {...props}
      formProps={{
        shouldUnregister: true,
      }}
      mutationOptions={{
        meta: { customSegment: "model" },
      }}
    >
      <TextInput source="id" disabled />
      <TextInput source="name" disabled />
      <WithRecord
        render={(record) => (
          <ReferenceInput source="towerModelId" reference="tower-models">
            <AutocompleteInputFluid
              filterToQuery={(searchText) => ({ name: `%${searchText}` })}
              optionText="name"
              optionValue="id"
              disabled={record.lockersCount}
            ></AutocompleteInputFluid>
          </ReferenceInput>
        )}
      />
    </MyEdit>
  );
};
