import { ReferenceArrayInput } from "react-admin";
import AutocompleteInputFluid from "./autocompleteInputFluid";

export const ReferenceInputString = ({
  source,
  reference,
  dataField = "id",
  ...rest
}) => {
  return (
    <ReferenceArrayInput source={source} reference={reference}>
      <AutocompleteInputFluid
        forceSize="medium"
        filterToQuery={(searchText) => ({ [dataField]: `${searchText}` })}
        optionText={dataField}
        optionValue={dataField}
        {...rest}
      />
    </ReferenceArrayInput>
  );
};
