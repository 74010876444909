import {
  TextField,
  DateField,
  NumberInput,
  FunctionField,
  WithRecord,
  useTheme,
  SelectField,
  SelectInput,
  useRecordContext,
  BulkUpdateWithConfirmButton,
} from "react-admin";
import { useParams } from "react-router-dom";

import BusinessIcon from "@mui/icons-material/Business";
import { MyList } from "../../ra-lb-tools/components/myList";
import {
  MyReferenceField,
  MyReferenceInput,
} from "../../ra-lb-tools/components/myReference";
import { TowerInput } from "../tower/towers";
import { getValue, humanPeriod } from "../../ra-lb-tools/utils/tools";
import { UserInput } from "../users";
import { Box, Chip } from "@mui/material";
import { TitledShow } from "../../ra-lb-tools/components/titledRecord";
import {
  MyTabbedShowLayout,
  Tab,
} from "../../ra-lb-tools/components/myTabbedShowLayout";
import { LabeledColumn } from "../../ra-lb-tools/components/labeledColumn";
import { LockerUsageEventList } from "./lockerUsageEvent";
import { LockersStatusField, OccupiedLockerClientInfo } from "../lockers";
import {
  AttachMoney,
  BugReport,
  ManageSearch,
  PriorityHigh,
  ThumbUpAlt,
  Warning,
  WarningAmber,
} from "@mui/icons-material";
import { Row } from "../../ra-lb-tools/components/row";
import { LocationInput } from "../locations/components";
import { SystemInput } from "../systems";
import { MyLinkField } from "../../ra-lb-tools/components/myLinkField";
import { MyBooleanField } from "../../components/myBooleanField";
import { NullableBooleanField } from "../../ra-lb-tools/components/nullableBooleanField";
import TimePeriodFilter from "./timePeriodFilter";
import { Column } from "../../ra-lb-tools/components/column";

export const SystemIcon = BusinessIcon;

const statuses = [
  { label: "Finished", color: "info" },
  { label: "Active", color: "success" },
];

/**
 * TODO:
 * Check for enhancement
 * Currently this needs to be manually synchronized from
 * hal-backend/app/app/models/locker_usage.py::class LockerUsageType(EnumBase)
 */
export const typeChoices = [
  {
    id: 1,
    name: "BILLABLE",
    icon: <AttachMoney />,
    style: { color: "green", fontSize: "1.2em" },
  },
  {
    id: 2,
    name: "TEST",
    icon: <BugReport />,
    style: { color: "grey" },
  },
  {
    id: 3,
    name: "IN_REVIEW",
    icon: <ManageSearch />,
    style: { color: "darkmagenta" },
  },
];

const lockerUsageTimeThreshold = [
  {
    seconds: 604800,
    name: "1 week",
    style: {
      color: "red",
    },
    icon: <Warning />,
  },
  {
    seconds: 172800,
    name: "2 days",
    style: {
      color: "orange",
    },
    icon: <WarningAmber />,
  },
  {
    seconds: 86400,
    name: "1 day",
    style: {
      color: "goldenrod",
    },
    icon: <PriorityHigh />,
  },
  {
    seconds: 600,
    name: "10 minutes",
    style: {
      color: "green",
    },
    icon: <ThumbUpAlt />,
  },
  {
    seconds: 0,
    name: "All",
    style: {
      color: "violet",
    },
    icon: <BugReport />,
  },
];

const BulkPropertyChangeButtons = ({ choices, property, meta, ...rest }) => {
  const sx = {
    gap: "5px",
    backgroundColor: "white",
    position: "absolute",
    top: "0",
    textAlign: "left",
    padding: "10px",
    alignItems: "baseline",
    border: "1px solid black",
    borderRadius: "5px",
    borderColor: "rgba(0, 0, 0, 0.23)",
    "> *": {
      padding: "5px !important",
      width: "100%",
      justifyContent: "flex-start !important",
    },
  };

  return (
    <Column sx={sx}>
      {choices.map((choice) => (
        <BulkUpdateWithConfirmButton
          key={choice.id}
          label={"Set " + choice.name}
          data={{
            [property]: choice.id,
            __meta: meta,
          }}
          icon={choice.icon}
          {...rest}
        />
      ))}
    </Column>
  );
};

export const LockerUsageTime = ({ lockerUsage, sx }) => {
  lockerUsage = lockerUsage || useRecordContext();
  const seconds = lockerUsage.usageTime;
  const label = humanPeriod(seconds);
  let setup = null;

  const style = {
    fontWeight: "bolder",
  };

  for (const _setup of lockerUsageTimeThreshold) {
    if (seconds >= _setup.seconds) {
      setup = _setup;
      break;
    }
  }

  return (
    <Row
      className="LockerUsageTime"
      style={{ ...style, ...setup.style }}
      sx={sx}
      title={`${seconds} seconds`}
    >
      <Box sx={{ whiteSpace: "nowrap" }}>{label}</Box>
      <Box sx={{ marginLeft: "5px !important" }}>{setup.icon}</Box>
    </Row>
  );
};

const rowStyle = (record, palette) => {
  const backgroundColor = getValue(record, "tower.isForTest")
    ? palette.specialColors.testing
    : palette.background.paper;

  return { backgroundColor };
};

export const LockerUsageList = (props) => {
  const [theme] = useTheme();
  const palette = theme.palette;

  const filters = [
    <NumberInput source="locker_id" alwaysOn key={1} label="Locker Id" />,
    <MyReferenceInput
      source="locker__status_id"
      reference="locker-statuses"
      alwaysOn
      key={7}
      label="Locker Status"
    />,
    <UserInput source="user_id" alwaysOn key={2} />,
    <TowerInput source="locker__tower_id" alwaysOn key={3} label="Tower" />,
    <LocationInput source="location_id" alwaysOn key={5} />,
    <SystemInput source="system_id" alwaysOn key={6} />,
    <NullableBooleanField source="active" alwaysOn key={4} />,
    <NullableBooleanField source="is_for_test" alwaysOn key={7} />,
    <SelectInput source="type" choices={typeChoices} alwaysOn key={8} />,
    <TimePeriodFilter
      source="usage_time_ge"
      label="Usage >="
      alwaysOn
      key={11}
    />,
  ];

  const title = "Locker Usage";
  const resource = "locker-usage";

  return (
    <MyList
      title={title}
      resource={resource}
      sort={{
        field: "id",
        order: "DESC",
      }}
      filters={filters}
      dataGridProps={{
        rowStyle: (record) => rowStyle(record, palette),
        bulkActionButtons: (
          <BulkPropertyChangeButtons
            choices={typeChoices}
            property={"type"}
            meta={{ customSegment: "update_type" }}
          />
        ),
      }}
      {...props}
    >
      <TextField source="id" />
      <MyReferenceField source="lockerId" reference="lockers" />
      <FunctionField
        label="Locker Status"
        render={(record) =>
          record.toDt ? null : (
            <MyReferenceField
              source="lockerId"
              reference="lockers"
              link={null}
              render={(locker) => (
                <LockersStatusField
                  editable={false}
                  record={locker}
                  statusIdField="statusId"
                />
              )}
            />
          )
        }
      />
      <DateField source="createdAt" showTime />
      <DateField source="fromDt" showTime />
      <DateField source="toDt" showTime />
      <LockerUsageTime source="usageTime" />
      <WithRecord
        source="Status"
        render={(record) => {
          const status = statuses[record.toDt ? 0 : 1];
          return <Chip label={status.label} color={status.color} />;
        }}
      />
      <MyReferenceField
        label="Last Drop off client info"
        source="lockerId"
        reference="lockers"
        link={null}
        render={(locker) => <OccupiedLockerClientInfo record={locker} />}
      />
      <SelectField
        source="type"
        choices={typeChoices}
        optionText={(choice) => (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              ...choice.style,
            }}
          >
            {choice.icon} {choice.name}
          </span>
        )}
      />
      <MyReferenceField
        source="userId"
        reference="users"
        primaryField="email"
      />
      <WithRecord
        source="Tower"
        sortable={false}
        render={(record) => {
          return <MyLinkField record={record.tower} resource={"towers"} />;
        }}
      />
      <MyBooleanField
        source="tower.isForTest"
        label="Is test tower"
        sortable={false}
      />
    </MyList>
  );
};

export const ActiveLockerUsageList = ({ filter = {}, ...props }) => {
  const fixed = { active: true };
  filter = { ...filter, ...fixed };

  return <LockerUsageList filter={filter} {...props} />;
};

export const LockerUsageShow = () => {
  const id = parseInt(useParams()["id"]);

  return (
    <TitledShow>
      <MyTabbedShowLayout>
        <Tab label="Summary" can={true}>
          <Row className={"RecordSummary"}>
            <LabeledColumn>
              <TextField source="id" />
              <MyReferenceField source="lockerId" reference="lockers" />
              <FunctionField
                label="Locker Status"
                render={(record) =>
                  record.toDt ? null : (
                    <MyReferenceField
                      source="lockerId"
                      reference="lockers"
                      link={null}
                      render={(locker) => (
                        <LockersStatusField
                          editable={false}
                          record={locker}
                          statusIdField="statusId"
                        />
                      )}
                    />
                  )
                }
              />
              <WithRecord
                source="Tower"
                render={(record) => {
                  return (
                    <MyLinkField record={record.tower} resource={"towers"} />
                  );
                }}
              />
              <MyBooleanField source="tower.isForTest" label="Is test tower" />
              <SelectField source="type" choices={typeChoices} />
            </LabeledColumn>
            <LabeledColumn>
              <MyReferenceField
                source="userId"
                reference="users"
                primaryField="email"
              />
              <DateField source="createdAt" showTime />
              <DateField source="fromDt" showTime />
              <DateField source="toDt" showTime />
              <LockerUsageTime
                source="usageTime"
                sx={{ justifyContent: "flex-start" }}
              />
              <WithRecord
                source="Status"
                render={(record) => {
                  const status = statuses[record.toDt ? 0 : 1];
                  return <Chip label={status.label} color={status.color} />;
                }}
              />
              <MyReferenceField
                label="Last Drop off client info"
                source="lockerId"
                reference="lockers"
                link={null}
                render={(locker) => (
                  <OccupiedLockerClientInfo record={locker} />
                )}
              />
            </LabeledColumn>
          </Row>
        </Tab>
        <Tab
          label="Events"
          path="events"
          can={{ action: "list", resource: "locker-usage-events" }}
        >
          <LockerUsageEventList filter={{ lockerUsageId: id }} />
        </Tab>
      </MyTabbedShowLayout>
    </TitledShow>
  );
};
