import { DateField, NumberInput, TextField } from "react-admin";
import { JsonField } from "../ra-lb-tools/components/jsonField";
import { MyList } from "../ra-lb-tools/components/myList";
import { LockerField, LockerInput } from "../components/referenceFields";
import { TowerInput } from "./tower/towers";
import { MyReferenceField } from "../ra-lb-tools/components/myReference";

export default function CommandsList(props) {
  const filters = [
    <NumberInput source="id" alwaysOn key={1} />,
    <LockerInput source="locker_id" alwaysOn key={2} />,
    <TowerInput source="tower_id" alwaysOn key={3} />,
    <NumberInput source="packet_type" alwaysOn key={4} />,
  ];

  const resource = "commands";

  return (
    <MyList {...props} resource={resource} filters={filters}>
      <TextField source="id" />
      <TextField source="packetTypeName" />
      <JsonField source="commandData" />
      <DateField source="created" showTime={true} />
      <TextField source="description" />
      <LockerField source="commandData.locker_id" label="Locker" />
      <MyReferenceField source="towerId" reference="towers" link="show" />
    </MyList>
  );
}
