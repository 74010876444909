import { cloneElement } from "react";
import { Settings } from "./settings";
import { Debug } from "../components/debug";
import { Store } from "./store";
import get from "lodash/get";

export const ENV_TYPES = {
  PROD: {
    href: "https://admin.harborlockers.com/",
    favicon: "favicon.ico",
    grafana: {
      namespace: "hal-backend",
    },
  },
  SANDBOX: {
    href: "https://admin.sandbox.harborlockers.com/admin/",
    label: "sandbox",
    barSx: { backgroundColor: "darkgoldenrod" },
    favicon: "favicon_snd.ico",
    grafana: {
      namespace: "sandbox-backend",
    },
  },
  DEV: {
    href: "https://admin.dev.harborlockers.com/admin/",
    label: "dev env",
    barSx: { backgroundColor: "darkorchid" },
    favicon: "favicon_dev.ico",
    grafana: {
      namespace: "sal-backend",
    },
  },
  LOCAL: {
    href: "http://admin.harborlockers.localhost/",
    label: "local env",
    barSx: { backgroundColor: "lightgreen" },
    favicon: "favicon_local.ico",
    grafana: {
      namespace: "local-backend",
    },
  },
};

export const getCurrentEnv = () => {
  const host = window.location.hostname.toLowerCase().split(".");

  if (host[host.length - 1] == "localhost") {
    return "LOCAL";
  } else if (host.includes("dev")) {
    return "DEV";
  } else if (host.includes("sandbox")) {
    return "SANDBOX";
  } else {
    return "PROD";
  }
};

export const getEnvStyling = () => ENV_TYPES[getCurrentEnv()];

export const ENV = () => {
  return Object.assign(
    {},
    window.ENV,
    get(Store.getAll(), "RaStore.app_settings.ENV")
  );
};

export const setFavicon = (favicon) => {
  var link = document.querySelector("link[rel~='icon']");
  if (link) {
    const href = link.href.split("/");
    href.pop();
    href.push(favicon);
    link.href = href.join("/");
  }
};

export const setEnvFavicon = () => {
  const env_styling = getEnvStyling();

  if (env_styling.favicon) {
    setFavicon(env_styling.favicon);
  }
};

export const asArray = (value) => (Array.isArray(value) ? value : [value]);

const addKeys = (comps) =>
  comps.map((comp, index) => cloneElement(comp, { key: index }));

export const getFields = (userFields, debugFields) => {
  let fields = [].concat(asArray(userFields.props.children));

  if (Settings.debugging() && debugFields.props.children) {
    fields = fields.concat(
      asArray(debugFields.props.children).map((debugField, index) => (
        <Debug key={index} source={debugField.props.source}>
          {debugField}
        </Debug>
      ))
    );
  }

  fields = addKeys(fields);

  return fields;
};

export const objectMap = ({
  obj = {},
  valueCallback = (_value, _key) => _value,
  keyCallback = (_key, _value) => _key,
}) =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      keyCallback(key, value),
      valueCallback(value, key),
    ])
  );

export const toClipboard = (data) => {
  if (typeof data === "object") {
    data = JSON.stringify(data);
  }

  navigator.clipboard.writeText(data);
};

export const splitArray = (arr, columns) => {
  const chunkSize = Math.ceil(arr.length / columns);

  const result = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize));
  }
  return result;
};

function capitalizeFirstChar(str) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Returns variations of the string on different casings
 * @param {*} str
 */
export const expandCasing = (str) => {
  let comps = str.split(/(?=[A-Z])|[^a-zA-Z0-9]+/).filter(Boolean);

  const casings = {
    str,
  };

  casings.snake = comps.map((s) => s.toLowerCase()).join("_");
  casings.camel = comps
    .map((s) => s.toLowerCase())
    .map((s, idx) => (idx ? capitalizeFirstChar(s) : s))
    .join("");

  return casings;
};

export const expandCasings = (arr) => {
  let casings = [];

  arr.forEach(
    (str) => (casings = casings.concat(Object.values(expandCasing(str))))
  );

  return casings;
};

export const getValue = (obj, path, defaultValue) =>
  path
    .split(".")
    .reduce(
      (acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined),
      obj
    ) ?? defaultValue;

export const cleanThemeCache = () => {
  const theme = Store.get("RaStore.theme");
  if (theme && !theme.aExpectedTheme) {
    Store.remove("RaStore.theme");
    console.log("Theme: cache cleaned");
  }
};

export const humanPeriod = (seconds, decimals = 1, maxUnit = 3) => {
  const allUnits = [
    { label: "year", seconds: 31536000 }, // 365 days
    { label: "month", seconds: 2592000 }, // 30 days
    { label: "week", seconds: 604800 }, // 7 days
    { label: "day", seconds: 86400 },
    { label: "hour", seconds: 3600 },
    { label: "minute", seconds: 60 },
    { label: "second", seconds: 1 },
  ];

  const units = allUnits.slice(maxUnit);

  for (const unit of units) {
    if (seconds >= unit.seconds) {
      const value = (seconds / unit.seconds).toFixed(decimals);
      return `${value} ${unit.label}${value != 1 ? "s" : ""}`;
    }
  }

  return "0 seconds";
};

export const strDateDiffSeconds = (dateStr2, dateStr1) => {
  dateStr2 = dateStr2 || new Date().toISOString();
  dateStr1 = dateStr1 || new Date().toISOString();

  return (new Date(dateStr2).getTime() - new Date(dateStr1).getTime()) / 1000;
};

export const humanDateDiff = (to, from, decimals = 1, maxUnit = 3) =>
  humanPeriod(strDateDiffSeconds(to, from), decimals, maxUnit);

export const secureJSONParse = (data) => {
  let value = null;

  try {
    value = JSON.parse(data);
  } catch (error) {
    console.warn(`Could not parse ${data}`);
  }

  return value;
};

export const expandTokenizedTemplate = (templateSetup, data) => {
  if (!(data && templateSetup)) return;

  const { regex, template } = templateSetup;
  if (!(regex && template)) return;

  let parsed = template;
  let parsedSuccess = true;

  const valueSearch = new RegExp(regex, "g");
  const values = get([...data.matchAll(valueSearch)], "[0].groups");
  if (!values) return null;

  for (const token in values) {
    const value = values[token];
    const marker = `{{$${token}}}`;

    if (!(value && parsed.includes(marker))) {
      parsedSuccess = false;
      return;
    }

    parsed = parsed.replaceAll(marker, value);
  }

  return parsedSuccess ? parsed : null;
};

export const popKey = (obj, key) => {
  if (!(key in obj)) return undefined;

  const value = obj[key];
  delete obj[key];
  return value;
};
