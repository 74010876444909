import { Labeled } from "react-admin";
import { asArray } from "../utils/tools";

export const LabeledAll = (props) => {
  const labeled = asArray(props.children).map((child, idx) => (
    <Labeled key={idx} className="RaLabeled">
      {child}
    </Labeled>
  ));

  return <>{labeled}</>;
};
