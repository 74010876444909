import set from "lodash/set";
import { secureJSONParse } from "./tools";

/**
 * Raw local storage access without hooks, use anywhere
 */
export const Store = {
  set: (key, val) => {
    localStorage.setItem(key, JSON.stringify(val));
  },
  get: (key) => {
    return JSON.parse(localStorage.getItem(key));
  },
  flip: (key) => {
    Store.set(key, !Store.get(key));
  },
  remove: (key) => {
    localStorage.removeItem(key);
  },
  getAll: () => {
    const data = {};
    const keys = Object.keys(localStorage);

    keys.forEach((key) => {
      const element = secureJSONParse(localStorage.getItem(key));
      if (!element) return;

      key = key.replaceAll(/--/g, ".");

      set(data, key, element);
    });

    return data;
  },
};
