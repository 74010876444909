import { useEffect, useState } from "react";
import { Loading, TabbedShowLayout, useDataProvider } from "react-admin";
import { FactoryAccessControlProvider } from "../utils/access";
import { accessCheck } from "../utils/accessCheck";
import { Settings } from "../utils/settings";

const Tab = TabbedShowLayout.Tab;
const MyTabbedShowLayout = ({ children, className = "", ...props }) => {
  const dataProvider = useDataProvider();
  const [acp, setAcp] = useState(false);
  const verticalTabs = Settings.getValue("verticalTabs");

  useEffect(() => {
    FactoryAccessControlProvider(dataProvider).then((acp) => {
      setAcp(acp);
    });
  }, []);

  if (!acp) {
    return <Loading />;
  }

  className = [className, verticalTabs ? "vertical-tabs" : null]
    .filter((v) => v)
    .join(" ");

  children = accessCheck(acp, children);
  return (
    <TabbedShowLayout className={className} {...props}>
      {children}
    </TabbedShowLayout>
  );
};

export { MyTabbedShowLayout, Tab };
