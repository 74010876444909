import { TextField, Button, Box } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";

const MultiInputField = ({
  label = "Item",
  onChange,
  value: values,
  fields = [
    {
      Component: TextField,
    },
  ],
}) => {
  //const [values, setValues] = useState([""]);

  // Handle input change
  const handleChange = (index, name, event) => {
    const newValues = [...values];
    newValues[index][name] = event.target.value;
    onChange(newValues);
  };

  // Add new input field
  const handleAdd = () => {
    onChange([...values, {}]);
  };

  // Remove input field
  const handleRemove = (index) => {
    const newValues = values.filter((_, i) => i !== index);
    onChange(newValues);
  };

  return (
    <Box
      className="MultiInputField"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 7,
        margin: "15px 0",
      }}
    >
      {values.map((value, rowIdx) => (
        <Box
          className="MultiInputFieldRow"
          key={rowIdx}
          sx={{ display: "flex", gap: 1 }}
        >
          {fields.map((field, fieldIdx) => {
            let { Component, label, name, ...rest } = field;
            label = label || name;

            return (
              <Component
                className="MultiInputFieldColumn"
                key={`${rowIdx}-${fieldIdx}`}
                label={`${label} ${rowIdx + 1}`}
                value={value[name]}
                onChange={(event) => handleChange(rowIdx, name, event)}
                variant="outlined"
                {...rest}
              />
            );
          })}
          <Button
            variant="contained"
            color="error"
            onClick={() => handleRemove(rowIdx)}
            disabled={values.length === 1}
          >
            <Delete />
          </Button>
        </Box>
      ))}
      <Button variant="contained" onClick={handleAdd}>
        <Add /> Add {label}
      </Button>
    </Box>
  );
};

export default MultiInputField;
