import { Settings } from "../utils/settings";
import { Debug } from "./debug";

export const NoAccess = ({ label, ...rest }) => {
  return Settings.getDebugSetting("show_restricted_elements") ? (
    <Debug {...rest} sx={{ whiteSpace: "break-spaces", border: "1px  solid" }}>
      Unauthorized: {label}
    </Debug>
  ) : null;
};
