import { ImageField, TextField, TextInput } from "react-admin";
import { MyList } from "../ra-lb-tools/components/myList";
import { LocationField, LocationInput } from "./locations/components";
import { ImageOutlined } from "@mui/icons-material";
import { Row } from "../ra-lb-tools/components/row";
import { TitledShow } from "../ra-lb-tools/components/titledRecord";
import { LabeledColumn } from "../ra-lb-tools/components/labeledColumn";
import {
  MyTabbedShowLayout,
  Tab,
} from "../ra-lb-tools/components/myTabbedShowLayout";
export const LocationImageIcon = ImageOutlined;

export const LocationImageList = (props) => {
  const filters = [
    <TextInput source="tag" key="tag" alwaysOn />,
    <LocationInput source="locationId" key="locationId" alwaysOn />,
  ];

  return (
    <MyList resource="location-image" filters={filters} {...props}>
      <TextField source="id" />
      <TextField source="tag" />
      <ImageField source="url" label="Image" />
      <LocationField source="locationId" />
    </MyList>
  );
};

export const LocationImageShow = () => {
  return (
    <TitledShow labelField={"tag"}>
      <MyTabbedShowLayout>
        <Tab label="Summary" can={true}>
          <Row className={"RecordSummary"}>
            <LabeledColumn sx={{ flexGrow: 1 }}>
              <TextField source="id" />
              <LocationField source="locationId" />
              <TextField source="tag" />
            </LabeledColumn>
            <LabeledColumn sx={{ flexGrow: 1 }}>
              <ImageField
                source="url"
                label=""
                sx={{
                  "& img": {
                    width: "100% !important",
                    height: "auto !important",
                    maxHeight: "50vh",
                    objectFit: "contain",
                  },
                }}
              />
            </LabeledColumn>
          </Row>
        </Tab>
      </MyTabbedShowLayout>
    </TitledShow>
  );
};
