import { TextField, DateField } from "react-admin";
import { TitledShow } from "../../ra-lb-tools/components/titledRecord";
import {
  MyReferenceField,
  MyReferenceInput,
} from "../../ra-lb-tools/components/myReference";
import { MyList } from "../../ra-lb-tools/components/myList";
import { LabeledColumn } from "../../ra-lb-tools/components/labeledColumn";
import { MyCreate as Create } from "../../ra-lb-tools/components/myMutator";
import { OrganizationField, OrganizationInput } from "./organizations";
import { PlanField, PlanInput } from "./plans";
import { PriceCheck } from "@mui/icons-material";
import {
  MyTabbedShowLayout,
  Tab,
} from "../../ra-lb-tools/components/myTabbedShowLayout";
import { LockerUsageRecordsList } from "./lockerUsageRecord";
import { useParams } from "react-router";
import { Row } from "../../ra-lb-tools/components/row";

export const PlanSubscriptionIcon = PriceCheck;

export const PlanSubscriptionList = (props) => (
  <MyList {...props} resource="plan-subscriptions">
    <TextField source="id" />
    <OrganizationField source="organizationId" />
    <PlanField source="planId" />
    <TextField source="processorId" />
    <DateField source="createdAt" showTime />
    <DateField source="updatedAt" showTime />
  </MyList>
);
export const PlanSubscriptionShow = () => {
  const id = parseInt(useParams()["id"]);

  return (
    <TitledShow>
      <MyTabbedShowLayout>
        <Tab label="Summary" can={true}>
          <Row className={"RecordSummary"}>
            <LabeledColumn>
              <TextField source="id" />
              <OrganizationField source="organizationId" />
              <PlanField source="planId" />
              <TextField source="processorId" />
              <DateField source="createdAt" showTime />
              <DateField source="updatedAt" showTime />
            </LabeledColumn>
          </Row>
        </Tab>
        <Tab
          label="Usage Records"
          path="usage-records"
          can={{ action: "list", resource: "locker-usage-records" }}
        >
          <LockerUsageRecordsList filter={{ plan_subscription_id: id }} />
        </Tab>
      </MyTabbedShowLayout>
    </TitledShow>
  );
};

export const PlanSubscriptionCreate = (props) => {
  return (
    <Create {...props}>
      <OrganizationInput source="organizationId" />
      <PlanInput source="planId" />
    </Create>
  );
};

export const PlanSubscriptionInput = (props) => {
  return (
    <MyReferenceInput
      source="planSubscriptionId"
      reference="plan-subscriptions"
      {...props}
    />
  );
};

export const PlanSubscriptionField = (props) => {
  return (
    <MyReferenceField
      source="planSubscriptionId"
      reference="plan-subscriptions"
      primaryField="id"
      {...props}
    />
  );
};
