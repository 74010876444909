import { Button, Link } from "react-admin";
import { getGrafanaUri } from "../utils/grafana";
import { History } from "@mui/icons-material";

export const GrafanaButton = ({ expr, label = "Server logs" }) => {
  return (
    <Button
      className="GrafanaButton"
      color="primary"
      component={Link}
      to={getGrafanaUri(expr)}
      target="_blank"
      startIcon={<History />}
      label={label}
    />
  );
};
