import { useState, Fragment } from "react";
import { useMutation } from "react-query";
import {
  Confirm,
  useNotify,
  useRecordContext,
  useDataProvider,
} from "react-admin";
import { ButtonStrong } from "../../components/buttonStrong";
import { Box, TextField } from "@mui/material";

const AddPriceButton = () => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  const [price, setPrice] = useState("");
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { mutate, isLoading } = useMutation(() =>
    dataProvider.addPriceToPlan(record.id, price.trim(), notify)
  );

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    notify("Add price to Plan");
    mutate();
    setOpen(false);
  };

  const confirm_message = (
    <>
      <Box>Are you sure you want to add price to Plan: {record.id}?</Box>
      <Box>
        <TextField
          label="Price Id"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          fullWidth
          multiline
          rows={2}
          variant="outlined"
          sx={{ marginTop: "10px" }}
        />
      </Box>
    </>
  );
  return (
    <Fragment>
      <ButtonStrong
        label="Add price"
        onClick={handleClick}
        color="inherit"
        size="small"
        sx={{ flexShrink: 0 }}
      />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="WARNING! READ THIS"
        content={confirm_message}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
        sx={{
          "*": {
            paddingBottom: "5px",
          },
        }}
        confirm={"YES"}
        cancel={"NO"}
        confirmColor="green"
      />
    </Fragment>
  );
};

export default AddPriceButton;
