import {
  TextField,
  SelectField,
  SelectInput,
  ReferenceInput,
  useRecordContext,
  TextInput,
} from "react-admin";
import { useParams, Link } from "react-router-dom";

import BusinessIcon from "@mui/icons-material/Business";
import { JsonField } from "../ra-lb-tools/components/jsonField";
import AutocompleteInputFluid from "../ra-lb-tools/components/autocompleteInputFluid";
import { MyList } from "../ra-lb-tools/components/myList";
import get from "lodash/get";
import { Debug } from "../ra-lb-tools/components/debug";
import { Settings } from "../ra-lb-tools/utils/settings";
import { Box } from "@mui/material";
import { TowerTimezonedDateField } from "./tower/towerTimezonedDate";
import { TowerField, TowerInput } from "./tower/towers";
import { getGrafanaUri } from "../ra-lb-tools/utils/grafana";
import { MyReferenceField } from "../ra-lb-tools/components/myReference";
import { TitledShow } from "../ra-lb-tools/components/titledRecord";
import {
  MyTabbedShowLayout,
  Tab,
} from "../ra-lb-tools/components/myTabbedShowLayout";
import { Row } from "../ra-lb-tools/components/row";
import { LabeledColumn } from "../ra-lb-tools/components/labeledColumn";

export const SystemIcon = BusinessIcon;

/**
 * TODO:
 * Check for enhancement
 * Currently this needs to be manually synchronized from
 * hal-backend/app/app/models/tower_history.py::class TowerHistoryType(EnumBase)
 */
export const TowerHistoryType = {
  StatusChange: { id: 1, name: "Status Change" },
  FirmwareChange: { id: 2, name: "Firmware Change" },
  DeviceSerialChange: { id: 3, name: "Device Serial Change" },
  DeviceModelChange: { id: 4, name: "Device Model Change" },
  EventsResetUserReset: { id: 5, name: "Events Reset: User Reset" },
  EventsResetAutomaticReset: { id: 6, name: "Events Reset: Automatic Reset" },
  EventSync: { id: 7, name: "Event Sync" },
  ErrorEvent: { id: 8, name: "Error Event" },
};

export const typeChoices = Object.values(TowerHistoryType);

const towerHistoryFilters = (typeChoices) => [
  <TowerInput
    label="Tower"
    source="towerId"
    alwaysOn
    key={"towerId"}
    filter={{ status_id: 0 }}
  />,
  <SelectInput
    label="Type"
    source="type"
    choices={typeChoices}
    alwaysOn
    key={"type"}
  />,
  <ReferenceInput
    source="user_id"
    label="User"
    reference="users"
    alwaysOn
    allowEmpty
    key={"user_id"}
  >
    <AutocompleteInputFluid
      label="User"
      filterToQuery={(searchText) => ({ email: `%${searchText}` })}
      optionText="email"
      optionValue="id"
    ></AutocompleteInputFluid>
  </ReferenceInput>,
  <TextInput source="device_serial" key={"device_serial"} />,
];

const getLogUri = (requestInfo) => {
  const request_id = requestInfo.id;
  const expr = `|= \`${request_id}\``;
  const time = new Date(requestInfo.timestamp + "Z");
  const delta = 30 * 60 * 1000;
  const from = time.getTime() - delta;
  const to = time.getTime() + delta;

  return getGrafanaUri(expr, from, to);
};

const LogLink = () => {
  const record = useRecordContext();
  const requestInfo = get(record, "new.request");

  if (!(requestInfo && requestInfo.id && requestInfo.timestamp)) {
    return null;
  }

  const logUri = getLogUri(requestInfo);

  return (
    <Link to={logUri} target="_blank">
      Log
    </Link>
  );
};

const types_with_summary = [
  TowerHistoryType.EventSync.id,
  TowerHistoryType.ErrorEvent.id,
];

export const SummaryField = ({ record }) => {
  let sx = {
    fontWeight: "bolder",
  };

  record = record || useRecordContext();
  if (!types_with_summary.includes(record.type)) {
    return null;
  }

  let summary = record.summary || "";
  summary = summary.toLowerCase();

  switch (summary) {
    case "success":
      sx.color = "green";
      break;
    case "failed":
      sx.color = "red";
      break;
    case "mixed":
      sx.color = "orange";
      break;
    case "empty":
      sx.color = "blue";
      break;

    default:
      break;
  }

  let detail = null;
  const error_types_data = get(record, "data.error_types", {}) || {};
  const error_types = Object.keys(error_types_data);
  const unique_error = error_types.length == 1 ? error_types[0] : null;

  if (summary == "failed" && unique_error) {
    detail = (
      <Box className="TowerHistoryDetail">
        <Box>{unique_error}</Box>
        <Box>
          Got events: {get(record, "data.detail.events.first")}-
          {get(record, "data.detail.events.last")}
        </Box>

        <Box>Expected event: {get(record, "data.next_event.before")}</Box>
      </Box>
    );
  } else if (record.type == TowerHistoryType.ErrorEvent.id) {
    record.summary = get(record, "data.error.description");
    sx.color = "red";
  }

  const customClasses = [summary == "failed" ? "blink-me" : null]
    .filter((v) => v)
    .join(" ");

  return (
    <Link
      to={`/tower-history/${record.id}/show`}
      className="TowerHistorySummaryField"
    >
      <TextField
        record={record}
        source="summary"
        sortable={false}
        sx={sx}
        className={`TowerHistorySummary status-${summary} ${customClasses}`}
      />
      {detail}
    </Link>
  );
};

export const TowerHistoryList = (props) => {
  const title = "Tower History";
  const resource = "tower-history";

  return (
    <MyList
      title={title}
      resource={resource}
      filters={towerHistoryFilters(typeChoices)}
      dataGridProps={{
        rowClick: null,
      }}
      sort={{
        field: "id",
        order: "DESC",
      }}
      {...props}
    >
      <TextField source="id" />
      <TowerField source="towerId" />
      <TowerTimezonedDateField source="timestamp" showTime />
      <SelectField source="type" choices={typeChoices} />
      <SummaryField source="summary" />
      <JsonField source="data" sortable={false} />
      <MyReferenceField
        source="userId"
        reference="users"
        link="show"
        primaryField="email"
      />
      {Settings.debugging() && (
        <Debug label="Log">
          <LogLink />
        </Debug>
      )}
    </MyList>
  );
};

export const TowerHistoryListView = () => {
  const { towerId } = useParams();

  return <TowerHistoryList towerId={towerId} />;
};

export const TowerHistoryShow = () => {
  return (
    <TitledShow>
      <MyTabbedShowLayout>
        <Tab label="Summary" can={true}>
          <Row className={"RecordSummary"}>
            <LabeledColumn>
              <TextField source="id" />
              <TowerField source="towerId" />
              <TowerTimezonedDateField source="timestamp" showTime />
              <SelectField source="type" choices={typeChoices} />
              <MyReferenceField
                source="userId"
                reference="users"
                link="show"
                primaryField="email"
              />
            </LabeledColumn>
            <LabeledColumn>
              <SummaryField source="summary" />
              <JsonField source="data" sortable={false} />
              {Settings.debugging() && (
                <Debug label="Log">
                  <LogLink />
                </Debug>
              )}
            </LabeledColumn>
          </Row>
        </Tab>
      </MyTabbedShowLayout>
    </TitledShow>
  );
};
