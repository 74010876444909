import { useEffect, useState } from "react";
import { Loading, useDataProvider, useResourceContext } from "react-admin";
import { FactoryAccessControlProvider } from "../utils/access";
import get from "lodash/get";
import { NoAccess } from "./noAccess";

export const WithPermissions = ({
  can,
  resource,
  action,
  children,
  label,
  fail,
}) => {
  resource = resource || useResourceContext();
  const dataProvider = useDataProvider();
  const [show, setShow] = useState(false);
  const [ready, setReady] = useState(false);
  label =
    label || get(children, "props.className", "").split(" ")[0] || "unknown";

  if (label == "unknown") {
    console.warn(
      "WithPermissions:: Empty label, please add label for easier debugging"
    );
  }

  useEffect(() => {
    FactoryAccessControlProvider(dataProvider).then((acp) => {
      setReady(true);

      can = can || { resource, action };

      if (acp.can(can, label)) {
        setShow(true);
      }
    });
  }, []);

  if (!ready) {
    return <Loading />;
  }

  return show ? <>{children}</> : fail || <NoAccess label={label} />;
};
