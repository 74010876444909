import { Box } from "@mui/material";
import { Settings } from "../utils/settings";
import { debugInfo } from "../theme/styles";
import { useState } from "react";
import { FieldInput } from "./unsourcedInputs";

export const Debug = ({
  foldableLabel = "Debug Info",
  foldable,
  children,
  sx,
  ...rest
}) => {
  if (!Settings.debugging()) {
    return null;
  }

  const [unfolded, setUnfolded] = useState(false);

  return (
    <Box sx={{ ...debugInfo, ...sx }} {...rest} className="Debug">
      <Box className={"DebugHeader"}>
        {foldable && (
          <FieldInput
            value={unfolded}
            setter={setUnfolded}
            label={foldableLabel}
          />
        )}
      </Box>
      {(!foldable || unfolded) && (
        <Box className={"DebugContent"}>{children}</Box>
      )}
    </Box>
  );
};
