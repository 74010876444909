import { Menu } from "react-admin";
import { ResourceMenuItems } from "../utils/resourceMenuItems";
import { Clear, HomeOutlined, Search } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import { MenuFavorites } from "./menuFavorites";

const MenuSearch = ({ filter, setFilter }) => (
  <TextField
    className="MenuSearch"
    variant="outlined"
    placeholder="Menu search"
    value={filter}
    onChange={(event) => {
      setFilter(event.target.value);
    }}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          {filter ? (
            <Clear
              onClick={() => setFilter("")}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <Search color="disabled" />
          )}
        </InputAdornment>
      ),
    }}
  />
);

export const MyMenu = ({ children }) => {
  const [filter, setFilter] = useState("");

  return (
    <Menu>
      <MenuSearch filter={filter} setFilter={setFilter} />
      <MenuFavorites />
      <hr style={{ borderColor: "grey", margin: "10px 21px" }} />
      <Menu.DashboardItem primaryText="Home" leftIcon={<HomeOutlined />} />
      <ResourceMenuItems filter={filter} />
      <hr style={{ borderColor: "grey", margin: "10px 21px" }} />
      {children}
    </Menu>
  );
};
