import {
  TextField,
  TextInput,
  required,
  AutocompleteInput,
  ReferenceArrayInput,
  SelectInput,
  useNotify,
  useRecordContext,
} from "react-admin";
import {
  MyCreate as Create,
  MyEdit,
} from "../ra-lb-tools/components/myMutator";
import SecurityIcon from "@mui/icons-material/Security";
import { MyList } from "../ra-lb-tools/components/myList";
import { TitledShow } from "../ra-lb-tools/components/titledRecord";
import { Row } from "../ra-lb-tools/components/row";
import { LabeledColumn } from "../ra-lb-tools/components/labeledColumn";
import { MyEditButton as EditButton } from "../ra-lb-tools/components/myEditButton";
import { MyDeleteButton } from "../ra-lb-tools/components/myDeleteButton";
import {
  MyTabbedShowLayout,
  Tab,
} from "../ra-lb-tools/components/myTabbedShowLayout";

export const PolicyIcon = SecurityIcon;

const policyFilters = [
  <TextInput label="Subject (Role or id)" source="v0" alwaysOn key={2} />,
  <TextInput label="Resource" source="v1" alwaysOn key={3} />,
  <TextInput label="Action" source="v2" alwaysOn key={4} />,
  <TextInput label="type" source="ptype" key={1} />,
];

const policyTypes = [{ id: "p", name: "P" }];

const is_protected_policy = (record) =>
  (record.v0 === "superuser" && record.v1 === "*" && record.v2 === "*") ||
  record.v2 === "backend/permission/*";

const PolicyDeleteButton = () => {
  const notify = useNotify();
  const record = useRecordContext();

  if (!record) return null;
  if (is_protected_policy(record)) return null;

  return (
    <MyDeleteButton
      mutationOptions={{
        meta: {
          notify,
        },
      }}
    />
  );
};

const PolicyEditButton = () => {
  const record = useRecordContext();

  if (!record) return null;
  if (is_protected_policy(record)) return null;

  return <EditButton />;
};

export const PolicyList = (props) => {
  const resource = "policies";

  return (
    <MyList
      {...props}
      filters={policyFilters}
      resource={resource}
      customRowButtons={["edit", "delete"]}
    >
      <TextField source="id" />
      <TextField source="ptype" label="Type" />
      <TextField source="v0" label="Subject (Role or id)" />
      <TextField source="v1" label="Resource" />
      <TextField source="v2" label="Action" />
      <TextField source="v3" />
      <TextField source="v4" />
      <TextField source="v5" />
      <PolicyDeleteButton />
      <PolicyEditButton />
    </MyList>
  );
};

export const PolicyCreate = (props) => {
  const filterToQuery = (searchText) => ({ id: `${searchText}` });

  return (
    <Create {...props}>
      <SelectInput
        source="ptype"
        choices={policyTypes}
        label="Type"
        defaultValue={"p"}
        disabled
      />
      <TextInput
        source="v0"
        validate={required()}
        label="Subject (Role or id)"
      />
      <ReferenceArrayInput source="v1" reference="policy-resources">
        <AutocompleteInput
          filterToQuery={filterToQuery}
          optionText="id"
          optionValue="id"
          label="Resource"
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="v2" reference="policy-actions">
        <AutocompleteInput
          filterToQuery={filterToQuery}
          optionText="id"
          optionValue="id"
          label="Action"
        />
      </ReferenceArrayInput>
    </Create>
  );
};

export const PolicyShow = () => {
  return (
    <TitledShow>
      <MyTabbedShowLayout>
        <Tab label="Summary" can={true}>
          <Row sx={{ justifyContent: "start" }} className={"RecordSummary"}>
            <LabeledColumn>
              <TextField source="id" />
              <TextField source="ptype" label="Type" />
              <TextField source="v0" label="Subject (Role or id)" />
              <TextField source="v1" label="Resource" />
              <TextField source="v2" label="Action" />
              <TextField source="v3" />
              <TextField source="v4" />
              <TextField source="v5" />
            </LabeledColumn>
          </Row>
        </Tab>
      </MyTabbedShowLayout>
    </TitledShow>
  );
};

export const PolicyEdit = (props) => {
  const filterToQuery = (searchText) => ({ id: `${searchText}` });

  return (
    <MyEdit {...props}>
      <SelectInput
        source="ptype"
        choices={policyTypes}
        label="Type"
        defaultValue={"p"}
        disabled
      />
      <TextInput
        source="v0"
        validate={required()}
        label="Subject (Role or id)"
      />
      <ReferenceArrayInput source="v1" reference="policy-resources">
        <AutocompleteInput
          filterToQuery={filterToQuery}
          optionText="id"
          optionValue="id"
          label="Resource"
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="v2" reference="policy-actions">
        <AutocompleteInput
          filterToQuery={filterToQuery}
          optionText="id"
          optionValue="id"
          label="Action"
        />
      </ReferenceArrayInput>
    </MyEdit>
  );
};
